@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.loading-icon {
    animation: spin 0.75s linear infinite;
}
