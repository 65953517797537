@import 'styles/variables.scss';

@media screen and (max-width: 576px) {
    .search-page-container {
        padding: 0 16px;
    }
    .search-page-header {
        .search-page-title {
            font-size: 24px;
        }
    }
}
