@import '../../../styles/variables.scss';

.term-of-service__modal {
    .ant-modal-body {
        padding-top: 0px !important;
        padding-left: 28px;
        padding-right: 28px;
    }
    .ant-modal-content {
        width: 410px;
        height: 570px;
        margin: 0 auto;
        @media screen and (max-width: $breakpoint-mobile) {
            width: 100% !important;
        }
        .modal-wrapper {
            display: flex;
            flex-direction: column;
            .title {
                font-weight: bold;
                font-size: 24px;
                line-height: 54px;
                color: $gray-color-3;
            }
            .description {
                color: $third_color;
                margin-top: 16px;
                font-size: 16px;
            }
            .term-of-service {
                color: $gray-color-3;
                font-weight: bold;
            }

            .btn-term-of-service {
                margin-top: 40px;
                .ant-btn.ant-btn-custom {
                    width: 100%;
                }
                .btn-disconnect {
                    margin-top: 16px;
                }
            }
            .reference-code {
                margin-top: 20px;
                .reference-content {
                    margin-bottom: 5px;
                    font-size: 16px;
                }
                .reference-error {
                    margin-bottom: 0px;
                    margin-top: 5px;
                    color: #ed1c51;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        .ant-modal-body {
            padding: 0 24px 32px !important;
            margin-top: -18px !important;
        }
        .ant-modal-content {
            width: 343px;
            height: 500px;
            .modal-wrapper {
                .title {
                    font-size: 24px;
                    line-height: 21px;
                }
                .description {
                    margin-top: 16px;
                    margin-bottom: 0;
                    font-size: 14px;
                }
                .btn-term-of-service {
                    margin-top: 64px;
                    .ant-btn.ant-btn-custom {
                        width: 100%;
                    }
                    .btn-disconnect {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}
