@import '../../../styles/variables.scss';

.ant-checkbox-wrapper.checkbox-custom {
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: $primary_color;
            border-color: $primary_color;
        }
    }
}

.ant-checkbox-group.checkbox-group-custom {
    display: flex;
    flex-direction: column;
    .ant-checkbox-group-item {
        margin-bottom: 16px;
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: $primary_color;
                border-color: $primary_color;
            }
        }
        .ant-checkbox + span {
            padding-left: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;

            color: $primary_color;
        }
    }
}
