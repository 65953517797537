@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.related-list {
    .container {
        padding: 10px !important;
        .collection-container {
            padding-inline: 16px;
        }
    }
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    padding: 10px;
}
.related-header {
    color: $gray-color-3;
    word-break: break-word;
    font-size: 24px;
    font-weight: 700;
    color: $gray-color-3;
    @media screen and (max-width: 366px) {
        font-size: 18px;
    }
}

.dark-related-header {
    color: $white-text;
}