@import "../../../styles/variables";
@import "../../../styles/mixins";

.ant-card.card-collection-custom {
  border-radius: 28px;
  cursor: pointer;
  background: $white;
  border: 1px solid $gray-color !important;
  box-shadow: none;
  position: relative;
  border: none;

  .ant-card-body {
    padding: 28px !important;
  }

  .card-collection__list-nft {
    display: flex;
    height: 100px;
    gap: 16px;
    .nft-avatar,
    .nft-default {
      width: calc((100% - 2 * 16px) / 3);
      height: 100%;
      border-radius: 20px;
    }
    .nft-avatar {
      object-fit: cover;
      aspect-ratio: 1;
    }
    .nft-default {
      background-color: $gray-color;
    }
  }

  &:hover {
    .card-collection-custom-header {
      .card-collection-image {
        transform: scale(1.05);
      }
    }
  }

  .card-collection-custom-header {
    position: relative;
    height: 240px;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 16px;
    .card-collection-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
    }
  }
  //trường hợp này dành cho card collection ở trang homepage
  .card-collection-custom-header-homepage {
    height: 260px;
  }

  .card-collection-infor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 16px 0;
    &__name {
      @include text-large-bold;
      font-weight: 600;
      line-height: 28px;
      color: $gray-color-3;
    }
    &__heart {
      font-size: 17px;
      display: flex;
      align-items: center;
      .heart-default {
        color: #b8bdd1;
        &:hover {
          color: $red;
        }
      }
    }

    .blockchain-icon {
      .ant-avatar {
        width: 28px;
        height: 28px;
        img {
          object-fit: unset;
        }
      }
    }
  }

  .card-collection-footer {
    display: flex;
    color: $secondary_color;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    &-info {
      display: flex;
      align-items: center;
      width: 100%;

      .info {
        margin-right: 20px;
        .info-title {
          @include text-small-medium;
          line-height: 24px;
          color: $gray-color-2;
        }

        .info-value {
          @include text-normal-medium;
          color: $gray-color-3;
        }
      }

      .default-owner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        @include text-normal-regular;
        color: $gray-color-2;
        max-width: 35%;
        margin-left: auto;

        .default-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .avatar-default-collection {
        position: relative;

        .verified-default {
          position: absolute;
          right: -3px;
          bottom: -5px;
        }

        .avatar-owner-default {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          margin-left: 10px;
        }
      }
    }
    &__total-nft {
      padding-left: 6px;
    }
    &__wrapper {
      position: relative;
      padding-left: 10px;
    }
    &__created-by {
      display: flex;
      align-items: center;
      margin-left: auto;
      a {
        @include text-normal-regular;
        color: $gray-color-2;
        position: relative;
        display: flex;
        align-items: center;
        &:hover {
          color: $gray-color-3;
        }
        .custom-avatar {
          margin-left: 10px;
          height: fit-content !important;
        }
        .verify-icon {
          margin-right: 5px;
        }
        .ant-avatar {
          width: 32px !important;
          height: 32px !important;
        }
      }
      .avatar-verify {
        .custom-avatar {
          margin-right: 3px;
        }
      }
    }
  }
}

#dark-theme .ant-card.card-collection-custom {
  background: $primary_background_color_dark;
  border: none !important;

  .ant-card-body {
    border: none !important;

    .card-collection-infor__name {
      color: $white-text;
    }
  }
}

.card-collection-custom_verify-icon {
  position: absolute;
  right: -4px;
  bottom: -2px;
}
