@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.accept-modal-container {
    font-size: 16px;
    color: $primary-color;
    p {
        margin: 0px;
    }

    .error-balance {
        color: red;
    }
    a {
        color: $primary-color;
        font-family: $font-primary;
        &:hover {
            color: $primary-color;
        }
    }
    & > :last-child {
        margin: auto;
        width: 100%;
    }
}
