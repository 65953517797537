@import '../../../styles/variables';
@import '../../../styles/mixins.scss';

.ant-card.card-item-custom {
    padding: 0;
    // border-radius: 20px;
    cursor: pointer;
    background: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    height: 100%;
    // border: 1px solid $border_color;
    min-height: 365px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(0);
    border-radius: 28px;
    border: 1px solid #F0EEEE;

    &:hover {
        transform: translateY(-5px);
        .card-item-custom-avatar img {
            transform: scale(1.05);
        }
    }

    .ant-card-body {
        border-radius: 20px;
        // padding: 5px;
        padding: 20px;
    }

    .card-item-custom-header {
        // padding: 10px 10px 10px 10px;
        position: relative;
        margin-bottom: 16px;

        &_avatar {
            position: relative;
        }

        .card-item-owner-creator-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .card-item-owner {
                height: 30px;
            }
        }

        .card-item-custom-header_videoIcon {
            position: absolute;
            top: 10px;
            left: 10px;
            & > svg {
                width: 32px;
                height: 32px;
            }
        }
        .card-item-custom-header-like {
            background: #fff;
            border-radius: 16px;
            padding: 4px 8px;
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            .heart-icon {
                width: 18px;
                height: 18px;
            }
            &__count {
                color: $primary_color;
                font-size: 14px;
                margin-left: 3px;
            }
        }
    }

    .card-item-custom-usersGroupItem {
        color: $secondary_color;
        &:hover,
        &:focus {
            color: $btn_blue;
        }
        &:not(:first-child) {
            margin-left: -15px !important;
            z-index: 3;
        }

        .card-item-custom-users_avatar {
            box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.15);
            width: 30px;
            height: 30px;
            border: none;
            position: relative;
            &:hover {
                z-index: 4;
            }
        }
        &:hover .card-item-custom-users_avatar_wrapper {
            z-index: 5;
        }
        .card-item-custom-users_avatar_wrapper {
            position: relative;
            z-index: 1;
            transition: all 0.25s ease;
            .card-item-custom-users_icon__last {
                position: absolute;
                bottom: -3px;
                right: -5px;
                z-index: 10;
            }
        }
    }

    .card-item-custom-users {
        display: flex;
        width: 100%;

        .card-item-custom-usersGroup {
            position: relative;
            display: inline-flex;
            width: 100%;
        }
    }

    .card-item-custom-users-resize {
        width: 55% !important;
    }

    .card-item-custom-usersGroupItem {
        max-width: 100%;
    }

    .card-item-custom-users_avatar_wrapper {
        position: relative;
        transition: all 0.3s ease-out;
        display: flex;
        align-items: center;
        width: 100%;
        &-verify {
            position: relative;
        }
        &:hover {
            transform: translateY(-10%);
            z-index: 7;
        }
        .card-item-custom-users_avatar {
            box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.15);
            width: 30px;
            height: 30px;
            border: none;
            position: relative;
        }

        .card-item-custom-users_verify {
            position: absolute;
            left: 16px;
            bottom: -5px;
        }

        .card-item-custom-users_icon__last {
            position: absolute;
            bottom: -2px;
            right: -5px;
            z-index: 10;
        }
        .name {
            margin-left: 8px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 38px);
        }
        .name-dark {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .card-item-custom-content_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .card-item-custom-avatar {
        width: 100%;
        // height: 280px;
        object-fit: cover;
        // border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transform: translateZ(0);
        border-radius: 20px;
        height: initial;
        aspect-ratio: 1 / 1;

        & > img {
            border-radius: 8px;
            object-fit: cover;
            transition: transform 0.5s ease;
        }
    }

    // .card-item-custom-content {
    //     padding: 10px;
    //     padding-top: 8px;
    // }

    .card-item-custom-content_title {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-item-custom-content_title__title {
            font-style: normal;
            font-family: $font-primary;
            // font-size: 15px;
            // color: $primary_color;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            text-align: start;
            @include text-large-medium;
            font-weight: 600;
            color: $gray-color-3;
        }
        .card-item-custom-content_title__title___dark {
            color: $secondary_background_color;
        }
        .ant-avatar {
            width: 20px;
            height: 20px;
            min-width: 20px;
            margin-left: 5px;
            img {
                object-fit: unset;
                border-radius: 50%;
            }
        }
    }

    .card-item-custom-content_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price-container {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }

        .card-item-custom-content_footer__block {
            width: 100%;
            display: flex;
            // gap: 20px;

            &-price {
                font-style: normal;
                // font-weight: 500;
                // font-size: 14px;
                // color: $third_color;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                @include text-small-medium;
                color: $gray-color-2;
            }
        }

        .card-item-custom-content_footer__notForSale {
            font-style: normal;
            // font-weight: 500;
            // font-size: 16px;
            // color: $third_color;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            @include text-normal-medium;
            color: $gray-color-3;
        }

        .line-empty {
            height: 25px;
        }

        .card-item-custom-content_footer__make-an-offer {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #47d176;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .card-item-custom-content_footer__make-an-offer__available {
                font-family: inherit;
                font-weight: bold;
                font-style: normal;
                font-family: Helvetica; 
                color: rgb(128, 128, 128);
                vertical-align: inherit;
                margin-left: 4px;
            }
        }

        .card-item-custom-content_footer__highest-price {
            display: flex;
            flex-direction: column;
            .card-item-custom-content_footer__highest-bid {
                font-size: 14px;
                font-weight: 400;
                color: $third_color;
            }
        }

        .card-item-custom-content_footer__price {
            font-style: normal;
            // font-weight: bold;
            // font-size: 16px;
            // color: $primary_color;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: space-between;
            font-family: Helvetica;
            @include text-normal-medium;
            color: $gray-color-3;
        }

        .card-item-custom-content_footer__price___dark {
            color: $white !important;
        }
        .card-item-custom-content_footer__number {
            font-family: inherit;
            font-weight: inherit;
            color: rgb(128, 128, 128);
            vertical-align: inherit;
        }

        .card-item-custom-content_footer__like {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: $third_color;
            margin-left: 7px;
        }
    }

    .card-item-custom-content_auction {
        position: absolute;
        bottom: -10px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 10px 8px;
        width: fit-content;
        justify-content: center;
        background-color: $white;
        border: solid 1px $color-gray-blue;
        & > div:first-child {
            align-items: center;
        }
        &__counter-container {
            margin-top: -1px;
        }
        .card-item-custom-content_auction__left {
            font-size: 12px;
            font-weight: 600;
            color: $primary_color;
            margin: 0 4px;
            line-height: 19px;
        }

        .card-item-custom-content_auction__counter-container {
            font-style: normal;
            font-family: $font-primary;
            font-size: 12px;
            color: $red;
            display: flex;
            .card-item-custom-content_auction__counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                .card-item-custom-content_auction__time-unit {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 28px;
                    border-radius: 2px;
                }
                .card-item-custom-content_auction__colon {
                    margin: 0 2px;
                }
            }
            div:nth-of-type(3) {
                span {
                    display: none;
                }
            }
        }
    }
}

#dark-theme .ant-card.card-item-custom {
    background: rgb(48, 51, 57);

    .card-item-custom-content_title {
        &__title {
            color: $btn-blue;
        }
    }

    .card-item-custom-content_footer__price {
        color: $btn-blue;
    }
}
.card-item-custom-content_multi-border {
    position: absolute;
    z-index: -1;
    inset: 0px;
    &::before,
    &::after {
        position: absolute;
        content: '';
        height: 40px;
        display: block;
        background: rgb(255, 255, 255);
        border-radius: 16px;
        border-bottom: 1px solid rgb(230, 230, 230);
    }
    &::before {
        left: 3px;
        right: 3px;
        bottom: -6px;
        z-index: -2;
    }
    &::after {
        left: 6px;
        right: 6px;
        bottom: -12px;
        z-index: -3;
    }
}

#dark-theme .card-item-custom-content_multi-border {
    &::before,
    &::after {
        background: $primary_background_color_dark;
    }
}

.card-item-custom-usersGroupItem {
    .card-item-custom-users_avatar_wrapper {
        position: relative;
        .card-item-custom-users_icon__last {
            position: absolute;
            bottom: -2px;
            right: -5px;
            z-index: 10;
        }
    }
}

.tooltip-customer-title__role {
    text-transform: capitalize;
}

.ant-tooltip-inner {
    color: $primary_color !important;
}

@media screen and (max-width: $breakpoint-xl) and (min-width: $breakpoint-tablet) {
    .card-item-custom-content_auction {
        flex-wrap: wrap;
    }
}

.card-item-dark {
    background: #292828 !important;
    border: 1px solid #333030 !important;
}
