@import 'styles/variables.scss';

.footer {
  padding: 25px 0 0 !important;
  color: #fff;
  width: 100%;
  &-top {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    flex-direction: row;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding-top: 40px !important;
    padding-inline: 40px !important;
    &_page-link {
      display: flex;
      width: 70%;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        padding-top: 40px;
        width: 100%;
      }
      &-item {
        width: 25%;
        text-align: left;
        padding-left: 15px;
        @media screen and (max-width: 767px) {
          padding-left: 0;
        }
        @media screen and (max-width: 520px) {
          width: 50%;
        }
        @media screen and (max-width: 300px) {
          width: 100%;
        }
        .sub-title {
          margin-top: 48px;
        }
        .item-link {
          font-weight: unset;
          a {
            display: block;
            font-size: 16px;
            width: fit-content;
            cursor: pointer;
            color: $white;
            margin-bottom: 0.3rem;
            transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
            &:hover {
              transform: translateX(10px);
              opacity: 0.8;
            }
          }
        }
      }
    }

    &_social-link {
      text-align: left;
      width: 30%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .links-container {
        margin-top: 4px;
        display: flex;
        flex-wrap: wrap;
        max-width: 315px;
        a {
          margin-right: 16px;
          margin-top: 16px;
          i {
            color: $main-color;
            font-size: 1.2rem;
            height: 2.5rem;
            width: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background: white;
            transition: all 0.25s ease-in-out;
            border: solid 1px transparent;
            font-weight: bold;
          }
          &:hover,
          &:active {
            i {
              opacity: 0.8;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .email-subscribe {
        padding-top: 64px;
        .input-subscribe {
          max-width: 265px;
          background-color: white;
          padding-right: 8px !important;
          border-radius: 30px !important;
        }

        .ant-btn-custom {
          width: fit-content;
          height: 32px !important;
          font-size: 13px !important;
          line-height: 20px !important;
          border-radius: 30px !important;
        }
      }
      .ant-input {
        // background-color: #333030;
        // color: $secondary_background_color;
      }
      .ant-input:hover {
        // background-color: #333030;
      }
    }
    .item-title {
      font-size: 1.5rem;
      margin-bottom: 1.3rem;
      font-weight: 700;
    }
  }
  .ant-btn-color-dark {
    background: $bg_icon !important;
    color: #fff !important;
    border: none;
  }
  &__text {
    color: $white;
    margin: 20px 0 0 0;
  }
  &_content {
    margin: 40px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: $primary_color;
    @media screen and (max-width: 500px) {
      padding: 0 5px;
    }
  }
  &_copyright {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    color: $secondary_color;
    margin-bottom: 0;
  }
  .footer-bottom {
    width: 100%;
    margin-top: 25px;
    padding-top: 15px;
    border-top: 1px solid rgba(230, 230, 230, 0.5);
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      justify-content: center;
      .footer-bottom-link {
        margin-top: 10px;
        font-size: 15px;
      }
    }
    .footer-bottom-link {
      font-size: 16px;
      a {
        color: $white;
        &:hover,
        *:focus {
          opacity: 0.8;
        }
        + a {
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px solid white;
        }
      }
    }
  }
  .footer_copyright {
    color: $white;
    a {
      color: $white;
      &:hover {
        color: #f11c25;
      }
    }
  }

  .divide-line {
    width: 60%;
    text-align: 'left';
    margin-left: 0;
  }

  .currency-price {
    display: grid;
    grid-column-gap: 10px;
    align-items: center;
    position: relative;
    grid-template-columns: min-content 1fr min-content !important;
    margin-bottom: 7.5px !important;
    box-shadow: 0 2px 3px 0 rgba(40, 40, 89, 0.09),
      0 3px 6px 0 rgba(7, 7, 7, 0.07);
    padding: 7.5px 10px;
    margin: 10px;
    background: linear-gradient(
      95.32deg,
      #b809b5 -7.25%,
      #ed1c51 54.2%,
      #ffb000 113.13%
    );
    border-radius: 10px;
    .price {
      display: flex;
      font-weight: 700;
      flex-direction: row !important;
      font-size: 13px !important;
      align-items: center !important;
      font-weight: 500 !important;
      width: 120px;
    }

    .coin-logo {
      width: 32.5px !important;
      margin-left: 0 !important;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 0px;
    }

    .value {
      font-weight: 400;
      margin-top: 0 !important;
      margin-left: auto;
    }

    .buy-now-link {
      justify-self: end;
      display: block;
      margin-right: 0 !important;
      background: #f18e2e26;
      color: white !important;
      padding: 3.5px 5.5px;
      line-height: 1;
      border-radius: 12%;
      font-size: 13px !important;
      font-weight: bold;
      &:hover {
        color: #5d0017 !important;
      }
    }
  }
}

#light-theme .footer .container-box {
  background: $main-color;
}

#dark-theme .footer .container-box {
  background: $primary_background_color_dark;
}

@media screen and (max-width: 950px) {
  .footer {
    .buy-now-link {
      position: initial !important;
      left: 0 !important;
    }
    &-top {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: initial;
    }
    .currency-price {
      width: 45%;
      .price {
        width: 100%;
      }
    }
    .item-title {
      font-size: 1.3rem !important;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .footer {
    padding: 70px 0 15px 0;

    .buy-now-link {
      position: absolute;
      left: 125px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .footer {
    .container {
      &_social-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        .cursor-pointer,
        .footer__text {
          text-align: center;
          color: $white;
        }
        .links-container {
          max-width: 270px !important;
          margin: auto;
          justify-content: center;
          a {
            margin-right: 0;
            padding: 0 7px;
          }
        }
        .email-subscribe .input-subscribe {
          max-width: 100% !important;
        }
      }
      &_page-link {
        width: 90%;
        margin: auto;
        &-item {
          padding: 0 15px;
          .item-title {
            font-size: 1.2rem !important;
          }
        }
      }
    }
    &-top {
      flex-wrap: wrap;
      .currency-price {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-m-mobile) {
  .footer {
    .container {
      &_page-link {
        flex-wrap: wrap;
        &-item {
          padding: 0 15px;
        }
      }
    }
    &-top {
      flex-wrap: wrap;
      .currency-price {
        width: 80%;
      }
    }
  }
}
