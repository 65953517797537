.header {
  height: 280px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.avatarWrapper {
  position: absolute;
  margin: auto;
  bottom: -40px;
  .avatar {
    border: 4px solid white
  }
}

.editButton {
  position: absolute;
  right: 42px;
  bottom: 31px;
}