@import '../../../styles/variables.scss';

.upload-dragger-custom {
    border: 1px solid $gray-color!important;
    background-color: transparent!important;
}

.upload-dragger-custom_video_wrap {
    background: $white;
    border: 1px solid $third_color;
    box-sizing: border-box;
    border-radius: 16px;
    min-height: 156px;
    display: flex;
    align-items: center;
    padding: 28px;
    margin-bottom: 16px;

    .upload-dragger-custom_video__container {
        position: relative;
        width: 100%;
        .upload-dragger-custom_cancelIcon {
            cursor: pointer;
            position: absolute;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid $input-prefix-background;
            box-sizing: border-box;
            box-shadow: 0px 8px 32px rgba(52, 87, 210, 0.1);
            background: $white;
            right: -10px;
            top: -10px;
            z-index: 10;
        }
    }
}

.ant-upload.ant-upload-drag.upload-dragger-custom {
    box-sizing: border-box;
    border-radius: 16px;
    padding-block: 78px;

    &.active {
        height: auto;
        cursor: pointer;
    }

    .ant-upload {
        padding: 0px 0px;
    }

    .upload-title {
        font-size: 16px;
        font-weight: $bold;
        color: $gray-color-3;
        margin: 24px auto 8px;
    }

    .upload-dragger-custom_title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: $secondary_color;
    }

    .upload-dragger-custom_btn {
        margin-top: 24px;
        display: inline-block;
        width: auto;
    }

    .upload-dragger-custom_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .upload-dragger-custom_wrap {
        position: relative;
        .upload-dragger-custom_cancelIcon {
            cursor: pointer;
            position: absolute;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid $input-prefix-background;
            box-sizing: border-box;
            box-shadow: 0px 8px 32px rgba(52, 87, 210, 0.1);
            background: $white;
            right: -10px;
            top: -10px;
            z-index: 10;
        }
    }

    .upload-dragger-custom_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .upload-dragger-custom_wrap {
        position: relative;
        .upload-dragger-custom_cancelIcon {
            cursor: pointer;
            position: absolute;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid $input-prefix-background;
            box-sizing: border-box;
            box-shadow: 0px 8px 32px rgba(52, 87, 210, 0.1);
            background: $white;
            right: -10px;
            top: -10px;
            z-index: 10;
        }
    }
}

#dark-theme .ant-upload.ant-upload-drag.upload-dragger-custom {
    background-color: #4c505c !important;

    .upload-title {
        color: $white;
    }

    .upload-dragger-custom_title {
        color: #b1adaa;
    }

    .upload-dragger-custom_btn {
        background-color: rgb(48, 51, 57) !important;
        color: $white !important;
    }
}

.ant-form-item-has-error .ant-upload.ant-upload-drag.upload-dragger-custom {
    border: 1px solid $red;
}
