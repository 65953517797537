@import '../../../styles/variables.scss';

.iconWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 500px) {
        img {
            width: 13px !important;
        }
    }
}

.iconWrapperDark {
    background: rgb(48, 51, 57);
    border-radius: 50%;
}

:global(.ant-carousel) {
    .customCarousel {
        :global(.slick-next),
        :global(.slick-prev) {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            background: $white;
            border-radius: 50%;
            box-shadow: 0px 8px 32px rgba(52, 87, 210, 0.1);
            z-index: 1;
            &:hover {
                color: $primary_color;
                background: $white;
            }
        }
    }
}
