@import '../../styles/variables.scss';

.explore {
    padding-top: 100px;
    padding-bottom: 40px;

    margin: 0 auto;
    color: $primary_color;
    @media screen and (max-width: 576px) {
        padding: 0;
        margin-bottom: 60px;
    }
}

.exploreLists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .exploreListsRow {
        width: 100%;
        z-index: 1;
    }
}

.exploreRow {
    width: 100%;
}

.exploreListsBtn {
    margin-top: 50px;
    margin-bottom: 20px;
}

.filter {
    display: inline-flex;
    align-items: center;
    @media screen and (max-width: 576px) {
        width: 100%;
        &:global() {
            :global(.select-custom-wrapper) {
                flex: 1;
            }
        }
    }
    .right {
        margin-right: 16px;
        min-width: 203px;
        @media screen and (max-width: 576px) {
            margin-right: 0;
            min-width: 40%;
        }
    }

    .left {
        min-width: 203px;
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                :global(.ant-select-selection-item) {
                    margin-left: 80px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .select {
        &:global(.ant-select) {
            background-color: red !important;
            :global(.ant-select-selector) {
                background-color: transparent;

                :global(.ant-select-selection-item) {
                    color: $primary_color;
                    font-weight: 400;
                }

                :global(.ant-select-selection-placeholder) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    color: $primary_color;
                }
            }

            :global(.ant-select-arrow) {
                color: $primary_color;
            }
        }
    }

    .iconFilter {
        margin-right: 8px;
    }
}

