@import 'styles/variables.scss';

.filter-types-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .filter-button-container {
        flex-wrap: wrap;
        justify-content: center;
    }
    .blue-text {
        font-size: 16px;
        font-weight: 500;
        color: $primary_color;
        margin: 0 0 12px;
        text-align: left;
    }

    .button-filter {
        // height: 36px;
        // margin: 5px;
        // width: auto;
        // border-color: $input-prefix-background !important;
        // margin-right: 11px;
        // font-size: $fs-text !important;
        // font-family: 'circular';
        // @media screen and (max-width: $breakpoint-tablet) {
        //     font-size: 14px;
        // }
        // &.ant-btn-color-white {
        //     background-color: transparent !important;
        // }

        // .button-filter {
        //     display: block !important;
        //     height: 36px !important;
        //     max-width: fit-content;
        //     border-color: $input-prefix-background !important;
        //     margin-right: 16px;
        //     font-size: $fs-text !important;
        //     @media screen and (max-width: $breakpoint-tablet) {
        //         font-size: 14px;
        //     }
        //     &.ant-btn-color-white {
        //         background-color: transparent !important;
        //     }
        // }
        // .ant-btn-color-blue {
        //     img {
        //         filter: brightness(0) invert(1);
        //     }
        // }
        margin-right: 12px;
            span {
                color: $gray-color-2;
                font-weight: 500;
                font-size: 14px;
                padding-left: 8px;
            }
    }
}

#dark-theme .filter-types-container {
    .button-filter {
        &.ant-btn-color-white {
            background-color: rgb(48, 51, 57) !important;
            color: white !important;
        }
        img {
            filter: brightness(0) invert(1);
        }
    }
}

.container-align-left {
    justify-content: start;
    margin-top: 15px;
}
