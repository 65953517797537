@import './variables.scss';
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin up-to-size($key, $breakpoint: $breakpoints) {
    @media (max-width: map-get($breakpoint, $key)) {
        @content;
    }
}

@mixin max-size($breakpoint) {
    @media (max-width: $breakpoint) {
        @content;
    }
}

@mixin min-size($key, $breakpoint: $breakpoints) {
    @media (min-width: map-get($breakpoint, $key)) {
        @content;
    }
}

@mixin border-radius($point) {
    border-radius: $point;
}

@mixin display-medium {
  font-weight: bold;
  font-size: 36px;
  line-height: 41px;
  letter-spacing: 0%;
}

@mixin heading-extra-large {
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.36%;
}

@mixin heading-large {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.35%;
}

@mixin heading-small {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.41%;
}

@mixin text-large-bold {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

@mixin text-large-medium {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

@mixin text-medium-regular {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

@mixin text-medium-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

@mixin text-normal-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

@mixin text-normal-semibold {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@mixin text-normal-medium {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@mixin text-normal-regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin text-small-bold {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

@mixin text-small-medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

@mixin text-small-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-tiny-bold {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

@mixin text-tiny-medium {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@mixin text-tiny-regular {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}  
