@import "styles/variables.scss";

.liked-container {
  // margin-top: 70px !important;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-top: 20px !important;
  }
  .filter-option {
    position: relative;
    margin-top: 10px;
    // @media screen and (min-width: $breakpoint-tablet) {
    //   position: absolute;
    //   right: 20px;
    //   top: -100px;
    //   margin-top: 0px;
    // }
    .btn-filter {
      height: 34px !important;
      font-size: 17px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      .btn-filter {
        font-size: 14px;
      }
    }
    .btn-filter-custom-dark {
      svg {
        path {
          fill: $white-text !important;
        }
      }
    }
  }
}
