@import "styles/variables.scss";
@import "styles/mixins.scss";

.container-refferal {
  width: 100%;
  background-color: $white;
  padding: 28px;
  border-radius: 28px;
  border: 1px solid $gray-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: $gray-color-3;
    margin: 0;
  }
  .image-container {
    display: flex;
    justify-content: center;
    margin: 32px 0;
  }
  .container-list-refferal {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 32px;
    @include up-to-size("lg") {
      flex-direction: column;
    }
    .refferal:nth-child(1) {
      margin-right: 16px;
      @include up-to-size("lg") {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .refferal:nth-child(2) {
      margin-left: 16px;
      @include up-to-size("lg") {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .refferal {
      padding: 10px 20px;
      // min-width: 300px;
      width: 100%;
      height: 132px;
      // border: 1px solid $main-color;
      border-radius: 16px;
      background-color: $layout_background;
      @include up-to-size("lg") {
        width: 100%;
        margin-bottom: 20px;
      }
      .title-refferal {
        color: $gray-color-3;
        font-size: 20px;
        font-weight: 600;
      }
      .content-refferal-container {
        margin-top: 16px;
        border-radius: 16px;
        height: 56px;
        color: $gray-color-2;
        font-size: 20px;
        display: flex;
        gap: 20px;
        background: white;
        border-radius: 16px;
        font-weight: 500;
        background-color: $secondary_background_color;
        .content-refferal {
          margin: auto 0 auto 24px;
          span:nth-child(1) {
            margin-right: 20px;
          }
          span:nth-child(2) {
            cursor: pointer;
            color: $gray-color-3;
          }
        }
      }
    }
  }
}
