@import '../../styles/variables.scss';

.banner-lens {
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 35px 0;
    text-align: center;
    border-bottom: 1px solid $color-gray-blue;
    @media screen and (max-width: $breakpoint-tablet) {
        padding: 50px 0;
    }
}

#dark-theme .banner-lens {
    background-color: $primary_background_color_dark;
}

.title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 48px;
    font-weight: bold;
    color: $primary_color;
    @media screen and (max-width: $breakpoint-tablet) {
        font-size: 40px;
    }
}

#dark-theme .title {
    color: $white-text;
}

.description {
    color: $secondary_color;
    max-width: 30rem;
    margin: auto;
}

.content {
    padding: 100px 50px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    .box-left {
        width: 48%;
    }
    @media screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
        padding: 70px 0;
        .box-left {
            width: 100%;
        }
    }
}

.ant-collapse > .ant-collapse-item {
    &.custom-panel {
        margin-top: 16px;
        font-size: 16px;

        & > .ant-collapse-header {
            border-radius: 10px !important;
            border: 1px solid $color-gray-blue;
            background: #fff;
            color: $primary_color;
            display: flex;
            outline: none;
            align-items: center;
            padding: 25px;
            font-size: 1.2rem;
            font-weight: 600;

            .collapseIcon {
                padding: 0;
                top: unset;
            }
        }

        &.ant-collapse-item-active > .ant-collapse-header {
            color: $primary_color;
            font-weight: bold;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        .ant-collapse-content-box {
            color: #555;
            padding: 25px !important;
            background-color: $white;
            border: 1px solid $color-gray-blue;
            font-size: 16px;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            word-break: break-all;
            white-space: pre-wrap;
            vertical-align: 2px;
            font-weight: 400;
        }
    }
}

#dark-theme .ant-collapse > .ant-collapse-item {
    &.custom-panel {
        & > .ant-collapse-header {
            background-color: rgb(48, 51, 57);
            color: $white;
        }

        &.ant-collapse-item-active > .ant-collapse-header {
            color: $white;
        }

        .ant-collapse-content-box {
            background-color: rgb(48, 51, 57);
            color: $white;
        }
    }
}
