@import '../../styles/variables.scss';
@import '../../styles/common.scss';

.explore-container {
    color: $primary_color;
    .container {
        padding: 0 10px !important;
    }
    &__header {
        .title {
            @include heading-extra-large;
            color: $gray-color-3;
            text-align: center;
            @extend %title-page;
            margin-bottom: 24px;
        }
        .head-filter {
            display: flex;
            justify-content: center;
            width: 100%;
            overflow: auto;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(163, 174, 208, 0.2);
            .tab-btn {
                margin: 0 5px;
                color: $primary_color;
            }
        }
        .filter {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;
            width: 100%;
            padding: 20px 0;
            border-top: 1px solid rgba(163, 174, 208, 0.2);
            .left {
                min-width: 203px;
                &:global(.ant-select) {
                    :global(.ant-select-selector) {
                        :global(.ant-select-selection-item) {
                            margin-left: 80px;
                            text-transform: lowercase;
                        }
                    }
                }
            }
            .select {
                &:global(.ant-select) {
                    :global(.ant-select-selector) {
                        background-color: white;
                        height: 34px !important;
                        box-shadow: 0px 2px 4px $shadow_color;
                        border: solid 1px rgba(10, 33, 65, 0.05);

                        :global(.ant-select-selection-item) {
                            color: $primary_color;
                            font-weight: 400;
                        }

                        :global(.ant-select-selection-placeholder) {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;

                            color: $primary_color;
                        }
                    }

                    :global(.ant-select-arrow) {
                        color: $primary_color;
                    }
                }
            }
        }
        .categories-filter {
            padding: 20px 0;
        }
        .button-filter-mobile {
            width: auto;
        }
    }
    &__content {
        // width: 80%;
        margin: 0 -10px;
        padding: 50px 0 50px 0;

        .card-item-custom {
            .ant-card-body {
                .card-item-custom-avatar {
                    aspect-ratio: initial;

                    & > img {
                        height: 240px;
                    }
                }
            }
        }
    }
    .filter-content {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
    }
    .filter-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;

        button {
            svg {
                height: 11px !important;
                width: 11px !important;
            }
            span {
                padding-right: 0px !important;
            }
        }
    }
    .sort-by {
        margin-left: 10px;
    }

    .ant-select.ant-select-custom .ant-select-selector,
    .btn-filter {
        display: flex;
        height: 40px !important;
        justify-content: space-between;
        column-gap: 12px;
        padding-right: 9px !important;
        font-variant: tabular-nums;
        font-size: 14px !important;
        .icon {
            margin: 0px !important;
        }
    }
    .ant-select-custom {
        min-width: 160px;
    }

    .select-placeholder {
        display: flex;
        align-items: center;
        gap: 8px;
        @include text-normal-medium;
    }
    .ant-select-selection-item {
        @include text-normal-medium;
    }
    .ant-select-dropdown.custom-dropdown {
        z-index: 999 !important;

        &.select__dropdown--chains {
            width: 188px !important;
        }

        &.select__dropdown--categories {
            width: 210px !important;
        }

        &.select__dropdown--price-range {
            width: 270px !important;

            .pricerange-header {
                @include text-normal-bold;
                color: $gray-color-3;
                width: 100%;
            }
        }

        &.select__dropdown--collections {
            width: 205px !important;

            .collection-holder {
                margin-right: -16px;
            }
        }

        &.select__dropdown--filter {
            width: 220px !important;
        }

        &.select__dropdown--properties {
            width: 205px !important;
        }
    }
}

#dark-theme .explore-container {
    &__header {
        background-color: $primary_background_color_dark;
        .title {
            color: $btn-blue;
        }
        .filter-container {
            .filter-content {
                .filter-right {
                    button {
                        svg {
                            path {
                                fill: $white-text;
                            }
                        }
                        span {
                            padding-right: 0 !important;
                        }
                    }
                }
            }
        }
        .select-custom-wrapper {
            .ant-select-arrow {
                color: white;
            }
        }
    }
}

#dark-theme #explore {
    .home-explore {
        .container {
            .no-item-container {
                p:first-of-type {
                    color: $white-text;
                }
                p:nth-of-type(2) {
                    color: $second_white_text;
                }
            }
        }
    }
}

.ant-select-arrow {
    top: 50%;
}

.filter-btn {
    min-width: 85px;
}

.dropdown-explorer {
    padding: 16px;
    // max-width: 220px;
    background-color: $white;
    color: rgb(4, 4, 5);
    border-radius: 12px;
    font-weight: normal;
    outline: 0px;
    box-shadow: rgba(4, 4, 5, 0.2) 0px 7px 36px -8px;
    min-width: 180px;
    .ant-menu {
        border-right: none !important;
    }
    .sale-type-item {
        cursor: pointer;
        color: rgb(4, 4, 5);
        transition: all 0.12s ease-in-out 0s;
        text-align: left;
        min-height: 35px;
        padding: 12px 16px;
        margin: 0px 12px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
            background-color: #e6f7ff;
        }
    }
    .sale-type-filter-button {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        padding: 12px 0 0 0;
        justify-content: space-between;
    }
    .filter-btn {
        background-color: #ebeeef;
    }
    #selected {
        background-color: #e6f7ff !important;
    }
    .ant-menu-item-selected {
        background-color: transparent !important;
    }
    .ant-dropdown-menu {
        box-shadow: none;
    }
}

.dark-price-input-holder {
    .price-input {
        color: $white-text;
    }
}

.price-input-holder {
    .price-input {
        margin-top: 5px;
    }
}

.dropdown-price-range {
    .currency-select {
        padding: 12px 18px;
        text-align: center;
    }
    .price-input-holder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
        padding: 0 18px;
        .price-input {
            max-height: 34px;
        }
    }
    .ant-divider-horizontal {
        margin: 8px 0 0 0;
    }
    .pricerange-header {
        font-size: 14px;
        font-weight: 500;
        color: rgb(4, 4, 5);
        width: 100%;
        text-align: center;
        height: 12px;
        margin: auto;
    }
}

.dropdown-collection {
    .ant-divider-horizontal {
        margin: 0;
    }
    .collection-holder {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 220px;
        overflow-y: auto;
        overflow-x: hidden;
        row-gap: 16px;
        margin: 16px 0;
        .property-filter-modal_item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            border-radius: 6px;
            max-height: 42px;
            width: 100%;
            padding: 10px 10px;
            align-items: center;
            &:hover {
                background-color: rgba(4, 4, 4, 0.1);
            }
            .property-filter-modal_item-left {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                width: 90%;
                .property-filter-modal_item-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 60%;
                }
            }
            .ri-check-line {
                color: $btn_blue;
                font-size: 16px;
                font-weight: 600;
            }
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: $primary_background_color;
        }
        &::-webkit-scrollbar-thumb {
            height: 8px;
            background-color: $btn_blue;
            border-radius: 5px;
            width: 4px;
            margin: auto;
        }
        &::-webkit-resizer {
            width: 4px;
        }
        scrollbar-width: 6px;
        scrollbar-color: $btn_blue;
        .collection-item-holder {
            display: flex;
            width: 100%;
            column-gap: 20px;
            align-items: center;
            // padding: 10px 10px;
            border-radius: 8px;
            max-height: 42px;
            cursor: pointer;
            &:hover {
                background-color: rgba(4, 4, 5, 0.05);
                color: rgb(4, 4, 5);
            }
            .collection-name {
                display: flex;
                align-items: center;
                column-gap: 20px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                .collection-name-text-holder {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    max-width: 100%;
                    width: 100%;
                    overflow: hidden;
                    .collection-name-text {
                        height: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 40px;
                        // font-weight: 600;
                    }
                }
            }
            .ri-check-line {
                color: $white;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

.collection-search-input-holder {
    margin: 0;
}

.property-filter-modal {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-radius: 6px;
    margin: 20px 0;
    max-height: 600px;
    overflow-y: auto;
    .property-filter-modal_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        border-radius: 6px;
        max-height: 42px;
        &:hover {
            background-color: rgba(4, 4, 4, 0.1);
        }
        .property-filter-modal_item-left {
            display: flex;
            flex-direction: row;
            column-gap: 20px;
            max-width: 300px;
            .property-filter-modal_item-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: $primary_background_color;
    }
    &::-webkit-scrollbar-thumb {
        height: 8px;
        background-color: $btn_blue;
        border-radius: 5px;
        width: 4px;
        margin: auto;
    }
    &::-webkit-resizer {
        width: 4px;
    }
    scrollbar-width: 6px;
    scrollbar-color: $btn_blue;
}

.dropdown-sortby {
    .collection-holder {
        max-height: 390px;
    }
    .verification-holder {
        @include text-small-regular;
        color: $gray-color-3;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 12px;
        width: 100%;
    }
    .filter-title {
        @include text-normal-bold;
        width: 100%;
        color: $gray-color-3;
    }
    .collection-item-holder {
        max-height: 30px !important;
    }
}

@media screen and (min-width: $breakpoint-xl) {
    .explore-container .filter-container {
        .filter-content {
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 662px) {
    .head-filter {
        justify-content: unset !important;
    }
}

@media screen and (max-width: 576px) {
    .explore-container {
        padding: 0;
        // margin-bottom: 60px;
    }
}

.exploreLists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .exploreListsRow {
        width: 100%;
        z-index: 1;
    }
}

.exploreListsBtn {
    margin-top: 50px;
    margin-bottom: 20px;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        .filter {
            margin-top: 24px;
        }
    }
    @media screen and (max-width: 576px) {
        padding: 16px;
        margin-bottom: 24px;
        .exploreHeader {
            font-size: 24px;
        }
        .filter {
            margin-top: 12px;
        }
    }
}

.filter {
    display: inline-flex;
    align-items: center;
    @media screen and (max-width: 576px) {
        margin-top: 24px;
        width: 100%;
        &:global() {
            :global(.select-custom-wrapper) {
                flex: 1;
            }
        }
    }
    .right {
        margin-right: 16px;
        min-width: 203px;
        @media screen and (max-width: 576px) {
            margin-right: 0;
            min-width: 40%;
        }
    }

    .left {
        min-width: 203px;
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                :global(.ant-select-selection-item) {
                    margin-left: 80px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .select {
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                background-color: transparent;
                // border: 1px solid $primary_color !important;

                :global(.ant-select-selection-item) {
                    color: $primary_color;
                    font-weight: 400;
                }

                :global(.ant-select-selection-placeholder) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    color: $primary_color;
                }
            }

            :global(.ant-select-arrow) {
                color: $primary_color;
            }
        }
    }

    .iconFilter {
        margin-right: 8px;
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .explore-container {
        &__content {
            margin: 0 auto;
        }
    }
}

.dropdown-explore-dark-antd {
    .filter-title {
        color: $white;
    }
}
