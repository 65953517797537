@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.ant-modal.create-collection_custom {
  .ant-avatar.ant-avatar-icon {
    font-size: 18px;
  }
  width: 892px !important;
  .ant-modal-content {
    margin: 70px 0;
    border-radius: 32px !important;
    max-width: 100%;
    width: 892px;
  }
  .ant-modal-close {
    top: 16px;
    right: 24px;
  }
  .ant-modal-body {
    padding: 32px 40px;
  }
  .create-collection_header {
    margin-bottom: 32px;
    .create-collection_title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: $gray-color-3;
    }
  }

  .create-collection_block {
    margin-bottom: 24px;
    .prefix {
      color: $gray-color-2;
      font-size: 16px;
    }
    input {
      background-color: white;
    }
  }

  .header-title {
    color: $gray-color-3;
    font-size: 20px;
    font-weight: 600;
  }

  .create-collection-select-category {
    .ant-select-selector {
      background-color: white !important;
    }
  }

  .create-collection_btnGroups {
    display: flex;
    justify-content: center;
    align-items: center;
    .create-collection_btn {
      flex: 1;
      &.mr {
        margin-right: 12px;
      }

      &.ml {
        margin-left: 12px;
      }
    }
    .ant-btn-color-gray {
      background-color: $gray-color !important;
      color: $gray-color-3 !important;
    }
  }

  .create-collection-fix-all-error {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $red;
  }

  .input-group {
    margin-top: 16px;
    & > * {
      margin-top: 16px;
    }

    .ant-input {
      background-color: white;
    }
  }

  .ant-form-item {
    margin-bottom: 16px !important;
    textarea {
      height: auto;
    }
  }

  .form-item-social {
    margin-top: 16px;
    &:first-child {
      margin-top: 0;
    }
  }

  .title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
    display: block;
    color: $gray-color-3;
  }

  .label {
    font-weight: 500;
    margin-bottom: 8px;
    color: $gray-color-3;
    font-size: 16px;
  }
  .label-custom {
    &_title {
      color: $gray-color-3 !important;
      font-weight: 500 !important;
    }
    &_desc {
      font-size: 14px;
      color: $gray-color-3 !important;
    }
    &_subdesc {
      color: $gray-color-2;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .ant-modal-close {
    top: 8px !important;
    right: 8px !important;
  }
  .ant-modal-body {
    padding: 24px !important;
  }
}
