.custom-avatar {
    position: relative;
    width: 40px;
    height: 40px;
    & > :nth-child(2) {
        position: absolute;
        right: -3px;
        bottom: 2px;
    }

    .verified {
        position: absolute;
        right: -3px;
        bottom: -5px;
    }
}

.custom-avatar-big {
    position: relative;
    width: 50px;
    height: 50px;
    & > :nth-child(2) {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
}

.custom-avatar-small {
    position: relative;
    width: 32px;
    height: 32px;
    & > :nth-child(2) {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
}
