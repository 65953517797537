.activity-profile {
    .activity-content-profile {
        margin-top: 40px;
        margin-bottom: 24px;
        .no-activity-container {
            .no-activity-container-img {
                height: 90px;
                margin-left: 17px;
            }
        }
    }
}
.tabs {
    .ant-tabs-content-holder {
        .no-item-container {
            margin-top: 30px !important;
            img {
                margin-left: 16px;
            }
        }
    }
}
