@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.select-avatar {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 24px;

    .select-avatar_title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: $gray-color-3;
    }
    .content {
        padding: 0 !important;
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .recommend {
            color: $gray-color-3;
            display: block;
        }
    }
    //temp
    @include up-to-size('md') {
        height: auto;
        justify-content: space-between;
        align-items: flex-start;
        .select-avatar_title {
            font-size: 16px;
            margin-bottom: 4px;
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 16px;
            flex: 1;
            border-top: none;
            padding: 0 !important;
            .recommend {
                text-align: start;
                font-size: 12px !important;
            }
        }
    }
}
