@import 'styles/variables.scss';

.home-explore {
    padding: 50px 0;
    color: $primary_color;
    height: fit-content;
    overflow-x: auto;
    .home-page-category__header {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $breakpoint-mobile) {
            flex-wrap: wrap !important;
        }
        h2 {
            line-height: 1;
        }
        a {
            font-size: 15px;
            font-family: $font-secondary;
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }
}
@media screen and (max-width: $breakpoint-xxl) {
    .explore {
        width: 90%;
    }
}

@media screen and (max-width: 576px) {
    .explore {
        padding: 0;
        margin-bottom: 60px;
    }
}

.exploreLists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .exploreListsRow {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.boxLoading {
    display: flex;
    justify-content: center;
    padding: 70px 0;
}

.viewAll {
    display: flex;
    justify-content: center;
    margin-top: 50px !important;
    margin: auto;
    .buttonViewAll {
        display: flex;
        align-items: center;
        padding: 3px 15px;
        border-radius: 20px;
        color: #fff;
    }
    .iconViewAll {
        margin-right: 7px;
    }
}
.exploreListsBtn {
    height: 36px !important;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: flex-start;
        .filter {
            margin-top: 24px;
        }
    }
    @media screen and (max-width: 576px) {
        padding: 16px;
        margin-bottom: 24px;
        .exploreHeader {
            font-size: 24px;
        }
        .filter {
            margin-top: 12px;
        }
    }
}

.filter {
    display: inline-flex;
    align-items: center;
    @media screen and (max-width: 576px) {
        margin-top: 24px;
        width: 100%;
        &:global() {
            :global(.select-custom-wrapper) {
                flex: 1;
            }
        }
    }
    .right {
        margin-right: 16px;
        min-width: 203px;
        @media screen and (max-width: 576px) {
            margin-right: 0;
            min-width: 40%;
        }
    }

    .left {
        min-width: 203px;
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                :global(.ant-select-selection-item) {
                    margin-left: 80px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .select {
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                background-color: white;
                height: 34px !important;
                box-shadow: 0px 2px 4px $shadow_color;
                border: solid 1px rgba(10, 33, 65, 0.05);

                :global(.ant-select-selection-item) {
                    color: $primary_color;
                    font-weight: 400;
                }

                :global(.ant-select-selection-placeholder) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    color: $primary_color;
                }
            }

            :global(.ant-select-arrow) {
                color: $primary_color;
            }
        }
    }

    .iconFilter {
        margin-right: 8px;
    }
}
