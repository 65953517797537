@import 'styles/variables.scss';

.about-holder {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    .about-content-container {
        width: 90%;
        margin: 0px auto 100px;
        .sub-title {
            margin-bottom: 64px;
            display: block;
            width: 100%;
            font-size: 40px;
            text-align: center;
            color: #000;
            font-weight: 700;
        }
    }
    .text-holder {
        text-align: center;
        .title-page {
            font-family: inherit;
            font-weight: 700;
            font-size: 42px;
            vertical-align: inherit;
            margin-bottom: 0;
        }

        .sub-header-title {
            display: block;
            width: 100%;
            margin-top: 8px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
        }
        .video-holder {
            margin-top: 80px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .box-our-story {
        .title-story {
            font-style: normal;
            font-weight: 400;
            font-size: 52px;
            line-height: 68px;
            letter-spacing: -0.5px;
            text-transform: uppercase;
            color: #000;
            margin-bottom: 20px;
        }
        .our-story {
            display: flex;
            justify-content: center;
            .vertical-line {
                width: 8px;
                height: 180px;
                background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
                border-radius: 10px;
                margin-right: 1.5rem;
            }
            .content-story {
                font-style: italic;
                font-weight: 400;
                font-size: 32px;
                line-height: 140%;
                max-width: 838px;
                width: 100%;
                color: #1d2939;
                position: relative;
            }
        }
    }

    .box-vision {
        display: flex;
        justify-content: space-around;
        // flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        .box-content {
            width: 30%;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            .vision-title {
                font-weight: 800;
                font-size: 24px;
                line-height: 33px;
                text-align: center;
                color: #000;
            }
            img {
                width: 48px;
                height: 48px;
            }
            p {
                max-width: 298px;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                letter-spacing: 0.2px;
                width: 100%;
                color: #000;
            }
        }
    }
    .box-our-team {
        .title-our-team {
            font-style: normal;
            font-weight: 400;
            font-size: 52px;
            line-height: 68px;
            letter-spacing: -0.5px;
            text-transform: uppercase;
            color: #000;
            text-align: center;
            padding: 48px 0;
        }
        .list-avatar {
            display: flex;
            padding: 50px 0 0 0;
            justify-content: space-around;
            img {
                width: 126px;
                height: 126px;
            }
        }
    }
    .box-support-us {
        display: flex;
        .box-sp-form {
            width: 50%;
            margin-right: 30px;
            .box-sp-form-title {
                font-style: normal;
                font-weight: 400;
                font-size: 52px;
                line-height: 68px;
                text-transform: uppercase;
                color: #000;
            }
            .ant-input {
                border-top: none;
                border-right: none;
                border-left: none;
                border-bottom: 1px solid hsla(0, 0%, 80%, 0.8) !important;
                border-radius: unset;
                padding: 8px 12px;
                background-clip: padding-box;
                background-color: $primary_background_color;
            }
            input::placeholder,
            textarea::placeholder {
                font-weight: bold;
                opacity: 0.6;
                line-height: 1.5;
                color: #212529;
            }
            input:focus {
                border: none;
                box-shadow: none;
                -webkit-box-shadow: none;
            }
            textarea {
                border-bottom: 1px solid hsla(0, 0%, 80%, 0.8) !important;
                border-radius: unset;
                min-height: 125px;
                line-height: 1.5715;
            }
            textarea:focus {
                border: none;
                box-shadow: none;
                -webkit-box-shadow: none;
            }
            button {
                margin-top: 20px;
                border-radius: 10px;
                width: 100%;
                color: $white;
                background: #EE811A !important;
                font-size: 14px;
                font-weight: 400;
                border: none;
                padding: 6px 12px;
                height: 50px;
            }
        }
    }
}
.text-dark-about {
    color: $white !important;
}
@media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    .about-holder {
        .our-story {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .list-avatar {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
