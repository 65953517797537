@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.bid-form-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px !important;
    .ant-modal-content {
        width: 100% !important;
    }
    .quantity-container {
        .quantity-input {
            input {
                padding-right: 95px !important;
            }
        }
        .ant-btn-custom {
            width: 93px !important;
        }
    }
}

.bid-form-modal-container {
    font-size: 16px;
    color: $primary-color;
    p {
        margin: 0px;
    }

    .error-balance {
        color: red;
    }
    a {
        color: $primary-color;
        font-family: $font-primary;
        &:hover {
            color: $primary-color;
        }
    }

    & > :last-child {
        margin: auto;
        width: 100%;
    }
    .gray {
        color: $gray-color-3;
    }
}
