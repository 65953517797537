@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.collection-container {
  color: $primary_color;
  .title {
    @include heading-extra-large;
    color: $gray-color-3;
    text-align: center;
    margin-bottom: 40px;
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 50px 0;
      font-size: 40px;
    }
  }
  .header-filter {
    overflow-x: auto;
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid $color-gray-blue;
    border-bottom: 1px solid $color-gray-blue;

    .ant-select-selector {
      height: 32px !important;
    }
  }
  .header-filter-two {
    padding: 15px 0 20px;
    border: none;
  }
  .option-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    .ant-switch {
      margin: 0 10px;
    }
    .filter-by,
    .sort-by {
      color: $secondary_color;
      font-size: 17px;
      text-transform: uppercase;
    }
    .filter-by {
      margin-right: 10px;
    }
    .header-sort-by,
    .header-filter-by {
      display: flex;
      align-items: center;
      font-size: 17px;
      gap: 12px;
    }

    .header-sort-by {
      .btn-filter {
        margin-right: 0 !important;
      }
    }

    .sort-by {
      margin: 0 10px;
    }

    .select-blockchain {
      margin-left: 10px;
    }

    .select-categorie {
      margin-right: 10px;
      min-width: 200px;
      .ant-select-selection-item {
        font-size: 14px;
      }
    }

    .btn-filter {
      border-radius: 18px;
      padding: 0 15px;
      color: $primary_color;
      margin-right: 10px;
      margin-left: 0 !important;
      display: flex;
      align-items: center;
      border: 1px solid $input-prefix-background !important;

      & > span {
        padding-right: 15px;
      }
      & svg {
        width: 10.2px;
        height: 10.2px;
        path {
          fill: #474873;
        }
      }
      &:hover {
        border: 1px solid $video-background !important;
      }
    }
  }

  .collection-page-filter {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .btn-filter {
      font-size: 17px;
    }
    .select-custom-wrapper {
      .ant-select.ant-select-custom {
        min-width: 200px;
        z-index: 999 !important;

        .ant-select-selector {
          height: 40px !important;

          .ant-select-selection-item {
            font-size: 17px;
          }

          .select-placeholder {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
  &__header-two {
    background-color: transparent;
  }
  &__content {
    padding: 100px 0;
    @media screen and (max-width: $breakpoint-tablet) {
      padding: 70px 0;
    }
    .collection-list-btn {
      margin: auto;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
  &__profile-content {
    padding: 50px 0;
  }

  .collection-list {
    padding: 28px;
    border-radius: 28px;
    background-color: $white;

    &__tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;
    }

    &__content {
      &__header {
        .ant-row {
          padding-bottom: 16px;
          border-bottom: 1px solid #f0eeee;

          .ant-col {
            @include text-normal-medium;
            color: $gray-color-2;
          }
        }
      }

      &__body {
        .no-data-container {
          margin-top: 28px;
        }

        .loading-data {
          margin: auto;
          margin-top: 50px;
          margin-bottom: 20px;
        }

        .ant-row {
          &:not(:last-child) {
            border-bottom: 1px solid #f0eeee;
          }
          .ant-col {
            padding: 16px 0;
            margin: auto 0;
            @include text-normal-medium;
            color: $gray-color-3;

            .owner-avatar {
              padding-left: 16px;
              display: flex;
              align-items: center;
              gap: 14px;

              .avatar-holder {
                position: relative;

                .verify-icon {
                  position: absolute;
                  top: 32px;
                  right: 0;
                }
              }

              .collection-name {
                @include text-normal-semibold;
                line-height: 20px;
                color: $gray-color-3;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .change {
              &--up {
                color: #67b629;
              }

              &--down {
                color: #952623;
              }
            }
          }
        }
      }
    }
  }
}

#dark-theme .collection-container {
  &__header {
    background-color: $primary_background_color_dark;

    .title {
      color: $btn-blue;
    }

    .header-filter {
      border-top: 1px solid rgba(163, 174, 208, 0.2);
      border-bottom: 1px solid rgba(163, 174, 208, 0.2);
    }
  }
  .header-filter-by {
    button {
      svg {
        path {
          fill: $white-text;
        }
      }
    }
    .select-categorie {
      .ant-select-arrow {
        svg {
          path {
            fill: $white-text;
          }
        }
      }
    }
  }
  .header-sort-by {
    button {
      svg {
        path {
          fill: $white-text;
        }
      }
    }
  }
}
.dropdown-filterby {
  border-radius: 10px !important;
  padding: 10px 0 !important;
  .ant-dropdown-menu-title-content {
    font-size: 16px;
  }
  .ant-dropdown-menu {
    border-radius: 10px;
  }
  .switch-filter {
    display: unset !important;
    margin-right: 10px;
  }
}

@media screen and (min-width: 1300px) {
  .collection-container {
    &__header {
      .option-filter {
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .collection-container .collection-page-filter {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 12px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .collection-container__header {
    .header-filter {
      padding: 20px 12px;
    }

    .option-filter {
      justify-content: unset;
    }
  }
  .header-filter-by {
    flex-direction: column;
  }
  .collection-container .collection-page-filter {
    margin-bottom: 0;
  }
}
