@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.home-page {
    .network-tabs {
        margin-bottom: 60px;

        .ant-tabs-nav {
            margin: 0;

            .ant-tabs-nav-list {
                padding: 0;
                gap: 16px;

                .ant-tabs-tab {
                    padding: 0 16px;
                    margin: 0;
                    box-shadow: none;
                    border: 1px solid $gray-color;

                    &.ant-tabs-tab-active {
                        border: 1px solid rgba(238, 129, 26, 0.3);
                    }

                    .label {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin: 0;
                        @include text-small-medium;
                        color: $gray-color-3;
                    }
                }
            }
        }
    }

    .hot-person-container {
        // width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        margin-top: 50px;
    }

    .container {
        margin: 0 auto;
        max-width: 1200px;
        padding: 0 10px;
    }

    &-category__header {
        font-size: $fs-title-box;
        font-family: $font-primary;
        margin-bottom: 24px;

        h2 {
            font-size: $fs-title-box;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &-carousel {
        margin-left: -12px;
        margin-right: -12px;

        &__item {
            padding: 12px;
        }
    }
}

.box-hot-sell-buy {
    padding: 50px 0;

    .hot-buy-sell-content {
        margin: 0 -12px;
        display: flex;
        justify-content: space-between;
    }

    .top-artists {
        color: $primary_color;
        background-color: transparent;
        min-height: 300px;
        width: 50%;
        padding: 0 12px;
        display: flex;
        flex-direction: column;

        .ant-row {
            height: 100%;
            background-color: white;
            border: solid 1px $color-gray-blue;
            padding: 10px;
            border-radius: 20px;
            box-shadow: 0px 17px 13px 0px #192c4b05;
            margin: 0 !important;

            .ant-col {
                padding-left: 5px !important;
                padding-right: 5px !important;
            }
        }

        .home-page-category__header {
            > span {
                font-size: 32px;
                @include up-to-size('sm') {
                    font-size: 28px;
                }
            }
        }
    }

    .hot-rank {
        position: absolute;
        top: calc(50% - 11px);
        left: -11px;
        z-index: 2;
        font-size: 12px;
        color: #fff;
        width: 22px;
        height: 22px;
        background-color: $primary_color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid #fff;
    }
}

#light-theme .box-hot-sell-buy {
    background-color: $primary_background_color;
}

#dark-theme .box-hot-sell-buy {
    background-color: $secondary_background_color_dark;

    .top-artists {
        .ant-row {
            background-color: $primary_background_color_dark;
            border: none;
        }

        .home-page-category__header > span {
            color: $white-text;
        }
    }
}

.top-artworks,
.home-explore,
.trending-collections,
.categories,
.top-collection,
.home-collections,
.live-auctions {
    margin-bottom: 60px;
    color: $primary_color;
    height: fit-content;

    .home-page-category__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        h2 {
            line-height: 38px;
            font-size: 24px;

            @include up-to-size('sm') {
                font-size: 28px;
                margin-bottom: 15px;
            }
        }

        .link-view-all {
            color: $main-color;
            @include text-normal-bold;
        }
    }
    .explore-trending-collection {
        h2 {
            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 20px;
            }
        }
    }
    .ant-card {
        .card-item-custom-content {
            .card-item-custom-content_title__title {
                font-size: 20px;
            }
        }
    }

    &.home-explore {
        .card-item-custom-content_footer {
            border-top: 1px solid rgba(255, 255, 255, 0.15);
            padding-top: 10px;

            .card-item-custom-content_footer__price {
                color: $primary_color;
                font-family: Helvetica;
            }
        }

        .card-item-custom-content_header {
            margin-bottom: 10px;
        }

        .card-item-custom-content {
            padding-top: 0;
        }

        .card-item-custom-content_title {
            margin-bottom: 10px;
        }
    }
}

.home-explore, .categories {
    .hot-collections {
        margin-bottom: 28px;
    }

    .ant-btn-custom {
        margin: auto;
        width: 240px;
        border-radius: 16px;
    }
}

.categories {
    &__list {
        display: flex;
        justify-content: center;
        gap: 24px;
        flex-wrap: wrap;
        margin-bottom: 40px;

        &__item {
            padding: 20px;
            background-color: $white;
            border-radius: 28px;
            display: flex;
            align-items: center;
            gap: 16px;
            border: 1px solid $gray-color;

            &:hover {
                border: 1px solid $main-color;
                cursor: pointer;
            }

            img {
                width: 80px;
                height: 80px;
                border-radius: 16px;
            }

            span {
                @include text-large-medium;
                font-weight: 600;
                line-height: 28px;
                color: $gray-color-3;
            }
        }
    }
}

.top-collection {
    .home-page-category__header {
        padding: 0;

        .ant-select-selector {
            height: 32px !important;
        }
    }

    &-content {
        padding: 0 !important;
    }
}

.hot-auctions {
    padding: 0 10px !important;

    .ant-card-body {
        padding: 10px;
    }

    .featured-custom-btn {
        width: 106px;
    }
}

.box-top-feature {
    background-color: transparent !important;
    padding: 0 !important;
}
.dark-box-collection {
    background-color: #222121;
}
// .box-collection,
// .trending-collections,
// .categories,
// .top-collection,
// #explore,
// .box-hot-sell-buy,
// .top-artworks,
// .community-box,
// .start-collection-box {
//     padding-top: 30px !important;
// }

@media screen and (max-width: $breakpoint-mobile) {
    .home-page {
        h2 {
            margin: 0 !important;
            font-size: 20px !important;
            line-height: 34px !important;
        }
        .title {
            font-size: 20px !important;
            line-height: 34px !important;
        }
    }
    .home-explore {
        .home-page-category__header {
            .sort-filter {
                margin: 0;
                padding-left: 1px;
            }
        }
    }
    .box-collection {
        padding: 50px 0 25px 0 !important;
    }
    .top-collection {
        padding: 20px 0 !important;
        .home-page-category__header {
            flex-wrap: nowrap !important;
            column-gap: 10px;
        }
    }
    .trending-collections, .categories {
        padding: 20px 0 !important;
        .home-page-category__header {
            h2 {
                margin: 0;
            }
        }

        .button-holder {
            display: flex;
            justify-content: space-between;
            column-gap: 20px;
            align-items: center;
        }
    }
    .box-hot-sell-buy {
        padding: 0 !important;
    }
    .community-box {
        padding: 0 0 25px 0 !important;
    }
}

@media screen and (max-width: 345px) {
    .top-collection .home-page-category__header {
        flex-wrap: wrap !important;
    }
}

@media screen and (min-width: $breakpoint-xxl) {
    .home-page {
        .hot-person-container {
            justify-content: space-evenly;
        }

        .container {
            padding: 0 12px;
            max-width: 1440px;
        }
    }
}

@media screen and (max-width: $breakpoint-desktop) {
    .home-page {
        .hot-person-container {
            width: 100%;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .home-page {
        .hot-person-container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .box-hot-sell-buy {
        padding: 50px 0 25px 0;

        .top-artists {
            width: 100%;

            + .top-artists {
                margin-top: 30px;
            }

            min-height: 300px;

            .ant-row {
                padding: 10px 0;
                flex: 1;
            }
        }

        .hot-buy-sell-content {
            flex-direction: column;
        }
    }
}

.center-loading {
    margin: auto;
}
