@import '../../styles/variables.scss';

.header {
    height: 280px;
    position: relative;
    display: flex;
    justify-content: center;
    &:hover {
        .editButton {
            button {
                bottom: -40px;
                right: 0;
            }
        }
    }
}

.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.avatarWrapper {
    position: absolute;
    margin: auto;
    bottom: -40px;
    .avatar {
        border: 4px solid white;
    }
}

.verify {
    position: absolute;
    margin: auto;
    bottom: 7px;
    right: 17px;
    width: 26px;
    height: 26px;
}

.editButton {
    position: absolute;
    right: 0px;
    top: 190px;
    width: 154px;
    height: 90px;
    overflow: hidden;
    button {
        position: absolute;
        transition: all 0.25s ease-in-out;
        bottom: -100px;
    }
}

.darkContent {
    background-color: $primary_background_color_dark;
    .name {
        color: $white-text;
    }
    .addressHolder {
        .tag {
            color: $white-text;
        }
    }
    .desc {
        color: $white-text;
    }
    .expandBtn {
        color: $white-text;
    }
}

.content {
    padding-bottom: 30px;
    padding-top: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @supports (gap: 15px) {
        gap: 15px;
    }

    @supports not (gap: 15px) {
        & > div:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

@media screen and (max-width: $breakpoint-xl) {
    .header {
        .editButton {
            top: 24px;
            right: 16px;
            height: 50px;
            button {
                position: static;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .addressHolder {
        grid-template-columns: repeat(2, max-content);
    }
    .tag {
        margin: 0;
    }
}

.name {
    color: $primary_color;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressHolder {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    margin: auto;
}

.tag {
    color: $primary_color;
    font-weight: bold;
    margin-right: 10px;
    display: flex;
    align-items: center;
    .editShortUrlIcon {
        margin-left: 10px;
        display: none;
    }
    &:hover .editShortUrlIcon {
        display: block;
    }
}

.address {
    display: inline-block !important;
    button {
        padding: 0 !important;
    }
}

.desc {
    color: $secondary_color;
    max-width: 676px;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
    position: relative;
}

.expandBtn {
    color: $gray-color-3;
    font-weight: 600;
    margin-left: 4px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.shareBtn {
    display: inline-block;
    cursor: pointer;
    margin-top: 32px;
    transition: all 0.2s;
    &:hover {
        opacity: 0.8;
    }
}

.itemList {
    margin-top: 24px !important;
    margin-bottom: 24px;
}

.cardItem {
    width: 100%;
    text-align: start;
}

.collectionProperties {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    border: 1px solid rgba(163, 174, 208, 0.5);
    border-radius: 8px;
    background-color: $primary_background_color;
    align-items: center;
    justify-content: space-around;
}

.propertyContent {
    padding: 10px;
    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 8px 0px rgba(4, 17, 29, 0.25);
    }
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: 1px solid rgba(163, 174, 208, 0.5);
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
        border-left: 1px solid rgba(163, 174, 208, 0.5);
    }
    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right: none;
        border-left: 1px solid rgba(163, 174, 208, 0.5);
    }
}

.propertyAmount {
    font-size: 20px;
    font-weight: bold;
    color: $primary_color;
    white-space: nowrap;
    overflow: hidden;
}

.propertyName {
    color: $primary_color;
    margin-bottom: 8px;
    font-size: 15px;
}

.bnbIcon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
}

.wrapNumber {
    width: 100%;
    align-items: center;
    justify-content: center;
}

.filter {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
    column-gap: 24px;
    row-gap: 24px;
    .right {
        margin-right: 16px;
        min-width: 203px;
    }
    .left {

        min-width: 203px;
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                :global(.ant-select-selection-item) {
                    margin-left: 80px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .select {
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                background-color: white;
                height: 34px !important;
                border: solid 1px rgba(10, 33, 65, 0.05);

                :global(.ant-select-selection-item) {
                    color: $primary_color;
                    font-weight: 400;
                }

                :global(.ant-select-selection-placeholder) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    color: $primary_color;
                }
            }

            :global(.ant-select-arrow) {
                color: $primary_color;
            }
        }
    }

    .select_filter {
        min-width: 180px;
    }

    .iconFilter {
        margin-right: 8px;
    }
}

.leftFilter {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: flex-start;
    position: relative;
    .searchBox {
        position: relative;
        display: flex;
        align-items: center;
    }
    .search {
        height: 34px !important;
        padding-left: 30px;
    }
    .searchIcon {
        position: absolute;
        left: 11px;
        cursor: pointer;
        height: 14px;
        width: auto;
    }
}

.rightFilter {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: flex-start;
}

.btnFilter {
    height: 34px !important;
}

@media screen and (min-width: 836px) {
    .leftFilter {
        .search {
            min-width: 300px;
        }
    }
}

@media screen and (max-width: 732px) {
    .leftFilter {
        .search {
            min-width: 300px;
        }
    }
}

@media screen and (max-width: 520px) {
    .leftFilter {
        .search {
            min-width: unset;
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .propertyAmount {
        font-size: 20px;
        text-overflow: clip;
        overflow: visible;
    }

    .propertyName {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: $breakpoint-m-mobile) {
    .propertyAmount {
        font-size: 18px;
        overflow: inherit;
    }
}

@media screen and (max-width: 350px) {
    .leftFilter {
        flex-direction: column;
        width: 100%;
        row-gap: 15px;
        .search {
            width: 100%;
        }
        .searchIcon {
            top: 10px;
        }
        & > div {
            width: 100%;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .propertyContent {
        &:first-child {
            border-bottom-left-radius: 0;
            border-bottom: 1px solid rgba(163, 174, 208, 0.5);
        }
        &:nth-child(2) {
            border-right: none;
            border-bottom-right-radius: 0;
            border-top-right-radius: 8px;
            border-bottom: 1px solid rgba(163, 174, 208, 0.5);
        }
        &:nth-child(3),
        &:nth-child(5) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 8px;
            border-right: 1px solid rgba(163, 174, 208, 0.5);
            border-left: none;
        }
        &:nth-child(4) {
            border-left: none;
        }
        &:nth-child(5) {
            border-top: 1px solid rgba(163, 174, 208, 0.5);
        }
        &:last-child {
            border-top-right-radius: 0;
            border-left: none;
            border-top: 1px solid rgba(163, 174, 208, 0.5);
        }
    }
    .collectionProperties {
        grid-template-columns: repeat(2, 1fr);
    }
    .content {
        // padding: 28px 21px 100px 21px;
        padding-left: 21px;
        padding-right: 21px;
    }
}
