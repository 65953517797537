@import 'styles/variables';

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $font-primary;
    height: 600px;
    padding-left: 25px;
    .not-found-title {
        color: $gray-color-3;
        font-size: 2rem;
        font-weight: bold;
    }
    .not-found-description {
        font-size: 1rem;
        color: $secondary_color;
        margin-top: 10px;
        margin-bottom: 1.5rem;
    }
    button {
        color: $white;
        width: 120px !important;
        &:hover {
            color: $white;
        }
    }
}
