@import 'styles/variables.scss';

.mobile-activity-filter {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    z-index: 9;
    border-top: 1px solid $color-gray-blue;
}

.filter-drawer {
    .ant-drawer-content-wrapper {
        height: fit-content !important;
        padding-bottom: 10px;
        background: #fff;

        .ant-drawer-content {
            border-radius: 8px 8px 0px 0px;
            .ant-drawer-wrapper-body {
                padding: 32px 24px 0 24px;
                .ant-drawer-header {
                    border-bottom: none;
                    padding: 0;
                    .ant-drawer-title {
                        font-size: 24px;
                        color: $primary_color;
                        font-weight: 700;
                    }
                }
                .ant-drawer-body {
                    padding: 8px 0 24px 0;
                    display: flex;
                    flex-direction: column;
                    .filter-container {
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                        button {
                            margin-top: 17px;
                        }
                    }
                    .show-button {
                        width: 100%;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    .ant-btn-color-blue {
        img {
            filter: brightness(0) invert(1);
        }
    }
}


.dark-mobile-activity-filter {
    background: $primary_background_color_dark;
    border: none;
}
