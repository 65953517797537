@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.countdown-container {
    display: flex;
    color: $third_color;
    line-height: 25px;
    @include up-to-size('md') {
        justify-content: space-between;
        font-size: 20px;
    }

    .flip-countdown-card-sec,
    .colon-custom {
        font-weight: 500;
        font-size: 22px !important;

        @media (max-width: 600px) {
            font-size: 16px !important;
        }
    }
    &-small {
        .flip-countdown-card-sec,
        .colon-custom {
            font-weight: 500;
            font-size: 18px !important;
        }
    }
    &-extra-small {
        .flip-countdown-card-sec,
        .colon-custom {
            font-weight: 500;
            font-size: 16px !important;
        }
    }
}
