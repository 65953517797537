@import '../../../styles/variables.scss';
@import 'styles/mixins.scss';

.default-layout {
  display: flex;
  flex-direction: column;
  align-content: center;

  max-width: 100vw;
  .layout-body {
    padding-top: 88px;
  }
}

#light-theme {
  background: white;
  color: white;
}

#dark-theme {
  background: $secondary_background_color_dark;
  color: white;
}

.primary-button {
  width: 100%;
  height: 40px !important;
  border: none !important;
  background: #0a1ed7 !important;
  color: white !important;
}

.normal-color {
  color: #c4bbef !important;
}

.ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
  background: rgba(255, 255, 255, 0.08) !important;
  border: none !important;
  color: white !important;
  height: 50px;
}

.ant-modal-title,
.ant-modal-close-x {
  color: black !important;
  font-weight: bold !important;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-content {
  background: #fff !important;
  border-radius: 20px !important;
}

.custom-link {
  text-decoration: underline;
  color: #39b5a8;
}

.custom-link:hover {
  text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mt-10 {
  margin-top: 10px;
}

.custom-message-content {
  border-radius: 8px;
  font-size: 16px;
  font-family: $font-primary;
  padding: 17px 48px;
  color: $primary_color;

  & .ant-message-custom-content {
    display: flex;
    align-items: center;
  }
}

.custom-message {
  &--success {
    .ant-message-notice-content {
      border: 1px solid $green;
      @extend .custom-message-content;
    }
  }
  &--error {
    .ant-message-notice-content {
      border: 1px solid $red;
      @extend .custom-message-content;
    }
  }
  &--info {
    .ant-message-notice-content {
      @extend .custom-message-content;
    }
  }
  @media screen and (max-width: 576px) {
    &--success,
    &--error,
    &--info {
      .ant-message-notice-content {
        padding: 8px 24px !important;
        .ant-message-custom-content {
          font-size: 14px !important;
        }
      }
    }
  }
}

.custom-message-icon {
  margin-right: 20px;
  height: 24px;
  width: 24px;
  @media screen and (max-width: 576px) {
    margin-right: 12px;
    height: 20px;
    width: 20px;
  }
}

.default-container {
  width: 1280px;
  max-width: 100%;
  margin: auto;
  @include up-to-size('sm') {
    margin: 0 !important;
  }
}

.ant-modal.custom-loading-modal {
  .ant-modal-content {
    background: transparent !important;
    box-shadow: none;
    width: auto;
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .default-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .default-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .default-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .default-container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .default-container {
    max-width: 1280px;
  }
}

.ant-message {
  z-index: 3000 !important;
}

@media screen and (min-width: $breakpoint-mobile) {
  .layout-body {
    display: flex;
    width: 100%;
    gap: 28px;
    align-items: stretch;
    &__content {
      padding-top: 88px;
      padding: 40px 28px;
      width: calc(100% - 168px - 28px);
      padding-block: 40px;
      background-color: $layout_background;
      border: 1px solid $gray-color;
      border-radius: 32px;
    }
    .container {
      padding-inline: 28px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding-inline: 16px !important;
  }
}
