@import 'styles/variables';

.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $font-primary;
    height: fit-content;
    padding-left: 25px;
    gap: 24px;
    .comingSoon-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .comingSoon-title {
        color: $gray-color-3;
        font-size: 2rem;
        font-weight: bold;
        margin: 0;
    }
    .comingSoon-description {
        font-size: 1rem;
        color: $gray-color-2;
    }
    button {
        color: $white;
        width: 120px !important;
        &:hover {
            color: $white;
        }
    }
}