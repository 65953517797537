@import 'styles/variables.scss';

.create-nft-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .label-custom {
        &_title {
            color: $gray-color-3!important;
        }
        &_desc {
            font-size: 14px;
            color: $gray-color-3!important;
        }
        &__subdesc span {
            color: $gray-color-2;
        }
    }
}

.create-nft-header {
    width: 100%;
    &_title {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: $gray-color-3;
    }
}

#dark-theme .create-nft-header {
    &_title {
        color: $btn-blue;
    }
    .create-nft-header_title {
        color: $white-text;
    }
}

.create-nft-container {
    background: $white;
    border: 1px solid $color-gray-blue;
    padding: 40px;
    width: 100%;
    border-radius: 28px;
    max-width: 100%;

    .switch-type-btn {
        display: flex;
        gap: 8px;
        background-color: $gray-color;
        border-radius: 16px;
        color: $gray-color-3;
        font-weight: 500;
        font-size: 14px;
        i {
            font-size: 16px;
            margin-right: 0;
            font-weight: 600;
            color: $gray-color-2;
        }
    }

    .create-nft-header {
        margin-bottom: 40px;
        .create-nft-header_back {
            display: inline-flex;
            cursor: pointer;
            align-items: center;
            margin-bottom: 8px;

            .create-nft-header_back__title {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                color: $third_color;
                margin-left: 17px;
            }
        }
        .create-nft-header_title {
            display: block;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $gray-color-3;
        }
    }

    .create-nft-form_wrapper {
        display: flex;
        justify-content: space-between;
        gap: 32px;

        .create-nft-form {
            flex-grow: 1;
            box-sizing: border-box;
            overflow: hidden;

            .create-nft_text__highlight {
                color: $primary_color;
                font-weight: bold;
            }

            .create-nft-block {
                margin-bottom: 32px;
                .ant-form-item-control {
                    padding: 2px;
                }
            }

            .create-nft-block_desc {
                margin-bottom: 32px;
            }

            .create-nft-marketPlace {
                margin-bottom: 32px;
                .create-nft-marketPlace_header {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 24px;
                    .create-nft-marketPlace_label {
                        display: flex;
                        flex-direction: column;

                        .create-nft-marketPlace_label__title {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            color: $gray-color-3;
                            margin-bottom: 4px;
                        }

                        .create-nft-marketPlace_label__desc {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            color: $gray-color-2;
                        }
                    }
                }
            }

            .create-nft-price {
                display: flex;
                justify-content: space-between;

                .create-nft-price_input {
                    flex: 1;
                    margin-right: 20px;
                    margin-bottom: 0;
                }

                .create-nft-price_currency {
                    width: 120px;
                    margin-bottom: 0;
                }
            }

            .create-nft-minimumBid_label {
                width: 100%;
            }

            .create-nft-minimumBid {
                display: flex;
                justify-content: space-between;

                .create-nft-minimumBid_price_input {
                    flex: 1;
                    margin-right: 20px;
                    margin-bottom: 0;
                    .create-nft-minimumBid_price_input_custom {
                        padding-right: 12px !important;
                    }
                }

                .create-nft-startDate {
                    flex: 1;
                    margin-right: 10px;
                    margin-bottom: 0;
                }

                .create-nft-expireDate {
                    flex: 1;
                    margin-left: 10px;
                    margin-bottom: 0;
                }
            }

            .create-nft-minimumBid_date__desc {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                margin-top: 7px;
                color: $third_color;
                display: block;

                .create-nft-minimumBid_date__highlight {
                    color: $primary_color;
                    text-decoration: underline;
                    font-weight: bold;
                    cursor: pointer;
                }
            }

            .create-nft-properties {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;

                .create-nft-properties_block {
                    margin-bottom: 0;
                    flex: 1;
                    &.mr {
                        margin-right: 10px;
                    }
                    &.ml {
                        margin-left: 10px;
                    }
                }
            }

            .create-nft-minimumBid_label {
                width: 100%;
            }

            .create-nft-minimumBid {
                display: flex;
                justify-content: space-between;

                .create-nft-minimumBid_price_input {
                    flex: 1;
                    margin-right: 20px;
                    margin-bottom: 0;
                }

                .create-nft-startDate {
                    flex: 1;
                    margin-bottom: 0;
                    max-width: 214px;
                    margin-left: 1px;
                }

                .create-nft-expireDate {
                    flex: 1;
                    margin-left: 8px;
                    margin-bottom: 0;
                    max-width: 214px;
                }
                .expireDate_label > * {
                    margin-left: 6px;
                }
            }

            .create-nft-minimumBid_date__desc {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                margin-top: 7px;
                color: $third_color;
                display: block;

                .create-nft-minimumBid_date__highlight {
                    color: $primary_color;
                    text-decoration: underline;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }

        .create-nft-form_sticky {
            position: sticky;
            z-index: 12;
            top: 200px;
        }

        .create-nft-form_preview {
            width: 332px;
            height: 400px;
            padding-inline: 40px;
            border: 1px solid $gray-color;
            box-sizing: border-box;
            border-radius: 16px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;

            .create-nft-form_preview__title {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: $gray-color-2;
                text-align: center;
                padding: 10px;
                width: 100;
            }
        }

        .create-nft-form_preview_isCard {
            border: none;
            align-items: flex-start;
            height: auto;
        }
    }

    .create-nft-unsave {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;

        text-align: center;

        color: $primary_color;

        margin-top: 24px;
        cursor: pointer;

        .create-nft-unsave_icon {
            margin-left: 5px;
        }
    }

    .create-nft-fix-all-error {
        margin-top: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $red;
    }

    .create-nft-unsave {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;

        text-align: center;

        color: $primary_color;

        margin-top: 24px;
        cursor: pointer;

        .create-nft-unsave_icon {
            margin-left: 5px;
        }
    }
}

.ant-select-dropdown {
    &.select__dropdown--chains {
        width: 188px !important;
    }
}

#dark-theme .create-nft-container {
    background: $primary_background_color_dark;

    button.ant-btn-color-gray {
        background: rgb(59, 59, 59) !important;
        color: $white !important;
    }

    .select-custom-wrapper {
        .ant-select {
            .ant-select-selector {
                background-color: rgb(48, 51, 57);

                .ant-select-selection-item {
                    color: $white;
                }
            }

            .ant-select-arrow {
                color: white;
            }
        }
    }

    .create-nft-form_wrapper {
        .create-nft-form_preview {
            background: #4c505c;

            .create-nft-form_preview__title {
                color: #b1adaa;
            }
        }

        .create-nft_text__highlight {
            color: $white-text;
        }

        .create-nft-marketPlace {
            .create-nft-marketPlace_header {
                .create-nft-marketPlace_label__title {
                    color: $white-text;
                }
            }
        }
    }
}

.create-nft-form_cart-item {
    width: 260px !important;
    align-items: flex-start;
    min-height: 278px !important;
    border: 1px solid $input-prefix-background !important;
    .card-item-custom-content {
        padding-bottom: 27px !important;
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .create-nft-header_title {
        padding: 0 15px;
    }
}
