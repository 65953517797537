@import 'styles/variables.scss';

.open-for-bid-container {
    &__card {
        border-radius: 8px;
        background-color: transparent;
        max-width: 100%;

        &--title {
            margin-bottom: 20px;
            font-family: $font-primary;
        }
    }
    .ant-btn-custom {
        max-height: 50px;
        max-width: 220px;
        min-height: 46.4px;
        padding: 5px 25px;
        width: auto !important;
        font-size: $fs-text + 2;
    }
}
