@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.no-data-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    margin-bottom: 24px;
  }
  
  &__main-text {
    color: $gray-color-3;
    @include text-normal-bold;
    margin-bottom: 8px;
  }
  
  &__sub-text {
    color: $gray-color-2;
    @include text-normal-regular;
    margin-bottom: 24px;
  }
}