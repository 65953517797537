@import '../../styles/variables.scss';
@import '../../styles/common.scss';

.dark-following-page {
    .following-content {
        .head-filter {
            .ant-btn-color-dark {
                span {
                    color: black !important;
                }
            }
        }
    }
}

.following-page {
    color: initial;
    min-height: calc(100vh - 460px);
    .following-content {
        color: $primary_color;
        margin-bottom: 3rem;
        .head-filter {
            .tab-btn {
                border: 1px solid black;
            }
        }
        .following-header {
            color: $primary_color;
            width: 100%;
            .title {
                font-family: $font-primary;
                line-height: 54px;
                color: $primary_color;
                @extend %title-page;
            }
        }
        .following-cards {
            margin-top: 3rem;
            width: 80%;
            margin: 0 auto;
            padding: 100px 0;
            @media screen and (max-width: $breakpoint-tablet) {
                padding: 70px 0;
            }
            .ant-card.card-item-custom {
                width: 100%;
            }
        }
    }
    .head-filter {
        padding: 0 20px 20px 20px;
        .ant-btn-small {
            font-size: $fs-text !important;
            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 14px !important;
            }
        }
    }
    .categories-filter {
        border-bottom: 1px solid $color-gray-blue;
        @media screen and (max-width: $breakpoint-tablet) {
            border-bottom: none;
            padding: 0!important;
        }
    }
}

.following-no-item-container {
    text-align: center;
    p:first-of-type {
        margin-top: 22px;
        font-size: 18px;
        font-family: $font-primary;
        margin-bottom: 0;
        color: #002979;
    }
    p:nth-of-type(2) {
        color: $third_color;
        font-size: 14px;
        font-family: $font-secondary;
        margin-top: 8px;
        margin-bottom: 0;
    }
    .btn-browse {
        margin: 0 auto;
        margin-top: 32px;
        width: 217px;
        height: 50px;
        .ant-anchor-wrapper {
            background-color: transparent;
            .ant-anchor-ink {
                display: none;
                position: absolute;
            }
            .ant-anchor-link {
                padding: 0;
                width: 217px;
                height: 50px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white !important;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
