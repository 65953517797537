@import '../../styles/variables.scss';
.follow {
    padding: 90px 80px 100px 80px;
    color: $primary_color;
}

.followLists {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .followListsRow {
        width: calc(100% + 24px);
        margin-bottom: 64px;
        @media screen and (max-width: 576px) {
            margin-bottom: 33px;
        }
    }
}

.followListsBtn {
    margin-top: 50px;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter {
    display: inline-flex;
    align-items: center;
    @media screen and (max-width: 576px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        &:global() {
            :global(.select-custom-wrapper) {
                flex: 1;
                width: 100%;
            }
        }
    }
    .right {
        margin-right: 16px;
        min-width: 203px;
        @media screen and (max-width: 576px) {
            margin-right: 0;
            margin-bottom: 10px;
            min-width: 100%;
        }
    }

    .left {
        min-width: 203px;
        @media screen and (max-width: 576px) {
            min-width: 100%;
        }
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                :global(.ant-select-selection-item) {
                    margin-left: 80px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .select {
        &:global(.ant-select) {
            :global(.ant-select-selector) {
                background-color: transparent;
                // border: 1px solid $primary_color !important;

                :global(.ant-select-selection-item) {
                    color: $primary_color;
                    font-weight: 400;
                }

                :global(.ant-select-selection-placeholder) {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;

                    color: $primary_color;
                }
            }

            :global(.ant-select-arrow) {
                color: $primary_color;
            }
        }
    }

    .iconFilter {
        margin-right: 8px;
    }
}

.followingIcon {
    width: 20px;
    height: 20px;
}
