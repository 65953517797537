@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.bid-modal-container {
    color: $secondary_color;
    font-size: 14px;
    @include up-to-size('sm') {
        font-size: 12px;
    }
    p {
        margin: 0px;
    }
    .title-modal {
        margin: 0 0 20px 0;
        font-size: 24px;
        font-family: $font-primary;
        color: $gray-color-3;
        @include up-to-size('sm') {
            font-size: 24px;
        }
    }
    .error-balance {
        color: red;
        margin-top: 8px;
    }

    .ant-input-affix-wrapper {
        .ant-input-prefix {
            margin-right: 0;
        }
    }
    .ant-input-suffix {
        margin-left: 0;
    }
    .ant-input {
        background-color: $bg_input;
    }

    .previous-bid {
        font-size: $fs-text;
        color: $primary_color;
        font-family: $font-primary;
        margin-bottom: 7px;
        @include up-to-size('sm') {
            font-size: 14px;
        }
    }
    .tip-bid {
        margin-bottom: 15px;
    }
    .balance-amount {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        & > :nth-child(2) {
            font-family: $font-primary;
        }
        @include up-to-size('sm') {
            font-size: 12px;
        }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $primary_color;
        border-color: $primary_color;
    }
    .input-form {
        .ant-input-custom {
            margin-bottom: 20px;
            height: 45px;
            border-radius: 12px !important;
            background-color: $bg_input;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            &:focus,
            &:hover {
                background-color: transparent;
                border: 1px solid $color-gray-blue !important;
            }
        }
        .ant-input {
            background-color: transparent;
        }
    }

    .term-of-service {
        display: flex;
        align-items: center;
        @include up-to-size('sm') {
            align-items: flex-start;
        }
        .term-of-service-text {
            margin-left: 14px;
            & > :first-child {
                font-size: 16px;
                color: $secondary_color;
                @include up-to-size('sm') {
                    font-size: 14px;
                }
            }
            & > :nth-child(2) {
                font-size: 16px;
                color: $primary-color;
                font-family: $font-primary;
                text-decoration: underline;
                margin-left: 8px;
                @include up-to-size('sm') {
                    font-size: 14px;
                }
            }
        }
    }

    .service-fee-row {
        color: $primary_color;
        margin: 15px 0;
        &.border-top {
            padding-top: 20px;
        }
        & > :nth-child(2) {
            text-align: end;
            & > :nth-child(2) {
                color: $secondary_color;
                @include up-to-size('sm') {
                    font-size: 13px;
                }
            }
        }
    }

    .total-fee-row {
        padding-top: 15px;
        margin-bottom: 15px;
        color: $primary_color;
        @include up-to-size('sm') {
            font-size: 14px;
        }
        & > :nth-child(2) {
            text-align: end;
            & > :first-child {
                font-size: 20px;
                font-family: $font-primary;
                @include up-to-size('sm') {
                    font-size: 16px;
                }
            }
            & > :nth-child(2) {
                color: $secondary_color;
                @include up-to-size('sm') {
                    font-size: 13px;
                }
            }
        }
    }

    .bid-modal-actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        & > * {
            width: 100% !important;
        }
    }
}
