@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.ant-tabs.tabs-custom {
    font-weight: 500;
    color: $primary_color;

    .ant-tabs-nav {
        margin: 0;
    }

    .ant-tabs-nav-list {
        padding: 6px 8px !important;
        gap: 8px;
        border-radius: 16px;
        background-color: #F5F5F5;

        .ant-tabs-tab {
            margin: 0;
            height: 36px;
            padding: 2px 24px;
            border: 1px solid transparent;
            background-color: transparent;
            @include text-small-medium;
            color: $gray-color-3;
            box-shadow: none;
            border-radius: 12px;

            &.ant-tabs-tab-active {
                background: $white;
            }
        }
    }

    .ant-btn.btn-following,
    .ant-btn.btn-followers {
        border: none;
        background-color: transparent !important;
        color: $primary_color;
        font-weight: 500;
        box-shadow: none;
        padding: 0;
    }
    .ant-tabs-tab.ant-tabs-tab-disabled {
        cursor: pointer;
    }
    [ant-click-animating-without-extra-node]:after {
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
    .ant-card.card-item-custom {
        width: 100%;
    }
    .ant-tabs-ink-bar {
        background: $primary_color !important;
        height: 4px !important;
    }
}

.tabs-custom.ant-tabs-top,
.tabs-custom.ant-tabs-bottom,
.tabs-custom.ant-tabs-top > div,
.tabs-custom.ant-tabs-bottom > div {
    & > .ant-tabs-nav::before {
        border-bottom: 1px solid $btn_blue !important;
        opacity: 0.1;
    }
}

.tabs-custom.ant-tabs-dropdown-menu {
    &-item {
        color: $primary_color !important;
        font-weight: 500 !important;
    }
    .ant-tabs-dropdown-menu-item-disabled,
    .ant-tabs-dropdown-menu-item-disabled:hover {
        cursor: pointer;
    }
    .btn-followers,
    .btn-following {
        border: none;
        background-color: transparent !important;
        color: $primary_color;
        font-weight: 500;
        box-shadow: none;
        padding: 0;
    }
    [ant-click-animating-without-extra-node]:after {
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}

.ant-tabs {
    overflow: unset !important;
    .ant-tabs-nav {
    }

    .ant-tabs-ink-bar {
        display: none;
    }
    .ant-tabs-nav-list {
        padding-bottom: 20px;
    }
    .ant-tabs-tab {
        background-color: white;
        border: solid 1px rgba(10, 33, 65, 0.05);
        box-shadow: 0px 2px 4px $shadow_color;
        height: 34px;
        padding: 0 10px;
        border-radius: 18px;
        font-weight: 400;
        display: flex;
        align-items: center !important;
        margin: 0;
        .ant-tabs-tab-btn {
            color: $gray-color-3;
        }
        + .ant-tabs-tab {
            margin-left: 10px;
        }
        &.ant-tabs-tab-active {
            background-color: black;
            color: white;
            font-weight: 500;
            // .ant-tabs-tab-btn {
            //     color: $white-text;
            //     font-weight: 500;
            //     box-shadow: none;
            // }
        }
        &:hover {
            color: $btn_blue;
        }
    }
}

#dark-theme .ant-tabs {
    .ant-tabs-tab {
        background-color: black;
    }
    .ant-tabs-tab-btn {
        color: $white-text;
    }
    .ant-tabs-tab-active {
        background-color: white !important;
        .ant-tabs-tab-btn {
            color: black;
        }
    }
}