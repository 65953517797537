.dark-bid {
  .bid-left {
    .bid-info {
      .price-info {
        p {
          color: white !important;
        }
      }
    }
  }
}
