@import 'styles/variables.scss';
@import 'styles/common.scss';

.activity-container {
  display: flex;
  flex-direction: column;
  &_header {
    color: $gray-color-3;
    font-family: $font-primary;
    width: 100%;
    text-align: center;
    background: #fff;
    border-radius: 28px;
    padding-top: 16px;
    .title {
      font-size: 24px;
      color: $gray-color-3;
    }

    .filter-container {
      overflow-x: auto;
      overflow-y: hidden;
      .filter-container-head {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .tab-btn {
          margin: 0 5px;
          border: 1px solid $gray-color;
          color: $gray-color-2;
          font-size: 15px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
          .tab-btn {
            max-width: fit-content;
          }
            
        }
        .btn-filter {
          font-size: 16px;
          justify-content: space-between;
          padding-right: 9px !important;
          height: 34px;
          i {
            font-size: 20px;
          }
        }
      }
      .filter-container-type {
        padding: 15px 0;
        border-top: 1px solid $color-gray-blue;
        .filter-types-container {
          margin-right: 0!important;
          .filter-button-container {
            gap: 12px;
          }
        }
      }
    }
  }
  &_content {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn-load {
      margin-top: 50px;
    }
    .card-activity-custom {
        height: 100%;
        &-content_type {
          flex-wrap: wrap;
        }
    }
    .no-activity-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      color: $primary_color;
      font-family: $font-primary;
      .no-activity-container-img {
        height: 100px;
        width: auto;
        margin: 0 0 10px 15px;
      }
      .content-item-title {
        margin: 10px;
        font-size: 20px;
      }
      .content-item-description {
        font-family: $font-secondary;
        opacity: 0.7;
        margin-bottom: 10px;
      }
      .btn-browse {
        width: auto;
        margin-top: 35px;
      }
    }
  }
}

#dark-theme .activity-container {
  &_header {
    background-color: $primary_background_color_dark;

    .title {
      color: $btn-blue;
    }

    .filter-container-type {
      border-top: 1px solid rgba(163, 174, 208, 0.2);
      border-bottom: 1px solid rgba(163, 174, 208, 0.2);
    }
    .filter-container {
      .filter-container-head {
        .ant-btn-color-dark {
          span {
            color: black !important;
          }
        }
        .tab-btn {
          background-color: black;
          span {
            color: $white-text;
          }
        }
      }
    }
  }
}

.chain-logo {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .activity-container {
    &_content {
      padding: 70px 0;
    }
  }
  .filter-container {
    padding: 20px 0 37px 0;
    border-top: 1px solid $color-gray-blue;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .activity-container {
    &_content {
      padding: 50px 0;
    }
  }

  .activity-container_header {
    .filter-container {
      .min-width-unset {
        min-width: auto;
      }
    }
  }
}
