@import 'styles/variables.scss';

.input-group-social {
    margin-top: 32px;
    margin-right: 32px;
    & > * {
        margin-top: 32px;
    }

    .ant-input {
        background-color: $bg_input;
    }
}

.form-item-social {
    position: relative;
    &:first-child {
        margin-top: 0;
    }
}

.header-title {
    font-weight: 700;
    font-size: 24px;
    display: block;
    color: $gray-color-3;
}

.label {
    font-weight: 500;
    margin-bottom: 8px;
    color: $primary_color;
    font-size: 17px;
}

.update-social_btnGroups {
    display: flex;
    justify-content: center;
    align-items: center;
    .update-social_btn {
        flex: 1;
        &.mr {
            margin-right: 12px;
        }

        &.ml {
            margin-left: 12px;
        }
    }
}

.remove-twitter-icon {
    position: absolute;
    display: block;
    font-size: 25px;
    right: 15px;
    top: 37px;
    cursor: pointer;
}
