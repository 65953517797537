@import 'styles/variables.scss';
@import 'styles/common.scss';
@import 'styles/mixins.scss';

.attribute-tab {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        >span {
            font-size: 20px;
            font-weight: 600;
            color: $gray-color-3;
        }
        border-radius: 28px;
        padding: 32px 28px;
        background: white;
        border: 1px solid $gray-color;
        @extend %border;
        @media screen and (max-width: 576px) {
            padding: 24px;
        }
        &__property-container {
            .gutter-box {
                padding: 16px 0;
            }
            &__property {
                display: flex;
                flex-direction: column;
                padding: 16px;
                border-radius: 16px;
                background: $layout-background;
                gap: 16px;
                .att-name {
                    color: $gray-color-3;
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 500;
                }
                .att-value {
                    color: $gray-color-3;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}