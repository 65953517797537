@import '../../../styles/variables';

.dark-card-item-preview-custom {
    background: #4c505c !important;
}

.ant-card.card-item-preview-custom {
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 32px rgba(52, 87, 210, 0.05);
    position: relative;
    border: none;
    min-height: 365px;

    .ant-card-body {
        padding: 0;
    }

    .card-item-custom-header {
        padding: 16px;
        position: relative;

        &_avatar {
            position: relative;
        }

        .card-item-owner-creator-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .card-item-owner,
        .card-item-creator {
            max-width: calc(50% - 8px);
            .name {
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $secondary_color;
            }
            .ant-avatar {
                min-width: 20px;
            }
        }

        .card-item-custom-header_videoIcon {
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .card-item-custom-header-like {
            background: #fff;
            border-radius: 16px;
            padding: 4px 8px;
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            .heart-icon {
                width: 18px;
                height: 18px;
            }
            &__count {
                color: $primary_color;
                font-size: 14px;
                margin-left: 3px;
            }
        }
    }

    .card-item-custom-users {
        display: flex;
        justify-content: center;
        width: fit-content;

        .card-item-custom-usersGroup {
            position: relative;
            display: inline-flex;
        }
    }

    .card-item-custom-users_avatar_wrapper {
        position: relative;
        transition: all 0.3s ease-out;
        display: flex;
        align-items: center;
        &:hover {
            transform: translateY(-10%);
            z-index: 7;
        }
        .card-item-custom-users_avatar {
            box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.15);
            width: 20px;
            height: 20px;
            border: none;
            position: relative;
            min-width: 20px !important;
            margin-right: 5px;
        }

        .card-item-custom-users_icon__last {
            position: absolute;
            bottom: -3px;
            left: 8px;
            z-index: 10;
            width: 16px;
        }
        .name {
            margin-left: 8px;
            font-weight: 600;
        }
    }

    .card-item-custom-content_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .card-item-custom-avatar {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        & > img {
            object-fit: cover;
            transition: transform 0.5s ease;
            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .card-item-custom-content {
        padding: 16px;
        padding-top: 8px;
    }

    .card-item-custom-content_title {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-item-custom-content_title__title {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #002979;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            text-align: start;
            width: 100%
        }
        .ant-avatar {
            width: 20px;
            height: 20px;
            img {
                object-fit: unset;
                border-radius: 50%;
            }
        }
    }

    .card-item-custom-content_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-item-custom-content_footer__notForSale {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: $third_color;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .card-item-custom-content_footer__make-an-offer {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #47d176;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-item-custom-content_footer__block {
            display: flex;
            justify-content: center;
            align-items: center;
            .heart-icon {
                width: 21px;
                height: 19px;
            }
            &.right {
                max-width: 70%;
            }
        }
        .card-item-custom-content_footer__highest-price {
            display: flex;
            flex-direction: column;
            .card-item-custom-content_footer__highest-bid {
                font-size: 12px;
                font-weight: 400;
                color: $third_color;
            }
        }

        .card-item-custom-content_footer__price {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: $primary_color;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-item-custom-content_footer__price___more {
            margin-top: -40px;
        }

        .card-item-custom-content_footer__like {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: $third_color;
            margin-left: 7px;
        }
    }

    .card-item-custom-content_auction {
        position: absolute;
        bottom: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 10px 5px;
        width: fit-content;
        background-color: $white;
        border: solid 1px $color-gray-blue;

        & > div:first-child {
            align-items: center;
        }
        &__counter-container {
            margin-top: -1px;
        }
        .card-item-custom-content_auction__left {
            font-size: 12px;
            font-weight: 600;
            color: $primary_color;
            margin: 0 4px;
            line-height: 19px;
        }

        .card-item-custom-content_auction__counter-container {
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            color: $red;
            display: flex;
            justify-self: center;
            .card-item-custom-content_auction__counter {
                display: flex;
                align-items: center;
                .card-item-custom-content_auction__time-unit {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 24px;
                    border-radius: 2px;
                }
                .card-item-custom-content_auction__colon {
                    margin: 0 2px;
                }
            }
            div:nth-of-type(3) {
                span {
                    display: none;
                }
            }
        }
    }
}

.tooltip-customer-title__role {
    text-transform: capitalize;
}

.ant-tooltip-inner {
    color: $primary_color !important;
}

.dark-card-item-custom-content {
    .card-item-custom-content_title {
        span {
            color: $white-text !important;
        }
    }
    .card-item-custom-content_footer {
        span {
            color: white !important;
        }
    }
}
