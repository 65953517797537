@import "styles/variables.scss";
@import "styles/mixins.scss";
@import "styles/common.scss";

.update-profile-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: self-start;
  justify-content: center;
  // padding: 60px 16px 100px 16px;
  flex-direction: column;

  @include up-to-size("md") {
    width: 100%;
  }
  .hidden {
    display: none;
  }
  .select-profile-avatar {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    margin-top: 50px;

    .upload-button-holder {
      flex-direction: column;
    }

    .upload-avatar-text {
      margin-bottom: 24px;
      max-width: 300px;
    }

    & > div {
      @include min-size("md") {
        margin-left: 16px;
      }
    }
    & > :nth-child(2) {
      width: max-content;
      @include up-to-size("md") {
        margin-top: 16px;
      }
      & > :first-child {
        color: $third_color;

        .profile-form-inner {
          padding-bottom: 30px;
          margin-bottom: 30px;
          border-bottom: 1px solid $color-gray-blue;
          .ant-col {
            @media screen and (max-width: 767px) {
              width: 100%;
            }
          }
        }

        .ant-input.ant-input-custom,
        .ant-input-affix-wrapper {
          height: 40px;
          border: 1px solid transparent;
          border-radius: 12px;
        }

        .update-profile-form {
          margin-bottom: 100px;
          padding: 40px !important;
          color: $primary_color;
          background-color: $white;
          width: 75%;
          border-radius: 16px;
          display: block;
          white-space: pre-wrap;
          box-sizing: border-box;
          border: 1px solid $color-gray-blue;
          .header-title {
            font-size: 26px;
          }
          .header-span {
            font-size: 16px;
            color: $third_color;
          }
          @include up-to-size("md") {
            padding: 20px 24px !important;
          }

          .input-group {
            margin-top: 32px;
            margin-right: 32px;
            & > * {
              margin-top: 32px;
            }
            .twt-link {
              color: $green !important;
              text-decoration: underline;
              font-weight: 700;
              color: $primary_color;
              margin: 0xp;
              cursor: pointer;
            }
            .twt-action {
              color: $primary_color;
              font-weight: 700;
              & > * {
                cursor: pointer;
              }
              & > :first-child {
                margin-right: 16px;
              }
            }
            .sign-message {
              font-weight: 700;
              color: $primary_color;
              cursor: pointer;
              text-decoration: underline;
            }
            .confirm {
              font-weight: 700;
              color: $primary_color;
              cursor: pointer;
            }
            .btn-get-verifi {
              color: $btn_blue;
              background-color: $bg_input;
              font-weight: bold;
              border-radius: 22px;
              border: 1px solid $bg_input;
            }
            .get-verifi-description {
              color: $secondary_color;
              font-weight: 500;
              margin: 10px 0;
            }
            .twitter-verified {
              color: $green;
              font-size: 16px;
              font-weight: 600;
            }
            .btn-link-default span {
              color: $primary_color !important;
              font-size: 16px;
            }
            .btn-verifi-twitter {
              font-weight: 600;
              font-size: 16px;
              padding: 0 0 0 15px;
              margin-top: -2px;
            }
            .btn-verifi-twitter-dark {
              background: none !important;
              color: $white !important;
            }
          }
          .form-list-social {
            max-height: 372px;
            overflow: hidden;
            position: relative;
            &.scroll {
              overflow: auto;
              .btn-show-more {
                position: relative;
                top: 0;
                margin-top: 15px;
              }
            }
            &::-webkit-scrollbar {
              width: 6px;
              background-color: $primary_background_color;
            }
            &::-webkit-scrollbar-thumb {
              height: 8px;
              background-color: $btn_blue;
              border-radius: 5px;
              width: 4px;
              margin: auto;
            }
            &::-webkit-resizer {
              width: 4px;
            }
            scrollbar-width: 6px;
            scrollbar-color: $btn_blue;
          }
          .btn-show-more {
            position: absolute;
            top: 336px;
            right: 0;
            height: 34px !important;
            width: fit-content;
          }
          .form-item-social {
            margin-top: 32px;
            position: relative;
            &:first-child {
              margin-top: 0;
            }

            .remove-twitter-icon {
              position: absolute;
              display: block;
              font-size: 25px;
              right: 15px;
              top: 41px;
              cursor: pointer;
            }
          }
          .verification-group {
            margin: 42px 0px 40px 0px;
            & > :nth-child(2) {
              margin-top: -4px;
              margin-bottom: 24px;
            }
            span {
              display: block;
            }
          }
          .ant-form-item {
            margin-bottom: 0px;
          }
          .title {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 16px;
            display: block;
            color: $gray-color-3;
          }

          .sub {
            color: $third_color;
            margin-top: 12px;
          }

          .label {
            font-weight: 500;
            margin-bottom: 8px;
            color: $primary_color;
            font-size: 17px;
          }

          .description {
            color: $third_color;
            font-size: 16px;
          }

          .prefix {
            color: $primary_color;
            font-size: 16px;
          }
          .err-underline {
            margin-top: 12px;
            color: $red;
            font-size: 12px;
          }
          .confirm {
            color: $primary_color;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      @include up-to-size("xs") {
        flex-direction: column;
        align-items: flex-start;
      }
      @include up-to-size("sm") {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .box-button-action-avata {
      display: flex;
      button {
        margin-right: 10px;
      }
    }

    .input-text-area {
      min-height: 110px;
    }

    .profile-form-inner {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $color-gray-blue;
      .ant-col {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .ant-input.ant-input-custom,
    .ant-input-affix-wrapper {
      height: 55px;
      border: 1px solid transparent;
      background-color: $bg_input;
      border-radius: 12px;
      padding: 15px;
    }

    .update-profile-form {
      margin-bottom: 100px;
      padding: 40px !important;
      color: $primary_color;
      background-color: $white;
      width: 75%;
      border-radius: 16px;
      display: block;
      white-space: pre-wrap;
      box-sizing: border-box;
      border: 1px solid $color-gray-blue;
      .header-title {
        font-size: 26px;
      }
      .header-span {
        font-size: 16px;
        color: $third_color;
      }
      @include up-to-size("md") {
        padding: 20px 24px !important;
      }

      .input-group {
        margin-top: 32px;
        margin-right: 32px;
        & > * {
          margin-top: 32px;
        }
        .twt-link {
          color: $green !important;
          text-decoration: underline;
          font-weight: 700;
          color: $primary_color;
          margin: 0xp;
          cursor: pointer;
        }
        .twt-action {
          color: $primary_color;
          font-weight: 700;
          & > * {
            cursor: pointer;
          }
          & > :first-child {
            margin-right: 16px;
          }
        }
        .sign-message {
          font-weight: 700;
          color: $primary_color;
          cursor: pointer;
          text-decoration: underline;
        }
        .confirm {
          font-weight: 700;
          color: $primary_color;
          cursor: pointer;
        }
        .ant-input {
          background-color: $bg_input;
        }
        .btn-get-verifi {
          color: $btn_blue;
          background-color: $bg_input;
          font-weight: bold;
          border-radius: 22px;
          border: 1px solid $bg_input;
        }
        .get-verifi-description {
          color: $secondary_color;
          font-weight: 500;
          margin: 10px 0;
        }
        .twitter-verified {
          color: $green;
          font-size: 16px;
          font-weight: 600;
        }
        .btn-link-default span {
          color: $primary_color !important;
          font-size: 16px;
        }
        .btn-verifi-twitter {
          font-weight: 600;
          font-size: 16px;
          padding: 0 0 0 15px;
          margin-top: -2px;
        }
        .btn-verifi-twitter-dark {
          background: none !important;
          color: $white !important;
        }
      }
      .form-list-social {
        max-height: 372px;
        overflow: hidden;
        position: relative;
        &.scroll {
          overflow: auto;
          .btn-show-more {
            position: relative;
            top: 0;
            margin-top: 15px;
          }
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: $primary_background_color;
        }
        &::-webkit-scrollbar-thumb {
          height: 8px;
          background-color: $btn_blue;
          border-radius: 5px;
          width: 4px;
          margin: auto;
        }
        &::-webkit-resizer {
          width: 4px;
        }
        scrollbar-width: 6px;
        scrollbar-color: $btn_blue;
      }
      .btn-show-more {
        position: absolute;
        top: 336px;
        right: 0;
        height: 34px !important;
        width: fit-content;
      }
      .form-item-social {
        margin-top: 32px;
        position: relative;
        &:first-child {
          margin-top: 0;
        }

        .remove-twitter-icon {
          position: absolute;
          display: block;
          font-size: 25px;
          right: 15px;
          top: 41px;
          cursor: pointer;
        }
      }
      .verification-group {
        margin: 42px 0px 40px 0px;
        & > :nth-child(2) {
          margin-top: -4px;
          margin-bottom: 24px;
        }
        span {
          display: block;
        }
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
      .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 16px;
        display: block;
        color: $gray-color-3;
      }

      .sub {
        color: $third_color;
        margin-top: 12px;
      }

      .label {
        font-weight: 500;
        margin-bottom: 8px;
        color: $primary_color;
        font-size: 17px;
      }

      .description {
        color: $third_color;
        font-size: 16px;
      }

      .prefix {
        color: $primary_color;
        font-size: 16px;
      }
      .err-underline {
        margin-top: 12px;
        color: $red;
        font-size: 12px;
      }
      .confirm {
        color: $primary_color;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  #dark-theme .update-profile-container {
    p,
    span,
    h3 {
      color: $secondary_background_color;
    }
    .update-profile-form {
      background-color: rgb(48, 51, 57);
      border: none;

      .header-title {
        color: $btn-blue;
      }

      .label {
        color: $btn-blue;
      }

      .prefix {
        color: $primary_background_color;
      }
      .sub {
        color: $third_color;
      }
      .input-group {
        margin-top: 32px;
        margin-right: 32px;
        & > * {
          margin-top: 32px;
        }
        .ant-form-item-control-input {
          input,
          span,
          textarea {
            color: #ffffff87;
          }
        }
        .twt-link {
          color: $green;
        }
        .confirm {
          font-weight: 700;
          color: $primary_color;
          cursor: pointer;
        }
        .ant-input {
          background-color: $bg_input_dark;
        }
        .btn-get-verifi {
          color: $btn_blue;
          background-color: $bg_input_dark;
          border: 1px solid $bg_input_dark;
        }
        .twitter-verified {
          color: $green;
        }
        .btn-link-default span {
          color: $green !important;
        }
      }
      .ant-btn-custom {
        min-width: fit-content;
      }
    }

    .ant-input.ant-input-custom,
    .ant-input-affix-wrapper {
      background-color: $bg_input_dark;
      color: #fff;

      .ant-input {
        background-color: #4c505c;
        color: #fff;
      }
    }
    .ant-input-affix-wrapper:hover {
      border-color: $primary_background_color !important;
    }
    .ant-btn-color-white span {
      color: $primary-color;
    }
  }

  .unverified-twitter-link {
    color: $secondary_color;
  }

  .modal-custom {
    display: flex;
    justify-content: center;
    .ant-modal-content {
      background: $white !important;
      width: 364px !important;
      color: $primary_color;
      font-size: 16px;
      font-weight: 700;
      border-radius: 8px !important;
      border: 1px solid $primary_color !important;
      .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0px !important;
        p {
          margin: 0px;
          margin-left: 18px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-desktop) {
    .update-profile-container {
      .update-profile-form {
        width: 100%;
      }
    }
  }

  /*
Extend styles, not modify




























*/
  .text-edit-title {
    color: $gray-color-3;
    line-height: 38px;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .container-profile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding: 28px;
    background-color: $white;
    border-radius: 28px;
  }

  .container-edit-profile-banner {
    position: relative;
    height: 260px;
    width: 100%;
    overflow: hidden;
    .cover-picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 28px;
    }
    .container-replace-banner {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .container-text-banner {
        margin-bottom: 24px;
        text-align: center;
        :nth-child(1) {
          font-size: 16px;
          margin-bottom: 8px;
        }
        :nth-child(2) {
          font-size: 14px;
          font-weight: 700;
        }
      }
      img {
        margin-right: 11px;
      }
      span {
        font-size: 14px;
        font-weight: 600;
        font-family: $font-primary;
      }
    }
    // &:hover {
    //   .container-replace-banner {
    //     bottom: 5px;
    //     right: 0px;
    //   }
    // }
  }

  .container-select-profile-avatar {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 32px;
    padding: 0 28px;
    flex-direction: row;
    @include up-to-size("sm") {
      flex-direction: column;
    }
    .container-modify-avatar {
      margin-left: 24px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @include up-to-size("sm") {
        margin-top: 8px;
      }
      .address-text {
        line-height: 38px;
        margin-bottom: 16px;
        h5 {
          font-size: 24px;
          font-weight: bold;
          color: $gray-color-3;
        }
        @include up-to-size("sm") {
          text-align: center;
        }
      }
      .container-button-action-avata {
        display: flex;
        button {
          margin-right: 10px;
        }
        span {
          color: $gray-color-2;
          font-weight: 500;
          font-size: 14px;
          padding-left: 8px;
        }
      }
    }
    // .upload-button-holder {
    //     flex-direction: column;
    // }

    // .upload-avatar-text {
    //     margin-bottom: 24px;
    //     max-width: 300px;
    // }

    // & > div {
    //     @include min-size('md') {
    //         margin-left: 16px;
    //     }
    // }
    // & > :nth-child(2) {
    //     width: max-content;
    //     @include up-to-size('md') {
    //         margin-top: 16px;
    //     }
    //     & > :first-child {
    //         color: $third_color;

    //         word-break: keep-all;
    //         @include up-to-size('md') {
    //             margin-bottom: 16px;
    //             font-size: 12px;
    //         }
    //     }
    // }
    // @include up-to-size('xs') {
    //     flex-direction: column;
    //     align-items: flex-start;
    // }
    // @include up-to-size('sm') {
    //     flex-direction: column;
    //     align-items: flex-start;
    // }
  }

  .container-basic-infomation {
    width: 100%;
    padding: 0 28px;
    @include up-to-size("sm") {
      padding: 0;
    }
    .update-basic-profile-form {
      color: $primary_color;
      width: 100%;
      display: block;
      white-space: pre-wrap;
      box-sizing: border-box;
      // border: 1px solid $color-gray-blue;
      .profile-form-row-antd {
        // padding-bottom: 30px;
        // margin-bottom: 30px;
        // border-bottom: 1px solid $color-gray-blue;
        > :first-child {
          @include min-size("md") {
            padding-right: 20px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        > :nth-child(2) {
          @include min-size("md") {
            padding-left: 20px;
          }
        }
        .header-title {
          font-size: 16px;
          color: $gray-color-3;
          font-weight: 700;
          line-height: 24px;
        }
        // @include up-to-size('md') {
        //     padding: 20px 24px !important;
        // }

        .input-group {
          margin-top: 24px;
          & > * {
            margin-bottom: 32px;
          }
          .twt-link {
            color: $green !important;
            text-decoration: underline;
            font-weight: 700;
            color: $primary_color;
            margin: 0xp;
            cursor: pointer;
          }
          .twt-action {
            color: $primary_color;
            font-weight: 700;
            & > * {
              cursor: pointer;
            }
            & > :first-child {
              margin-right: 16px;
            }
          }
          .sign-message {
            font-weight: 700;
            color: $primary_color;
            cursor: pointer;
            text-decoration: underline;
          }
          .confirm {
            font-weight: 700;
            color: $primary_color;
            cursor: pointer;
          }
          // .ant-input {
          //     background-color: $bg_input;
          // }
          .ant-input.ant-input-custom,
          .ant-input-affix-wrapper {
            height: 40px;
            border: 1px solid transparent;
            border-radius: 12px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $gray-color-2;
          }
          .btn-get-verifi {
            // color: $btn_blue;
            // background-color: $bg_input;
            // font-weight: bold;
            // border-radius: 22px;
            // border: 1px solid $bg_input;
            height: 40px;
            width: auto;
            border-radius: 40px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
          }
          .get-verifi-description {
            color: $secondary_color;
            font-weight: 500;
            margin: 10px 0;
          }
          .twitter-verified {
            color: $green;
            font-size: 16px;
            font-weight: 600;
          }
          .btn-link-default span {
            color: $gray-color-3;
            font-size: 16px;
          }
          .btn-verifi-twitter {
            font-weight: 500;
            font-size: 16px;
            padding: 0 0 0 15px;
            margin-top: -2px;
            color: #3b82f6;
          }
          .btn-verifi-twitter-dark {
            background: none !important;
            color: $white !important;
          }
        }
        // .form-list-social {
        //     max-height: 372px; //372px
        //     overflow: hidden;
        //     position: relative;
        //     &.scroll {
        //         overflow: auto;
        //         .btn-show-more {
        //             position: relative;
        //             top: 0;
        //             margin-top: 15px;
        //         }
        //     }
        //     &::-webkit-scrollbar {
        //         width: 6px;
        //         background-color: $primary_background_color;
        //     }
        //     &::-webkit-scrollbar-thumb {
        //         height: 8px;
        //         background-color: $btn_blue;
        //         border-radius: 5px;
        //         width: 4px;
        //         margin: auto;
        //     }
        //     &::-webkit-resizer {
        //         width: 4px;
        //     }
        //     scrollbar-width: 6px;
        //     scrollbar-color: $btn_blue;
        // }
        // .btn-show-more {
        //     position: absolute;
        //     top: 336px;
        //     left: 0;
        //     height: 34px !important;
        //     width: fit-content;
        // }
        .form-list-social {
          max-height: 278px; //372px
          overflow: hidden;
          position: relative;
          &.scroll {
            overflow: auto;
            // .btn-show-more {
            //     position: relative;
            //     top: 0;
            //     margin-top: 15px;
            // }
          }
          &::-webkit-scrollbar {
            width: 6px;
            background-color: $primary_background_color;
          }
          &::-webkit-scrollbar-thumb {
            height: 8px;
            background-color: $btn_blue;
            border-radius: 5px;
            width: 4px;
            margin: auto;
          }
          &::-webkit-resizer {
            width: 4px;
          }
          scrollbar-width: 6px;
          scrollbar-color: $btn_blue;
        }
        .btn-show-more {
          // position: absolute;
          // top: 336px;
          // left: 0;
          height: 40px;
          width: 118px;
          border-radius: 40px;
          font-size: 14px;
        }
        .form-item-social {
          margin-top: 32px;
          position: relative;
          &:first-child {
            margin-top: 0;
          }

          .remove-twitter-icon {
            position: absolute;
            display: block;
            font-size: 25px;
            right: 15px;
            top: 41px;
            cursor: pointer;
          }
        }
        .verification-group {
          margin: 42px 0px 40px 0px;
          & > :nth-child(2) {
            margin-top: -4px;
            margin-bottom: 24px;
          }
          span {
            display: block;
          }
        }
        .ant-form-item {
          margin-bottom: 0px;
        }
        .title {
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 16px;
          display: block;
          color: $gray-color-3;
        }

        .description {
          color: $third_color;
          font-size: 16px;
        }

        .prefix {
          color: $gray-color-2;
          font-size: 16px;
        }
        .err-underline {
          margin-top: 12px;
          color: $red;
          font-size: 12px;
        }
        .confirm {
          color: $primary_color;
          font-size: 16px;
          font-weight: 700;
        }
      }

      .sub {
        color: #a3a3a3;
        margin: 12px 0 8px 0;
        font-weight: 500;
        font-size: 16px;
      }

      .label {
        margin-bottom: 6px;
        color: $gray-color-3;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
