.wallet-address {
    padding: 0 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.dark-wallet-address {
    color: rgba(255, 255, 255, 0.87);
}

.custom-tooltip {
    pointer-events: none;
}
