@import 'styles/variables.scss';
@import 'styles/common.scss';

.description-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 28px;
  padding: 32px 28px;
  background: white;
  border: 1px solid $gray-color;
  > span {
    font-size: 20px;
    font-weight: 600;
    color: $gray-color-3;
  }
  .desc-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      color: $gray-color-3;
      font-weight: 500;
    }
    .group-avatar {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
      margin-bottom: 0;
      .name {
        font-size: 16px;
      }
    }
  }
}
