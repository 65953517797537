@import 'styles/mixins.scss';
@import 'styles/variables';

.create-type-container {
    width: 1280px;
    max-width: 100%;
    margin: auto;
    .create-type-header {
        padding: 100px 0;
        .title {
            margin-top: 24px;
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: bold;
            color: $gray-color-3;
            text-align: center;
        }
    }
    .create-type-content {
        padding-bottom: 100px;
        max-width: 65%;
        margin: auto;
        .ant-card {
            background: $secondary_background_color;
            border: solid 1px $color-gray-blue;
            padding: 20px;
            border-radius: 20px;
            -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
            box-shadow: 0px 17px 13px 0px #192c4b05;
            display: block;
            .ant-card-body {
                padding: 0px;
            }
        }
        .create-type-content-holder {
            flex-direction: column;
            .img-container {
                height: 72px;
                img {
                    width: auto;
                    height: 100%;
                }
            }

            .ant-divider-horizontal {
                margin: 12px 0;
            }

            h3 {
                color: $gray-color-3;
            }
        }
    }
    .create-type {
        &__text {
            max-width: 30rem;
            margin-left: auto;
            margin-right: auto;
        }
    }
    p {
        width: 100%;
        margin: 0 auto;
        display: block;
        color: $secondary_color;
        line-height: 1.6;
        font-size: 16px;
        margin-top: 16px;
    }

    // @include min-size(sm) {
    //     max-width: 540px;
    // }
    // @include min-size(md) {
    //     max-width: 720px;
    // }
    // @include min-size(lg) {
    //     max-width: 960px;
    // }
    // @include min-size(xl) {
    //     max-width: 1140px;
    // }
    // @include min-size(xxl) {
    //     max-width: 1280px;
    // }
}

#dark-theme .create-type-container {
    .create-type__text {
        color: $white-text;
    }

    .create-type-content {
        .ant-card {
            background-color: rgb(48, 51, 57);

            h3 {
                color: $white-text;
            }

            p {
                color: $white-text;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-desktop) {
    .create-type-container {
        .create-type-content {
            max-width: 80%;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .create-type-container {
        .create-type-header {
            padding: 50px 0;
        }
        .title {
            padding: 0 10px;
            font-size: 40px !important;
        }
        .create-type {
            &__text {
                padding: 0 10px;
            }
        }
        .create-type-content {
            row-gap: 15px;
            .create-type-content-item {
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .create-type-container .create-type-content {
        max-width: 100%;
    }
}
