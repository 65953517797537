@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.buy-modal-container {
    font-size: 16px;
    color: $gray-color-3;
    p {
        margin: 0px;
    }

    .error-balance {
        color: red;
    }
    a {
        color: $primary-color;
        font-family: $font-primary;
        &:hover {
            color: $primary-color;
        }
    }
    .quantity-container {
        flex-direction: row !important;
        align-items: center !important;
        .quantity-input {
            margin-bottom: 0px;
            margin-right: 10px;
            input {
                @media screen and (max-width: $breakpoint-m-mobile) {
                    min-width: 250px;
                    width: 100%;
                }
            }
        }
    }

    & > :last-child {
        margin: auto;
        width: 100%;
    }
}
