@import 'styles/variables.scss';
.cancel-bid-modal {
    p {
        margin: 0px;
    }
    & > :first-child {
        margin-bottom: 24px;
        font-size: 24px;
        color: $gray-color-3;
        font-family: $font-primary;
    }
    & > :nth-child(2) {
        margin-bottom: 16px;
    }
    .ant-btn-loading-icon {
        margin-right: 5px;
    }
}
