@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/common';
.static-page {
    color: $secondary_color;
    font-size: $fs-text;
    padding: 100px 0;
    @media screen and (max-width: $breakpoint-tablet) {
        padding: 50px 0;
    }
    .container {
        margin: 0 auto;
        max-width: 1200px;
        padding: 0 20px;
    }
    .title-page {
        font-size: 25px;
        margin-bottom: 100px;
        margin-top: 0;
        i {
            margin-left: 12px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
            font-style: 40px;
            margin-bottom: 50px;
        }
    }
}

#dark-theme .static-page {
    .title-page {
        color: $btn-blue;
    }
}
.privacy-content {
    @include border-radius(20px);
    @extend %box-shadow;
    padding: 60px 30px;
    h6 {
        font-size: $fs-text;
    }
    p {
        line-height: 1.6;
        margin-bottom: 0;
    }
    h3 {
        font-size: 26px;
        margin-bottom: 20px;
        margin-top: 25px;
        letter-spacing: -0.01em;
        line-height: 1.3;
    }
    h4 {
        font-size: 20px;
        margin-bottom: 20px;
        letter-spacing: -0.01em;
        line-height: 1.3;
    }
    .label-version {
        margin-top: 0;
    }
}

#dark-theme .privacy-content {
    background-color: rgb(48, 51, 57);
    border: none;

    .label-version {
        color: white;
    }

    .title-field {
        color: white;
    }

    .text {
        color: rgba(255, 255, 255, 0.87);
    }
}

@media screen and (min-width: $breakpoint-xxl) {
    .static-page {
        .container {
            padding: 0 12px;
            max-width: 1440px;
        }
    }
}
