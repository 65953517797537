@import 'variables.scss';
@import 'mixins.scss';
// @font-face {
//     font-family: 'circular';
//     src: url('../assets/fonts/CircularStd-Regular.woff') format('woff'),
//         url('../assets/fonts/CircularStd-Regular.woff2') format('woff2'),
//         url('../assets/fonts/CircularStd-Regular.svg') format('svg'),
//         url('../assets/fonts/circular.ttf') format('truetype');
// }
// @font-face {
//     font-family: 'circular_bold';
//     src: url('../assets/fonts/CircularStd-Bold.svg') format('svg'),
//         url('../assets/fonts/CircularStd-Bold.woff') format('woff'),
//         url('../assets/fonts/CircularStd-Bold.woff2') format('woff2'),
//         url('../assets/fonts/CircularStd-Bold.ttf') format('truetype');
//     font-weight: normal;
// }

/*
    Poppins font
*/
@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf');
    font-weight: 400;
}

body {
    position: relative;
    font-family: $font-secondary, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 17px;
    background-color: $white;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    color: $gray-color-2;
}

* {
    box-sizing: border-box;
}

.clearfix::after {
    content: '';
    clear: both;
    display: table;
}

.d-flex {
    display: flex;
}

.justify-content {
    &-space-between {
        justify-content: space-between;
    }
    &-space-around {
        justify-content: space-around;
    }
    &-center {
        justify-content: center;
    }
}

.align-items {
    &-center {
        align-items: center;
    }
    &-end {
        align-items: flex-end;
    }
    &-start {
        align-items: flex-start;
    }
}

.text-center {
    text-align: center;
}

.my-auto {
    margin: auto 0;
}
.mx-auto {
    margin: 0 auto;
}
.cursor-pointer {
    cursor: pointer;
}
.custom-loading-icon {
    animation: spin 0.75s linear infinite;
}

.container {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 28px;
    width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
    font-family: $font_titles;
    font-weight: bold;
    text-align: inherit;
    position: relative;
    color: $primary_color;
    letter-spacing: -0.02em;
    margin-top: 16px;
}
h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    color: $gray-color-3;
}

%box-shadow {
    border: solid 1px $color-gray-blue;
    box-shadow: 0px 17px 13px 0px #192c4b05;
    background-color: white;
}

%border {
    border: 1px solid $color-gray-blue !important;
}

%button-bid {
    max-height: 50px;
    max-width: 220px;
    min-height: 46.4px;
    padding: 5px 25px;
    width: auto !important;
    font-size: $fs-text + 2;
}

%title-page {
    font-size: 55px;
    font-family: $font-primary;
    @media screen and (max-width: $breakpoint-tablet) {
        font-size: 40px;
        padding: 50px 0;
    }
}

@media screen and (min-width: $breakpoint-xxl) {
    .container {
        padding: 0 28px;
        max-width: 1440px;
    }
}

.br-12 {
    border-radius: 12px !important;
}

.br-16 {
    border-radius: 16px !important;
}

.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.ant-modal-mask {
    z-index: 1002;
}

.ant-modal-wrap {
    z-index: 1003;
}

.flip {
    transform: scaleX(1)!important;
}

.mt-40 {
    margin-top: 40px;
}
