@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.report-modal {
    width: 380px !important;
    .report-form {
        display: flex;
        flex-direction: column;
        .btn-submit {
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .report-input {
            overflow: hidden;
        }
        .reason-select .ant-select-selector {
            height: 47px !important;
            border-radius: 12px !important;
        }
        .collection-search-input-holder {
            margin: 0;
            position: relative;
            .ant-input-custom {
                height: 47px !important;
                border-radius: 12px !important;
            }
            .custom-avatar {
                position: absolute;
                left: 24px;
                height: 47px !important;
                display: flex;
                align-items: center;
                z-index: 100;
                .ant-avatar {
                    height: 30px !important;
                    width: 30px !important;
                }
            }
            .input-selected-collection {
                padding-left: 65px !important;
                .ant-input-suffix {
                    position: absolute;
                    right: 9px;
                    top: 18px;
                    svg {
                        width: 11px;
                        height: 11px;
                        path {
                            fill: #474873;
                        }
                    }
                }
            }
        }
    }
    .title {
        font-weight: 700;
        font-size: 24px;
        margin: 0px;
        color: $gray-color-3;
    }
    .desc {
        color: $gray-color-3;
    
    }
}

.report-modal-dark {
    background-color: black !important;
}

.dropdown-selected-collection {
    min-width: 100% !important;
    .collection-holder {
        max-height: 250px !important;
    }
}

.report-text-dark {
    color: $bg_input !important;
}

.original-collection-name {
    display: flex;
    justify-content: space-between;
}
