@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.dark-group-avatar {
  .collection-name {
    color: $white-text;
  }
  .creator-name {
    color: $white-text;
  }
}

.group-avatar {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 20px;
  border-top: 1px solid #cad1d8;
  padding-top: 25px;
  margin-top: 25px;
  @include up-to-size('md') {
    & > :first-child {
      .custom-avatar {
        & > :nth-child(2) {
          width: 12px;
        }
      }
    }
  }
  @include up-to-size('sm') {
    flex-wrap: wrap;
    padding-bottom: 30px;
    & > * {
      width: 50%;
    }
  }

  @media screen and (max-width: 350px) {
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .ant-avatar {
      .ant-avatar-string {
        transform: scale(1) translateX(-50%) translateY(-4px) !important;
      }
    }
  }

  .ant-avatar {
    border: 3px solid white;
  }

  .creator-name {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .collection-name {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .owner-feild {
    + .owner-feild {
      margin-left: 20px;
    }
    @include up-to-size('sm') {
      + .owner-feild {
        margin-top: 10px;
        margin-left: 0;
      }
    }
  }
  .owner-feild-inner {
    margin-left: 10px;
    .text {
      font-size: 12px;
      color: $secondary_color;
      font-family: $font-secondary;
    }
  }

  .custom-tolltip-inner {
    display: flex;
    font-size: $fs-text;
    align-items: center;
    color: $primary_color;
    font-family: $font-primary;
    overflow: hidden;
    .blockchain-icon img {
      object-fit: unset;
    }
  }
  .owner-item {
    &:last-child {
      .owner-feild-inner {
        opacity: 1;
      }
    }
  }

  .list-owner {
    min-width: 150px;
    position: relative;
    min-height: 40px;
    .owner-item {
      position: absolute;
    }
    .ant-avatar-group {
      height: 100%;
      .ant-avatar {
        height: 36.3px !important;
        width: 36.3px !important;
        @media screen and (max-width: $breakpoint-tablet) {
          height: 29px !important;
          width: 29px !important;
        }
      }
      .owner-item {
        position: unset;
      }
    }
    .avatar-group-owner {
      .owner-item {
        display: none;
      }
    }
  }
}
