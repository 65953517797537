@import "styles/variables.scss";
@import "styles/mixins.scss";

.list-container {
  .list-inner {
    padding-bottom: 50px;
  }
  .select-custom-wrapper {
    @media screen and (min-width: $breakpoint-desktop) {
      width: 280px;
      //   position: absolute;
      //   right: 20px;
      //   top: 0;
    }
    width: 280px;
    // margin-top: 10px;
    // margin-bottom: 15px;
    .ant-select-selection-item {
      margin-left: 80px;
      text-transform: lowercase;
    }
  }
  .filter-option {
    position: relative;
    margin-top: 10px;
    // @media screen and (min-width: $breakpoint-tablet) {
    //   position: absolute;
    //   right: 20px;
    //   top: -100px;
    //   margin-top: 0px;
    // }
    .btn-filter {
      height: 34px !important;
      font-size: 17px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      .btn-filter {
        font-size: 14px;
      }
    }
    .btn-filter-custom-dark {
      svg {
        path {
          fill: $white-text !important;
        }
      }
    }
  }
}
