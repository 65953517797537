@import "../../../styles/variables";

.ant-tooltip {
  z-index: 3000 !important;
  .ant-tooltip-inner {
    background: $white !important;
    color: $bg_color;
    border-radius: 8px;
    font-weight: bold;
    max-width: 500px;
    width: -moz-fit-content;
    width: fit-content;
  }
  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background: $white;
    }
  }
}
