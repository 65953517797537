@import 'styles/variables.scss';
@import 'styles/common.scss';

.ant-card.card-activity-custom {
  height: 100%!important;
  max-width: 100%;
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  background: $secondary_background_color;
  border: solid 1px $color-gray-blue;
  padding: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  display: block;
  .ant-card-body {
    display: flex;
    flex-direction: row;
    padding: 0;
    height: 100%;
    @media screen and (max-width: 330px) {
      flex-direction: column;
    }
    .card-activity-custom {
      &_item-image {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @media screen and (min-width: 992px) and (max-width: 1200px) {
          width: 121px;
        }
        @media screen and (max-width: 330px) {
          width: 100% !important;
        }
        img {
          height: auto;

          max-height: 185px;
          width: 150px;
          object-fit: cover;
          padding: 10px 10px;
          @media screen and (min-width: 992px) and (max-width: 1200px) {
            width: 121px;
          }
          @media screen and (max-width: 330px) {
            width: 100% !important;
          }
        }
      }
      &_item-image-following {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          height: auto;

          height: 150px;
          width: 150px;
          object-fit: cover;
          padding: 10px 10px;
          border-radius: 50%;
        }
        .icon {
          position: absolute;
          bottom: 25px;
          right: 10px;
          width: 45px;
          height: 45px;
        }
      }
      &_icon-file-extension {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      &-content {
        padding: 20px 20px 13px 24px;
        color: $primary_color;
        font-family: $font-primary;
        font-size: 16px;
        width: calc(100% - 150px);
        .item-name {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          margin-right: 9px;
          text-overflow: ellipsis;
          color: $primary_color;
          @media screen and (max-width: 576px) {
            max-width: 150px;
          }
          @media screen and (max-width: 320px) {
            max-width: 100%;
          }
        }

        &_type {
          margin-top: 13px;
          display: flex;
          white-space: nowrap;
          align-items: center;
          flex-wrap: wrap;
          @media screen and (max-width: 350px) {
            display: unset;
          }
          > span,
          > div > span {
            color: $third_color;
            font-weight: 500;
            margin-left: 11px;
            @media screen and (min-width: 992px) and (max-width: 1200px) {
              margin-left: 6.25px;
            }
            @media screen and (max-width: 576px) {
              margin-left: 6.25px;
            }
          }
          .avatar {
            display: inline-block;
            position: relative;
            margin-right: 12px;
            margin-left: 8px;
            @media screen and (min-width: 992px) and (max-width: 1200px) {
              margin-right: 4px;
            }
            @media screen and (max-width: 576px) {
              margin-right: 4px;
            }
            .icon {
              position: absolute;
              left: 22px;
              bottom: 0;
            }
          }
          .user-name {
            overflow: hidden;
            white-space: nowrap;
            margin-right: 9px;
            text-overflow: ellipsis;
            color: $primary_color;
            span {
              margin-left: 0;
            }
            @media screen and (min-width: 576px) and (max-width: 768px) {
              max-width: 125px !important;
            }
            @media screen and (max-width: 440px) {
              max-width: 60px !important;
            }
            @media screen and (max-width: 320px) {
              max-width: 150px !important;
            }
          }
          .price {
            margin-left: 8px;
            color: $primary_color;
            font-weight: 700;
            @media screen and (min-width: 992px) and (max-width: 1200px) {
              margin-left: 6px;
            }
            @media screen and (max-width: 576px) {
              margin-left: 6px;
            }
            @media screen and (max-width: 340px) {
              max-width: 90px;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        &_user {
          &-wrapper {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            .d-flex {
              margin-top: 16px;
              align-items: center;
            }

            .avatar {
              display: inline-block;
              position: relative;
              margin-right: 12px;
              @media screen and (min-width: 992px) and (max-width: 1200px) {
                margin-right: 6.25px;
              }
              @media screen and (max-width: 576px) {
                margin-right: 6.25px;
              }
              .icon {
                position: absolute;
                left: 22px;
                bottom: 0;
              }
            }
            .user-name {
              max-width: 125px;
              overflow: hidden;
              white-space: nowrap;
              margin-right: 9px;
              text-overflow: ellipsis;
              color: $primary_color;
              @media screen and (max-width: 1400px) {
                max-width: 70px;
              }
              @media screen and (min-width: 992px) and (max-width: 1200px) {
                margin-right: 4px;
              }
              @media screen and (min-width: 768px) and (max-width: 992px) {
                max-width: 125px;
              }
              @media screen and (max-width: 576px) {
                margin-right: 4px;
              }
              @media screen and (max-width: 440px) {
                max-width: 45px;
              }
              @media screen and (max-width: 320px) {
                max-width: 100px;
              }
            }
            .user-name-long {
              max-width: 230px;
              overflow: hidden;
              white-space: nowrap;
              margin-right: 9px;
              text-overflow: ellipsis;
              color: $primary_color;
              @media screen and (max-width: 576px) {
                max-width: 150px;
              }
              @media screen and (max-width: 320px) {
                max-width: 100px;
              }
            }
            .dark-user-name-long {
              color: $third_color;
            }
            .price {
              white-space: nowrap;
              margin-left: 8px;
              margin-right: 10px;
              color: $primary_color;
              font-weight: 700;
              @media screen and (min-width: 992px) and (max-width: 1200px) {
                margin-left: 4px;
                margin-right: 8px;
              }
              @media screen and (max-width: 576px) {
                margin-left: 4px;
                margin-right: 8px;
              }
            }
          }
        }
        &_to-user-wrapper {
          display: inline-block;
          span:first-of-type {
            color: $third_color;
            font-weight: 500;
            margin-right: 8px;
          }
          .to-user-container {
            display: inline-block;
            .to-user {
              display: inline-block;
              .icon {
                position: absolute;
                left: 22px;
                bottom: 0;
              }
            }
            span {
              color: $primary_color;
              font-weight: 700;
            }
          }
        }
        &_date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 20px;
          row-gap: 10px;
          color: $third_color;
          font-size: 12px;
          font-weight: 500;
          img {
            margin-left: 9px;
          }
        }
      }
    }
  }
  .gray-text {
    color: $third_color;
    font-weight: 500;
  }
  @media screen and (max-width: 576px) {
    margin-left: 16px;
    margin-right: 16px;
    .card-activity-custom {
      &_item-image {
        width: 76px !important;
        img {
          width: 76px !important;
          max-height: 123px !important;
        }
      }

      &_item-image-following {
        width: 76px !important;
        position: relative;
        img {
          width: 76px !important;
          height: 76px !important;
        }
        .icon {
          position: absolute;
          bottom: 8px !important;
          right: 0 !important;
          width: 40px !important;
          height: 40px !important;
          @media screen and (max-width: 576px) {
            width: 32px !important;
            height: 32px !important;
            bottom: 30px !important;
            right: 2px !important;
          }
        }
      }
      &-content {
        padding: 12px 10px !important;
        width: calc(100% - 76px) !important;
        .item-name {
          font-size: 14px;
        }
        &_type,
        &_user {
          font-size: 12px;
          .avatar {
            .ant-avatar {
              width: 18px;
              height: 18px;
            }
          }
          .icon {
            left: 12px !important;
            height: 5.5px;
          }
        }

        &_type {
          .type-icon {
            height: 14px;
          }
          margin-top: 6px !important;
        }

        &_user {
          padding-top: 0 !important;
          &-wrapper {
            .d-flex {
              align-items: center;
              margin-top: 6px;
            }
          }
        }

        &_date {
          font-size: 10px !important;
          margin-top: 12px !important;
        }
      }
    }
  }
}

#dark-theme .ant-card.card-activity-custom {
  background: rgb(48, 51, 57);
  border: none;
  min-height: 230px;

  .card-activity-custom {
    &-content {
      color: white;
      .item-name {
        color: $white;
      }

      &_type {
        .user-name {
          color: $white;
        }

        .price {
          color: $white;
        }
      }

      &_user {
        .user-name {
          color: $white;
        }
        &-wrapper {
          .price {
            color: $white-text;
          }
        }
      }
    }
  }
}

.blue .ant-tooltip-content .ant-tooltip-inner {
  color: $primary_color !important;
}
