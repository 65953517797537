@import '../../../styles/variables';

.ant-switch:focus {
    box-shadow: none;
}

.ant-switch.switch-custom.ant-switch-checked {
    background-color: $main-color;
    border: 1px solid $main-color;
    display: flex;
    align-items: center;
    height: 24px;
    .ant-switch-handle {
        top: 50%;
        transform: translateY(-50%);
        &::before {
            background: $white;
        }
    }
    .ant-switch-inner {
        line-height: 1;
        color: $main-color;
    }
}

.ant-switch.switch-custom.default{
    background-color: $third_color;
    .ant-switch-handle {
        &::before {
            background: $white !important;
        }
    }
}

.ant-switch.switch-custom.ant-switch-checked.default{
    background-color: $main-color;
    .ant-switch-handle {
        &::before {
            background: $white !important;
        }
    }
}

.ant-switch.switch-custom {
    width: 20px;
    background-color: $gray-color;
    border: 1px solid $gray-color;
    display: flex;
    align-items: center;
    height: 24px;
    .ant-switch-handle {
        top: 50%;
        transform: translateY(-50%);
        &::before {
            background: $white;
        }
    }
    .ant-switch-inner {
        line-height: 1;
        color: $gray-color;
    }
}
