@import '../../../styles/variables';
@import 'styles/mixins.scss';

.ant-input.ant-input-custom {
        height: 48px;
        padding: 8px 16px;
        @include text-normal-regular;
        border-radius: 16px;
    
        @include up-to-size('md') {
            font-size: 14px;
        }
    
        &:hover {
            border: 1px solid $main-color !important;
        }
    
        &:focus-within {
            border: 1px solid $main-color !important;
            color: $primary_color !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        &::placeholder {
            color: $gray-color-2;
        }
    
        &--outline {
            background-color: $white;
            border: 1px solid $gray-color !important;
            color: $primary_color !important;
        }
    
        &--filled {
            background-color: $white !important;
            border: 1px solid $white !important;

            input {
                background-color: $white !important;
                color: $primary_color !important;
            }
        }
    }

#dark-theme .ant-input.ant-input-custom {
    background: #4c505c;
    color: white;
}

.ant-input-affix-wrapper {
    height: 48px;
    padding: 8px 16px;
    border-radius: 16px;
    border: 1px solid $gray-color;

    .ant-input-prefix {
        margin-right: 10px;
    }

    &.ant-input-custom {
        &:hover {
            border: 1px solid $main-color !important;
        }
    
        &:focus-within {
            border: 1px solid $main-color !important;
            color: $primary_color !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
    
        &--outline {
            background-color: $white;
            border: 1px solid $gray-color !important;
            color: $primary_color !important;
        }
    
        &--filled {
            background-color: $white !important;
            border: 1px solid $white !important;

            input {
                background-color: $white !important;
                color: $primary_color !important;
            }
        }
    }


    input {
        @include text-normal-regular;
        color: $primary_color;

        @include up-to-size('md') {
            font-size: 14px;
        }
        &::placeholder {
            color: $gray-color-2;
        }

        &:focus {
            border: none !important;
            color: $primary_color;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
    }
}

.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}

.ant-form-item-has-error {
    .ant-form-item-control {
        .ant-form-item-explain.ant-form-item-explain-error {
            font-size: 12px;
            color: $red;
            margin-top: 12px;
            margin-bottom: -8px;
        }
    }
    .ant-input-affix-wrapper-focused {
        border: 1px solid $red !important;
    }
    .ant-input-affix-wrapper {
        border: 1px solid $red !important;
    }
    .ant-input-custom {
        border: 1px solid $red !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
