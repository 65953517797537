// Main color
$main-color: #EE811A;
$main-color-2: #FF9732;
$main-color-3: #DF7000;
$gradient-main-color: linear-gradient(to right, #FFAB00 100%, #FF4B00 100%);

// Colors
$gray-color: #F0EEEE;
$gray-color-2: #999999;
$gray-color-3: #666666;

$primary_color: #1e1a1a;
$secondary_color: #757575;
$third_color: #a3aed0;
$layout_background: #f5f5f5;
$primary_background_color: #fafafa;
$secondary_background_color: #ffffff;
$bg_icon: #ed1c51;
$bg_input: #f2f7fa;
$bg_input_dark: #333030;
$bg_color: #041c4a;
$bg_color_active: #1d325d;
$shadow_color: #7e8eb11f;
$color-text: #ffffff;
$color-gray-blue: #e2eef1;
$white: #ffffff;
$green: #43a047;
$price-buy: #36b37e;
$bg_hover_item: #ccd4e4;
$bg_menu_active: #ccd4e4;
$red: #952623;
$btn_blue: linear-gradient(95.32deg, #b809b5 -7.25%, #ed1c51 54.2%, #ffb000 113.13%);
$white-text: rgba(255, 255, 255, 0.87);
$second_white_text: rgba(255, 255, 255, 0.6);
$btn_white: #ffffff;
$dashed-line: rgba(255, 255, 255, 0.2);
$dark_primary_color: #041c49;
$border_color: #e8ecf2;
$border_color_dark: #333030;
$table_head: #e1e7f4;
$input-prefix-background: rgba(163, 174, 208, 0.3);
$video-background: rgb(34, 34, 34);
$box_shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

// Dark Theme Color
$primary_background_color_dark: #292828;
$secondary_background_color_dark: black;

$fs-title-box: 34px;
$fs-text: 17px;
$fs-title-page: 24px;
$fs-title-modal: 24px;
$font-primary: 'Poppins';
$font-secondary: 'Poppins';
$font_titles: $font-primary, -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$bold: 700;
$normal: 400;

$breakpoint-xxl: 1440px;
$breakpoint-xl: 1200px;
$breakpoint-desktop: 1024px;
$breakpoint-lg-tablet: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 435px;
$breakpoint-m-mobile: 375px;
$breakpoint-sm-mobile: 320px;

$breakpoints: (
    xs: 0,
    sm: 576px,
    md: 767px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
