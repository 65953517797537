@import 'styles/variables.scss';
.custom-pagination {
  &__container {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-color-3;
    gap: 16px;
  }
  &__button-list {
    display: flex;
    align-items: center;
    gap: 8px;
    i {
      font-size: large;
    }
    span {
      min-width: 40px;
      max-height: 32px;
      background-color: white;
      border-radius: 6px;
      border: 1px solid $gray-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-block: 6px;
    }
    span.active {
      background-color: $main-color;
      color: white;
      border: none;
    }
    button {
      width: 24px !important;
      height: 32px !important;
      background-color: transparent;
      border-radius: 6px !important;
      &:hover {
        background-color: $gray-color !important;
      }
      img:hover {
        background-color: transparent;
      }
    }
  }
}

#dark-theme .custom-pagination {
  &__container {
    color: $white;
  }

  &__select.ant-select.ant-select-custom {
    .ant-select-selector {
      background-color: $primary_background_color_dark !important;
    }
    .ant-select-arrow {
      svg {
        path {
          fill: $white-text;
        }
      }
    }
  }
}
