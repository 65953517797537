@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
// $button-bg: $btn_blue;

.ant-btn.ant-btn-custom {
  border: none;
  height: 48px;
  width: 150px;
  min-width: fit-content;
  border-radius: 22px;
  position: relative;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  transition: transform ease-in 0.1s;
  @include text-normal-semibold;
  i {
    vertical-align: middle;
    margin-right: 5px;
  }

  .connect-icon {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.3;
  }

  &:hover {
    outline: 0 !important;
    border-color: unset;
  }

  &:active {
    transform: scale(0.9);
  }
}

.ant-btn-color-blue {
  background: $main-color !important;
  color: white !important;

  &:hover {
    background-color: $main-color-2 !important;
  }

  &:active {
    background: $main-color-3 !important;
  }
}

.ant-btn-color-dark {
  background: black !important;
  color: white !important;
  &:hover {
    opacity: 0.9;
  }
}

#dark-theme .ant-btn {
  background: black;
  color: $white !important;
  &:hover {
    opacity: 0.9;
  }
}

#dark-theme .ant-btn-color-dark {
  background: white !important;
  color: black !important;
  &:hover {
    opacity: 0.9;
  }
}

.ant-btn-color-red {
  background: $red !important;
  color: white !important;

  &:hover {
    opacity: 0.9;
  }
}

.ant-btn-color-white {
  background: $btn_white !important;
  color: $gray-color-3 !important;
  border: 1px solid $gray-color !important;

  &:hover {
    opacity: 0.8;
  }
}

.ant-btn-color-gray {
  background: $layout_background !important;
  color: #525252 !important;

  &:hover {
    background: $primary_background_color !important;
    border: none !important;
  }
  &:active {
    background: #e5e5e5 !important;
    transform: scale(1)!important;
  }
}

.ant-btn-shape-square {
  width: 50px !important;
  height: 50px !important;

  img {
    width: 18px;
    height: 18px;
  }
}

.ant-btn-lg {
  height: 48px;
  border-radius: 16px;
}

.ant-btn-md {
  height: 40px !important;
  border-radius: 16px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.ant-btn-small {
  height: 36px !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.ant-btn-xs {
  height: 28px !important;
  border-radius: 12px !important;
  font-size: 10px !important;
  line-height: 16px !important;
}

// .ant-btn-medium {
//     font-size: 16px !important;
//     height: 44px !important;
//     border-radius: 22px !important;
//     font-weight: 400;
// }

.ant-btn-fullwidth {
  width: 100% !important;
}

.ant-btn-gradien {
  transition: all 0.4s ease-in-out !important;
  background: radial-gradient(
    100% 100% at 53.13% 0%,
    #31e7fa 0%,
    #4477ff 52.6%,
    #db74ff 100%
  ) !important;
  color: $secondary_background_color !important;
  border: none !important;
  -webkit-transition: all 0.4s ease-in-out !important;
}

.ant-btn-border-none {
  border: none !important;
}

.ant-btn-outline {
  border: 1px solid $main-color !important;
  color: $main-color;
  background: transparent;

  &:hover {
    background-color: #fff2e6 !important;
    color: $main-color !important;
  }

  &:active {
    background-color: #ffdbb8 !important;
    color: $main-color !important;
  }
  &:focus {
    color: $main-color !important;
  }
}

.ant-btn-ghost {
  border: none;
  color: $gray-color-3;
}

.ant-btn-outline-sub {
  border: 1px solid $gray-color !important;
  color: $gray-color-2;

  &:hover {
    border: 1px solid $main-color !important;
    color: $main-color;
  }

  &:focus {
    color: $main-color !important;
  }
}

.ant-btn-gradient {
  background-image: linear-gradient(180deg, #ffab00 0%, #ff4b00 100%);
  border-image-source: linear-gradient(180deg, #ffab00 0%, #ff4b00 100%);
  border-width: 1px;
  border-image-slice: 1;
  color: $white-text;

  &:hover {
    background: $main-color !important;
    color: white !important;
  }
}

.text-white {
  color: $white;
  border-radius: 40px !important;
}
