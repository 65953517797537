@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.detail-tabs-container {
    padding-top: 25px;
    .ant-tabs-nav-list {
        flex-wrap: wrap;
        gap: 12px;
    }
    .ant-tabs-tab {
        padding: 10px 20px;
        color: $gray-color-2;
        font-weight: 500;
        margin-left: 0!important; 
        margin-right: 0!important; 
        &:first-of-type {
            margin-left: 0px;
        }
        border: 1px solid $gray-color;
        box-shadow: none;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: white;
        }
        background-color: $main-color;
        border: none;
    }
    .ant-tabs-tab .ant-tabs-tab-btn {
        color: $gray-color-3;
        & > :first-child {
            display: flex;
            align-items: center;
            .fire-icon {
                width: 25px;
                object-fit: contain;
                margin-bottom: 2px;
                margin-right: 5px;
            }
            .offers-tab-head {
                & > :last-child {
                    margin-left: 8px;
                }
                svg {
                    fill: currentColor;
                }
                display: flex;
                align-items: center;
            }
        }
    }

    .ant-tabs-ink-bar {
        height: 4px !important;
        background: $primary_color !important;
    }
    .ant-tabs-top,
    .ant-tabs-bottom,
    .ant-tabs-top > div,
    .ant-tabs-bottom > div {
        & > .ant-tabs-nav {
            &::before {
                border-bottom: 1px solid $btn_blue !important;
                opacity: 0.1;
            }
            margin-bottom: 32px;
        }
    }
    .offers__buttons {
        > span {
            display: flex;
            align-items: center;
            svg {
                margin-left: 6px;
            }
        }
    }
    .ant-tabs-tabpane {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
}


.ri-auction-line {
    margin-right: 8px;
}
