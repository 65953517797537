@import 'styles/variables.scss';

.snail-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text-holder {
        max-width: 850px;
        text-align: center;
        .title-page {
            font-family: inherit;
            font-weight: 700;
            font-size: 42px;
            vertical-align: inherit;
            margin-bottom: 32px;
        }
        .content-snail {
            text-align: left;
            margin-bottom: 20px;
        }
    }

    .wallet-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        vertical-align: inherit;
        max-width: 720px;
        min-height: 0px;
        flex-shrink: 0;
        flex-basis: auto;
        display: flex;
        align-items: stretch;
        margin-bottom: 48px;
        width: 100%;
        .wallet-card {
            width: 100%;
            border: 1px solid rgba(4, 4, 5, 0.02);
            border-radius: 16px;
            box-shadow: rgba(230, 230, 230, 0) 0px 2px 24px;
            min-height: 92px;
            justify-content: center;
            .ant-card-body {
                padding: 24px;
            }
        }
        .wallet-holder {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            .text-holder {
                flex-grow: 1;
                .text {
                    font-size: 18px;
                }
            }
        }
    }
    p {
        margin-bottom: 0;
    }
}

#dark-theme .snail-holder {
    .wallet-container {
        .wallet-card {
            background-color: rgb(48, 51, 57);

            .wallet-holder {
                .text {
                    color: $white;
                }
            }
        }
    }

    .text-holder {
        .title-page {
            color: $white;
        }
        p {
            color: $white;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .snail-holder {
        .wallet-container {
            .wallet-holder {
                justify-content: center;
            }
        }
    }
}
