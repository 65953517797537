@import 'styles/variables.scss';

.ant-modal.create-nft-flow_custom {
    .ant-modal-content {
        border-radius: 16px !important;
        max-width: 100%;
    }
    .create-nft-flow_header {
        .create-nft-flow_title {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            color: $gray-color-3;
        }
    }

    .create-nft-steps {
        margin-top: 16px;

        .create-nft-step {
            display: flex;
            align-items: flex-start;
            margin-bottom: 24px;
            .waiting-status {
                margin-left: 45px;
            }
            .create-nft-step_loading {
                margin-top: 4px;
                margin-right: 26px;
                border: 3px solid rgba(0, 41, 121, 0.5);
                border-top: 3px solid $primary_color;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                animation: spin 1s linear infinite;
            }
            .create-nft-step_start {
                margin-right: 24px;
            }

            .create-nft-step_icon {
                margin-right: 24px;
            }

            .create-nft-step_content {
                display: flex;
                flex-direction: column;
                flex: 1;

                .create-nft-step_content__title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    color: $gray-color-3;
                    margin-bottom: 4px;
                }

                .create-nft-step_content__desc {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    margin-bottom: 16px;
                    color: $third_color;
                }

                .create-nft-step_content__btn {
                    height: 40px !important;
                }
            }
        }
    }
    .warning {
        margin-top: 24px;
        border-top: 1px solid $border_color;
        padding-top: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $gray-color-3;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
