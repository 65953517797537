@import '../../../../styles/variables.scss';

.sidebar {
  color: $gray-color-2 !important;
  position: sticky;
  top: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: fit-content;
  max-height: calc(100vh - 88px);
  padding-bottom: 20px;
  width: 168px;
  min-width: 168px;
  .sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ant-menu-inline {
      border: none;
      .sidebar-title,
      .sidebar-title + .ant-menu-item-selected {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        background: none;
        &::after {
          border: none;
        }
        span {
          flex-grow: 1;
          a {
            color: $gray-color-3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            i {
              margin-left: auto;
            }
          }
        }
      }
      .ant-menu-submenu-title {
        color: $gray-color-3;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .sidebar-title:hover,
      .ant-menu-submenu-open,
      .ant-menu-submenu-active,
      .ant-menu-submenu-open > .ant-menu-submenu-arrow,
      .ant-menu-submenu-selected > .ant-menu-submenu-arrow,
      .ant-menu-submenu-title:hover,
      .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
      .ant-menu-submenu-active > .ant-menu-submenu-arrow {
        span {
          color: $main-color;
        }
        svg path {
          fill: $main-color;
        }
      }
      .ant-menu-submenu:hover
        > .ant-menu-submenu-title
        > .ant-menu-submenu-arrow {
        color: $main-color;
      }
      .ant-menu-sub {
        background: transparent;
      }
      .ant-menu-sub .ant-menu-item {
        padding-left: 20px !important;
      }
      .ant-menu-item {
        &hover {
          color: $main-color;
        }
        a {
          color: $gray-color-3;
          &:hover {
            color: $main-color !important;
          }
        }
      }
      .dropdown-sidebar .ant-menu-item-selected {
        font-weight: 600;
        border: 1px solid rgba($main-color, 0.3);
        border-radius: 16px;
        background: rgba($main-color, 0.1);
        a {
          color: $main-color !important;
        }
        &::after {
          border: none;
        }
      }
    }
    button {
      border-radius: 16px;
      img {
        margin-right: 12px;
      }
      i {
        margin-left: auto;
      }
    }
  }
  > button.ant-btn:hover {
    color: $main-color !important;
  }
}

.sidebar-mobile {
  position: sticky;
  bottom: 0;
  width: 100vw;
  height: 84px;
  justify-content: center;
  background: white;
  z-index: 2000;
  border-top: 4px solid #f5f5f5;
  font-size: 10px;

  &__action {
    height: 84px;
    margin-top: -18px;
    display: flex;
    gap: 52px;
    width: fit-content;
    align-items: flex-end;
    font-weight: 600;
    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $gray-color-3;
      padding-bottom: 8px;
      cursor: pointer;
      &:hover {
        color: $main-color;
        svg path {
          fill: $main-color;
        }
      }
    }
    .wallet-button {
      border: 8px solid white;
      background: $main-color;
      color: white;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
    }
  }
}

.logout-modal-container {
  width: fit-content;
  .ant-modal-title {
    // position: fixed !important;
    color: $gray-color-3 !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    padding: 48px 48px 0px 48px;
  }
  // .ant-modal-body {
  //   display: flex;
  //   justify-content: center;
  // }
  .button-modal-logout-container {
    display: flex;
    justify-content: space-around;
    margin: 48px 48px 0px 48px;
    .cancelButton {
      background-color: $layout_background;
      margin-right: 20px;
    }
    .okButton {
      margin-left: 20px;
    }
  }
}

@media screen and (min-width: $breakpoint-mobile) {
  .sidebar {
    // display: block;
  }
  .sidebar-mobile {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .sidebar {
    display: none;
  }
  .sidebar-mobile {
    display: flex;
  }
}
