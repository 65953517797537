@import 'styles/variables.scss';

.create-nft-marketPlace_btnGroup {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .create-nft-marketPlace_btn {
        flex: 1;
        cursor: pointer;
        background: $white;
        height: 180px;
        border: 1px solid $gray-color;
        box-sizing: border-box;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-block: 54px;

        .create-nft-marketPlace_btn__icon {
            margin-bottom: 17px;
            opacity: 0.5;
        }
        & > span {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: $gray-color-2;
            text-align: center;
            @media screen and (max-width: $breakpoint-m-mobile) {
                font-size: 12px;
            }
        }
    }

    .create-nft-marketPlace_btn {
        &.active {
            border: 1px solid $main-color;
            svg * {
                fill: $main-color;
            }
            & > span {
                color: $gray-color-3;
                font-size: 16px;
                font-weight: bold;
            }
            .create-nft-marketPlace_btn__icon {
                opacity: 1;
            }
            .auction-icon {
                i {
                    color: #084f87;
                }
                .dark-ri-auction-line {
                    color: white;
                }
            }
        }

        &.disabled {
            cursor: not-allowed;
        }
    }
    .create-nft-marketPlace_btn.ml {
        .auction-icon {
            i {
                color: black !important;
            }
        }
        .restore-icon {
            filter: brightness(0);
            -webkit-filter: brightness(0);
        }
    }
}

#dark-theme .create-nft-marketPlace_btnGroup {
    .create-nft-marketPlace_btn {
        background-color: #4c505c;

        & > span {
            color: rgba(237, 234, 234, 0.687);
        }
        .create-nft-marketPlace_btn__icon {
            height: 24px;
            width: 30px;
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
        }
    }

    .create-nft-marketPlace_btn.active {
        & > span {
            color: white;
        }
    }
}

@media screen and (max-width: $breakpoint-mobile){
    .create-nft-marketPlace_btnGroup {
        flex-direction: column;
        gap: 16px;
        .create-nft-marketPlace_btn {
            padding-block: 24px;
        }
    }
}
