@import '../../../styles/variables';

.ant-card.card-user-custom {
  padding: 0;
  border-radius: 8px;
  cursor: pointer;
  background: $white;
  box-shadow: 0px 4px 32px rgba(52, 87, 210, 0.05);
  position: relative;
  border: none;

  .ant-card-body {
    padding: 0;
  }

  .card-user-custom-header {
    position: relative;
  }

  .card-user-custom-user {
    position: absolute;
    bottom: -30px;
    display: flex;
    justify-content: center;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 0);

    .card-user-custom-user_wrapper {
      position: relative;
      .card-user-custom-user_avatar {
        width: 60px;
        height: 60px;
        border: 2px solid $white;
        position: relative;
        img {
          background: $gray-color;
        }
      }

      .card-user-custom-user_icon__last {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 18px;
        height: 18px;
      }
    }
  }

  .card-user-custom-avatar {
    width: 100%;
    height: 100px;
    border-radius: unset;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
    background-color: $gray-color;
    &.default img {
        object-fit: none;
        transform: scale(0.4);
    }
  }

  .card-user-custom-content {
    padding: 46px 20px 23px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &_name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      color: $primary_color;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    &_followers {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: $third_color;
    }
  }
}

.tooltip-customer-title__role {
  text-transform: capitalize;
}
