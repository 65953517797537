@import '../../../styles/variables';

.label-custom_wrapper {
    position: relative;

    .label-custom_subdesc {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $third_color;
        display: block;
        margin-bottom: 10px;
    }

    .label-custom_desc {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $third_color;
        margin-top: 7px;
        display: block;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

.label-custom_title_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.label-custom_title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: $primary_color;
    display: block;
}

#dark-theme .label-custom_title {
    color: $white-text;
}

.label-custom_wrapper-report {
    .label-custom_title {
        color: $white-text;
    }
}

.avatar-collection-default {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
