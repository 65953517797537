@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.collection-detail-container {
  width: 100%;

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }

  .collection-banner {
    padding: 28px;
    background-color: $white;
    border-radius: 28px;
    border: 1px solid $gray-color;
    &__img {
      margin-bottom: 40px;
      position: relative;

      img {
        height: 260px;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      .edit-button {
        position: absolute;
        transition: all 0.25s ease-in-out;
        right: 10px;
        bottom: 10px;
      }
    }

    &__profile {
      padding: 0 28px;

      &__info {
        display: flex;
        gap: 16px;
        margin-bottom: 40px;

        .avatar {
          position: relative;
          height: fit-content;

          .btn-edit-avatar-collection {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            font-size: 25px;
            background-color: #ccc;
            color: $primary_color;
            display: flex;
            transition: all 0.25s ease;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              margin: auto;
            }
          }

          .verify-icon {
            top: 75px;
            right: 5px;
            margin: 0;
          }
        }

        .collection-info {
          width: 100%;

          &__section {
            padding: 0 24px;
            margin-bottom: 24px;

            .collection-name {
              @include heading-extra-large;
              font-weight: 600;
              color: $gray-color-3;
              margin-bottom: 24px;
            }

            .collection-description {
              display: flex;
              gap: 24px;
              flex-wrap: wrap;

              .item {
                div:first-child {
                  @include text-small-medium;
                  color: $gray-color-2;
                }

                div:last-child {
                  font-size: 20px;
                  line-height: 24px;
                  font-weight: 600;
                  color: $gray-color-3;

                  .sub-value {
                    @include text-normal-medium;
                    color: $gray-color-2;
                  }
                }
              }
            }

            .collection-tags {
              display: flex;
              align-items: center;
              gap: 12px;
              flex-wrap: wrap;
              button {
                font-weight: 500 !important;
              }
            }

            .ant-btn-custom {
              display: flex;
              align-items: center;
              gap: 8px;

              &.tag-only {
                pointer-events: none;
              }
            }
          }

          .description-input {
            width: 100%;
          }
          .desc .des_content {
            font-weight: 500;
          }
        }
      }

      &__tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-tabs-tab-btn {
          color: $gray-color-3 !important;
        }

        .collection-buttons {
          display: flex;
          align-items: center;
          gap: 12px;

          .ant-btn-custom {
            display: flex;
            align-items: center;
            gap: 8px;
            width: fit-content;
          }

          .user-info-btn {
            border: 1px solid $gray-color !important;
            color: $gray-color-2 !important;
            font-weight: 500 !important;

            &:hover {
              border: 1px solid $main-color !important;
              color: $main-color !important;
            }
          }
        }
      }
    }
  }
}

.collection-detail-filters {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 28px!important;
  margin-bottom: 40px !important;

  .search {
    position: relative;
    width: 100%;

    .search-box {
      padding-left: 44px;
      display: flex;
      align-items: center;
      width: 100% !important;
    }

    .searchIcon {
      position: absolute;
      left: 16px;
      top: 10px;
      cursor: pointer;
      height: 20px;
      width: auto;
      z-index: 1;
      color: $gray-color-3;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__selects {
    display: flex;
    gap: 16px;
  }

  .select-custom-wrapper {
    width: 40%;
  }

  .view-mode {
    .ant-tabs-nav {
      margin: 0;

      .ant-tabs-nav-list {
        padding: 0;

        .ant-tabs-tab {
          display: flex;
          justify-content: center;
          margin: 0;
          box-shadow: none;
          border-radius: 16px;
          width: 52px;

          svg {
            color: $gray-color-2;
          }

          &.ant-tabs-tab-active {
            background-color: $main-color;

            svg {
              color: $white;
            }
          }

          &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:nth-child(2) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

.all-categories-button {
  &__dropdown {
    &--filter {
      width: fit-content !important;

      .dropdown-collection {
        .collection-holder {
          margin: 0;
          margin-right: -16px;
        }
      }
    }
  }
}

.select__dropdown--categories {
  width: 210px !important;
  z-index: 999 !important;
}

.address-button {
  .copied-text {
    padding: 0 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .content {
    visibility: hidden;
    position: relative;
  }
  .content::after {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    content: "Copied";
  }
  > span {
    display: flex !important;
  }
}

.button-holder {
  display: flex;
  justify-content: center;
  gap: 20px;
  .icon-container {
    color: #000000;
    cursor: pointer;
    background: $secondary_background_color;
    height: 40px;
    width: 40px;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: solid 1px $color-gray-blue;
    border-radius: 2000px;
    -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    &:hover {
      box-shadow: none;
      background: $color-gray-blue;
    }
    img:hover {
      cursor: pointer;
    }

    .ri-share-line,
    .ri-more-fill {
      font-size: 20px;
      font-weight: 500;
    }

    .social-icon {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
    }
  }
}

.description-input {
  max-width: 400px !important;
  margin-top: 24px !important;
}

.short-url-input {
  margin-top: 0 !important;
}

// .avatar-collection {
//     .btn-edit-avatar-collection {
//         cursor: pointer;
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         border-radius: 50%;
//         font-size: 25px;
//         background-color: #ccc;
//         color: $primary_color;
//         opacity: 0;
//         visibility: hidden;
//         display: flex;
//         transition: all 0.25s ease;
//         i {
//             color: $primary_color;
//             margin: auto;
//         }
//     }
//     &:hover .btn-edit-avatar-collection {
//         opacity: 1;
//         visibility: visible;
//     }
// }

.dark-action-edit {
  .cancel-edit-button {
    background-color: black !important;
  }
}

.action-edit {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  margin-left: 8px;
}
.edit-description-button {
  margin: 24px 0 !important;
}

.desc {
  display: flex;
  align-items: center;
  position: relative;
  word-break: break-word;
  width: 100%;

  .desc-text {
    display: flex;
    word-break: break-word;
    text-align: left;
    margin-bottom: 0;
    @include text-normal-medium;
    color: $gray-color-2;
  }
  .edit-description-icon {
    cursor: pointer;
    display: none;
    position: absolute;
    right: -20px;
    top: 0;
  }
  .desc-content {
    width: 100%;
    @include text-normal-medium;
    color: $gray-color-2;
  }
  &:hover {
    .edit-description-icon {
      display: block;
      padding-left: 10px;
    }
  }
}

.btn-loadmore {
  margin: 50px auto 60px;
}

.header-sort-by .btn-filter {
  height: 34px !important;
  box-shadow: none;
}

.twitter-verified-collection {
  color: green;
}

.twitter-link {
  display: flex;
  align-items: center;
  font-weight: 300;

  .ri-twitter-fill {
    margin-right: 7px;
    font-size: 20px;
  }
}

.unconfirmed-twitter-link {
  color: $secondary_color;
  font-weight: 300;

  &:hover {
    color: rgb(153, 153, 153);
  }
}

.collection-creator {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  .collection-creator-name {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin: 0 20px;
    cursor: pointer;

    span {
      padding: 0px;
    }
  }

  & svg {
    position: absolute !important;
    right: -16px !important;
    bottom: -3px !important;
  }

  .user-avatar {
    margin-left: 10px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    grid-column: 1 / -1;
    margin-top: 7px;
  }
}

.dark-collection-creator {
  span {
    color: $white-text;
  }
  .collection-creator-name {
    color: $white-text;
  }
}

.no-twitter-text {
  color: $primary_color;
  font-weight: 300;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.no-twitter-text-dark {
  color: $white-text;
}

.container {
  .properties-btn {
    .ri-arrow-down-s-line {
      font-size: 20px;
    }
  }
  .sort-filter-btn {
    svg {
      height: 8px !important;
      width: auto !important;
    }
  }
  // .all-categories-button {
  //     .ant-select-selector {
  //         border: 1px solid rgba(0, 0, 0, 0.188) !important;
  //     }
  // }
  .search {
    .search-box {
      border: 1px solid rgba(0, 0, 0, 0.188);
    }
  }
}

.dark-container {
  svg {
    path {
      fill: $white-text;
    }
  }
}

@media screen and (max-width: $breakpoint-lg-tablet) {
  .collection-banner__profile {
    padding: 0 0!important;
    &__info {
      .avatar {
        width: 100px;
      }
      flex-direction: column;
      align-items: center;
    }
    &__tabs {
      flex-direction: column;
      gap: 16px;
    }
  }
  .collection-detail-filters {
    flex-direction: column;
    padding-inline: 0!important;
  }
}
