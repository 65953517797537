@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/common.scss';

.detail-container {
  color: $primary_color;
  &.container {
    padding-top: 35px;
    padding-bottom: 100px;
  }
  @include up-to-size('md') {
    display: flex;
    flex-direction: column;
  }

  p {
    margin: 0px;
  }
  & > * {
    display: block;
  }
  & > :nth-child(3) {
    margin-top: 60px;
    width: 100%;
    @include up-to-size('sm') {
      margin-top: 40px;
    }
  }
  .link-home {
    margin: 40px 0;
    color: $primary_color;
    background: white;
    @extend %border;
    box-shadow: 0px 2px 4px $shadow_color;
    width: fit-content;
    padding: 3px 20px;
    border-radius: 18px;
    &:hover,
    &:focus {
      color: $btn_blue;
    }
  }
  .dark-asset-detail {
    .ant-btn-color-white,
    .share-icon-container,
    .description {
      border: 1px solid rgba(255, 255, 255, 0.145) !important;
      background-color: $primary_background_color_dark !important;
      color: $white-text !important;
    }
    .ant-btn-color-white {
      span {
        .art-icon {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }
  }

  .asset-detail {
    .description {
      @extend %border;
    }
    .share-icon-container:has(button) {
      min-width: fit-content !important;
    }
    .share-icon-container button,
    .report-icon-container {
      height: 40px;
      width: fit-content;
      max-width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: 1px solid $gray-color!important;
      border-radius: 20px;
      padding: 8px 16px;
      img {
        margin-right: 0;
      }
      i {
        color: $gray-color-3;
      }
      &:not(.ant-btn-custom) {
        font-weight: 500;
      }
      span {
        font-size: 14px;
        color: $gray-color-2;
      }
      &:hover {
        background-color: #fff2e6 !important;
      }
    }
  }
}

.detail-placeholder {
  height: 600px;
}

// style modal
.ant-modal {
  .checkout-title {
    color: $gray-color-3;
    font-size: 24px;
    font-family: $font-primary;
    @include up-to-size('sm') {
      font-size: 24px;
    }
  }
  .collection-text {
    color: $secondary_color;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bold-text {
    font-size: $fs-text;
    font-family: $font-primary;
    @include up-to-size('sm') {
      font-size: 14px;
    }
  }
  .light-text {
    font-size: $fs-text !important;
    font-weight: 400 !important;
    @include up-to-size('sm') {
      font-size: 14px !important;
    }
  }

  .checkout-detail {
    margin: 20px 0;
    .checkout-detail__header {
      display: flex;
      justify-content: space-between;
    }

    & > * {
      // border-bottom: 1px solid $border_color;
      padding: 10px 0;
      // align-items: center;
      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        color: $secondary_color;
        @include up-to-size('sm') {
          font-size: 9px;
        }

        & > :first-child {
          font-size: $fs-text;
          font-family: $font-primary;
          color: $primary-color;
          @include up-to-size('sm') {
            font-size: 14px;
          }
        }
      }
    }

    & > :first-child {
      padding: 8px 0;
      @include up-to-size('sm') {
        & > :nth-child(2) {
          font-size: 14px;
          font-family: $font-primary;
        }
      }
    }
    & > :last-child {
      padding: 15px 0;
      border-bottom: none;
    }
    .asset-img {
      border-radius: 8px;
      height: 80px;
      margin-right: 20px;
    }

    .loading-img-placeholder {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nft-info {
      display: flex;
      max-width: 70%;
      align-items: flex-start;
      .asset-img {
        max-width: 110px;
        margin: auto 10px auto 0;
        height: auto;
        @media screen and (max-width: $breakpoint-mobile) {
          max-width: 100px;
        }
      }
      .nft-info-name {
        max-width: 70%;
      }
      & > :nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 80%;
        & > :nth-child(2) {
          margin-top: 5px;
          font-family: $font-primary;
          font-size: $fs-text;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .nft-info-mobile {
      display: flex;
      max-width: 100%;
      .asset-img {
        max-width: 110px;
        margin: auto 10px auto 0;
        height: auto;
        @media screen and (max-width: $breakpoint-mobile) {
          max-width: 90px;
        }
      }

      & > :nth-child(2) {
        flex: 1;
        display: flex;
        align-items: flex-start;
        & > :first-child {
          width: 100px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & > :nth-child(2) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          & > :first-child {
            font-size: 13px;
            width: 60%;
          }
          & > :nth-child(2) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-flow: row wrap;
            width: max-content;
            overflow: visible;
            font-size: 14px;
            & > :first-child {
              font-size: 13px;
            }
            & > :nth-child(2) {
              color: $primary_color;
              margin-left: 4px;
            }
          }
        }
      }
    }
    .term-of-service {
      display: flex;
      align-items: center;
      @include up-to-size('sm') {
        align-items: flex-start;
      }
      .term-of-service-text {
        margin-left: 14px;
        & > :first-child {
          font-size: 15px;
          color: $secondary_color;
          @include up-to-size('sm') {
            font-size: 14px;
          }
        }
        & > :nth-child(2) {
          font-size: 16px;
          color: $primary-color;
          font-family: $font-primary;
          text-decoration: underline;
          margin-left: 8px;
          @include up-to-size('sm') {
            font-size: 14px;
          }
        }
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary_color;
      border-color: $primary_color;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      & > :nth-child(3),
      & > :nth-child(4) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .royalty-display {
    display: flex;
    align-items: center;
    .royalty {
      color: $primary_color;
      font-family: $font-primary;
      margin-right: 8px;
      font-size: 20px;
    }
    .text {
      color: $secondary_color;
      margin-right: 8px;
    }
  }
  .border-top {
    border-top: 1px solid $border_color;
    margin-top: 8px;
  }
  .ant-input {
    &.ant-input-custom {
      // TODO: update styling for inputs
      height: 45px;
      border-radius: 12px;
      // background-color: $bg_input;
      border: none;
      @include up-to-size('sm') {
        height: 40px;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid $color-gray-blue;
      }
    }
  }
  .label-input-form {
    margin-top: 17px;
    @include up-to-size('sm') {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
  .quantity-container {
    position: relative;
    flex-direction: row !important;
    align-items: flex-start !important;
    .quantity-input {
      margin-bottom: 0px;
      margin-right: 10px;
      input {
        min-width: 300px;
        padding-right: 65px;
        @media screen and (max-width: $breakpoint-m-mobile) {
          min-width: 250px;
          width: 100%;
        }
      }
    }
    .ant-btn-custom {
      position: absolute;
      right: 0;
      top: 0;
      height: 45px !important;
      border-radius: 0 12px 12px 0 !important;
      width: 93px !important;
      background-color: $main-color!important;
      @include up-to-size('sm') {
        height: 42px !important;
      }
    }
  }

  .price-container {
    flex-direction: row !important;
    align-items: flex-start !important;
    position: relative;
    .select-currency {
      position: absolute;
      right: 0;
      top: 0;
      .ant-select-selector {
        max-height: 45px !important;
      }
    }
    .price-input {
      margin-bottom: 0px;
      margin-right: 10px;
      input {
        min-width: 300px;
        padding-right: 95px;
        @media screen and (max-width: $breakpoint-m-mobile) {
          min-width: 250px;
          width: 100%;
        }
      }
    }
    .select-currency {
      margin-bottom: 0px;
    }
    .ant-select-custom {
      .ant-select-selector {
        border: none !important;
        width: 93px;
        border-radius: 0 12px 12px 0 !important;
        background-color: $primary_color;
        color: white;
        padding: 0 15px !important;
        @include up-to-size('sm') {
          height: 40px !important;
        }
      }
      .ant-select-selection-item {
        padding-right: 0;
        color: white;
      }
      .ant-select-arrow {
        color: white;
      }
    }
  }

  .cancel-bid-modal {
    p {
      margin: 0px;
    }
    & > :first-child {
      margin-bottom: 24px;
      line-height: 1.3;
      font-size: $fs-title-modal;
      color: $gray-color-3;
      font-family: $font-primary;
    }
    & > :nth-child(2) {
      margin-bottom: 16px;
    }
    .ant-btn-loading-icon {
      margin-right: 5px;
    }
  }
  .bid-success-modal {
    p {
      margin: 0px;
    }
    & > :first-child {
      margin-bottom: 20px;
      font-size: $fs-title-modal;
      color: $gray-color-3;
      font-family: $font-primary;
      @include up-to-size('md') {
        font-size: 24px;
      }
    }
    & > :nth-child(2) {
      font-size: $fs-text;
      color: $secondary_color;
      margin-bottom: 20px;
    }
    & > :nth-child(3) {
      margin-bottom: 16px;
    }
  }
}
