@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.cancel-sale {
    p {
        margin: 0px;
    }
    & > :first-child {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 1.3;
        color: $gray-color-3;
        font-family: $font-primary;
        @include up-to-size('sm') {
            font-size: 24px;
        }
    }
    .cancel-fixed-price-text {
        font-size: $fs-text;
        color: $secondary_color;
        margin-bottom: 25px;
        @include up-to-size('sm') {
            font-size: 14px;
        }
    }
    .btn-never {
        margin-bottom: 16px;
    }
}
