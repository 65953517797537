@import 'styles/variables.scss';

.about-holder {
    display: flex;
    flex-direction: column;
    .text-holder {
        .video-holder {
            margin-top: 20px;
        }
    }

    .box-support-us {
        display: flex;
        .box-sp-form {
            width: 50%;
            margin-right: 130px;
            .box-sp-form-title {
                font-style: normal;
                font-weight: 400;
                font-size: 52px;
                line-height: 68px;
                text-transform: uppercase;
                color: #000;
            }
            .ant-input {
                border-top: none;
                border-right: none;
                border-left: none;
                border-bottom: 1px solid hsla(0, 0%, 80%, 0.8) !important;
                border-radius: unset;
                padding: 8px 12px;
                background-clip: padding-box;
                background-color: $primary_background_color;
            }
            input::placeholder,
            textarea::placeholder {
                font-weight: bold;
                opacity: 0.6;
                line-height: 1.5;
                color: #212529;
            }
            input:focus {
                border: none;
                box-shadow: none;
                -webkit-box-shadow: none;
            }
            textarea {
                border-bottom: 1px solid hsla(0, 0%, 80%, 0.8) !important;
                border-radius: unset;
                min-height: 100px;
                line-height: 1.5715;
            }
            textarea:focus {
                border: none;
                box-shadow: none;
                -webkit-box-shadow: none;
            }
            button {
                margin-top: 10px;
                border-radius: 10px;
                width: 100%;
                color: $white;
                background: #EE811A !important;
                font-size: 14px;
                font-weight: 400;
                border: none;
                padding: 6px 12px;
                height: 50px;
            }
        }
    }
    .box-info-us {
        .info-us {
            display: flex;
        }

        .text-light {
            color: $primary_color;
            font-style: normal;
            color: #182f43;
        }
        .title-info-us {
            font-weight: 700;
            font-size: 28px;
            line-height: 44px;
        }
        .des-info-us,
        .info2 {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
        }
        .box-info {
            margin-left: 10px;
            .info1 {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}
