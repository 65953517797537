@import 'styles/variables.scss';

@media screen and (max-width: $breakpoint-lg-tablet) {
    .create-nft-header {
        width: auto;
    }
    
    .create-nft-container {
        padding: 16px;
        .create-nft-form_wrapper {
            flex-direction: column;

            .create-nft-form {
                padding: 0;
            }

            .create-nft-form_preview__wrap {
                display: none;
            }

            .create-nft-minimumBid-col {
                flex-direction: column;
                .create-nft-startDate {
                    max-width: 100% !important;
                    margin-right: 0 !important;
                }
                .create-nft-expireDate {
                    max-width: 100% !important;
                }
                .expireDate_label {
                    margin-top: 32px;
                }
                .expireDate_label > * {
                    margin-left: 0px !important;
                }
            }

            .create-nft-form_preview {
                margin: auto;
            }
        }
    }
}
