@import 'styles/variables.scss';

.create-nft-collections {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 16px;

  .create-nft-collections_item {
    width: calc((100% - 2 * 16px) / 3);
    min-width: 200px;
    height: 180px;
    border: 1px solid $bg_input;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;

    &-bg {
      border-color: $bg_input;
      background-color: white;
    }
    &.active {
      border: 1px solid $main-color !important;
    }

    .create-nft-collections_item__icon {
      object-fit: cover;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      i {
        font-size: 2.2rem;
        color: $gray-color-3;
      }
    }

    .verify-icon {
      position: absolute;
      right: 36%;
      top: 39%;
      height: 30px;
      width: 30px;
    }

    .create-nft-collections_item__title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      color: $gray-color-3;
      max-width: 100px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .new-collection-title {
      color: $gray-color-2;
      font-weight: 400;
      max-width: unset;
    }
  }
}

#dark-theme .create-nft-collections {
  .create-nft-collections_item {
    background: #4c505c;
    color: white;
    .create-nft-collections_item__title {
      color: $white-text;
    }

    .create-nft-collections_item__icon {
      background-color: rgb(48, 51, 57);
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .create-nft-collections_item {
    width: 100% !important;
  }
}
