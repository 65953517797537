@import 'styles/variables.scss';
@import 'styles/common.scss';

.dark-details-tab {
    &__container {
        background: $primary_background_color_dark;
        .contract-info {
            .contract-title {
                color: white;
            }
            .contract-value {
                color: $white-text !important;
            }
        }
    }
}

.details-tab {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        >span {
            font-size: 20px;
            font-weight: 600;
            color: $gray-color-3;
        }
        border-radius: 28px;
        padding: 32px 28px;
        background: white;
        border: 1px solid $gray-color;
        @extend %border;
        @media screen and (max-width: 576px) {
            padding: 24px;
        }
        .contract-info {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: 50%;
            @media screen and (max-width: 1200px) {
                max-width: 40%;
            }
            @media screen and (max-width: 975px) {
                max-width: 55%;
            }
            @media screen and (max-width: 768px) {
                .d-flex {
                    flex-direction: column;
                    max-width: 100%;
                }
                max-width: 100%;
            }
            .contract-title {
                white-space: nowrap;
                color: $secondary_color;
                font-size: 16px;
                font-weight: 500;
                min-width: 240px;
            }
            .contract-value {
                color: $gray-color-3;
                font-size: 16px;
                font-family: $font-primary;
            }
        }
    }
    &__owner {
        & > :first-child {
            font-size: 14px;
            font-weight: 600;
            color: $secondary_color;
            margin-bottom: 16px;
        }
        & > :nth-child(2) {
            font-size: 18px;
            font-family: $font-primary;
            color: $primary_color;
            display: flex;
            align-items: center;
            & > :first-child {
                margin-right: 16px;
            }
            & > :nth-child(2) {
                max-width: 500px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            a {
                color: $primary_color;
            }
        }
    }
    &__properties {
        & > :first-child {
            font-size: 14px;
            font-weight: 600;
            color: $secondary_color;
        }
    }
    &__property {
        padding: 16px 24px;
        border-radius: 8px;
        border: 1px solid $border_color;
        width: max-content;
        max-width: 600px;
        margin: 16px 16px 10px 0px;
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        @media screen and (max-width: 992px) {
            max-width: 500px;
        }
        @media screen and (max-width: 768px) {
            max-width: 400px;
        }
        @media screen and (max-width: 576px) {
            max-width: 300px;
        }
        & > :first-child {
            font-size: 16px;
            font-weight: 500;
            color: $secondary_color;
            margin-bottom: 2px;
        }
        & > :nth-child(2) {
            font-size: 18px;
            font-family: $font-primary;
            color: $primary_color;
        }
    }
}
