@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.offers {
  &__loading {
    margin: auto;
  }
  &__container {
    color: $primary_color;
    display: flex;
    flex-direction: column;
    gap: 36px;
    .user-info {
      align-items: center;
      gap: 8px;
      .avatar {
        position: relative;
        .icon {
          position: absolute;
          top: 50%;
          right: -2px;
          z-index: 1;
        }
      }
    }
  }
  &__no-item {
    text-align: center;
    p {
      font-size: 17px;
      font-family: $font-primary;
      margin-top: 22px;
    }
    img {
      height: 90px;
      margin: 0 0 20px 11px;
    }
  }
  &__table {
    border-radius: 28px;
    background-color: white;
    border: 1px solid $gray-color;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 28px;
    & > * {
      @include up-to-size('xl') {
        padding-left: 24px;
      }
      background: $white;
      & > * {
        width: 12%;
        display: flex;
        align-items: center;
        @include up-to-size('xl') {
          width: 15%;
        }
        a {
          width: calc(100%);
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      a {
        color: $gray-color-3;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ant-row:first-child {
      padding-bottom: 16px;
      font-weight: 500;
      color: $gray-color-2;
    }
    .ant-row:not(:first-child) {
      border-top: 1px solid $border_color;
      > .ant-col:first-child {
        padding-left: 16px;
      }
      padding-block: 16px;
      color: $gray-color-3;
      font-weight: 500;
      .action-col {
        display: flex;
        gap: 10px;
      }
    }
  }
  &__table-mobile {
    margin-bottom: 48px;
    & > :last-child {
      border-radius: 0px 0px 8px 8px;
    }
    & > :first-child {
      border-radius: 8px 8px 0px 0px;
    }
    & > * {
      border-bottom: 1px solid $border_color;
      padding: 24px;
      background: $white;
      a {
        color: $primary_color;
      }
      & > * {
        margin-bottom: 20px;
        & > :first-child {
          font-family: $font-primary;
          font-size: 14px;
        }
      }
      & > :last-child {
        margin-bottom: 0px;
      }
    }
  }
  &__buttons {
    height: 36px !important;
    width: 105px !important;
    margin-left: auto;
    & > :first-child {
      margin-right: 4px;
      font-size: 14px;
    }
  }
}

.dark-offers {
  &__no-item {
    color: $white-text;
  }
}
