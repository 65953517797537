@import "styles/variables.scss";
@import "styles/mixins.scss";

.homepage-explore__header {
  h2 {
    margin-bottom: 0 !important;
  }
  @media screen and (max-width: 490px) {
    display: unset !important;
  }
}

#dark-theme .homepage-explore__header {
  h2 {
    color: $white-text;
  }

  .select {
    .ant-select {
      .ant-select-selector {
        ant-select-selection-item {
          color: $white;
        }

        .ant-select-arrow {
          color: $white;
        }
      }
    }
  }
}

.home-explore {
  .custom-dropdown {
    width: fit-content !important;
    z-index: 999 !important;

    .dropdown-explorer {
      padding: 16px;
      // max-width: 220px;
      background-color: $white;
      color: rgb(4, 4, 5);
      border-radius: 12px;
      font-weight: normal;
      outline: 0px;
      box-shadow: rgba(4, 4, 5, 0.2) 0px 7px 36px -8px;
      min-width: 180px;
      .ant-menu {
        border-right: none !important;
      }
      .sale-type-item {
        cursor: pointer;
        color: rgb(4, 4, 5);
        transition: all 0.12s ease-in-out 0s;
        text-align: left;
        min-height: 35px;
        padding: 12px 16px;
        margin: 0px 12px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          background-color: #e6f7ff;
        }
      }
      .sale-type-filter-button {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        padding: 12px 0 0 0;
        justify-content: space-between;
      }
      .filter-btn {
        background-color: #ebeeef;
      }
      #selected {
        background-color: #e6f7ff !important;
      }
      .ant-menu-item-selected {
        background-color: transparent !important;
      }
      .ant-dropdown-menu {
        box-shadow: none;
      }
    }
  }
}

.btn-filter {
  border-radius: 18px !important;
  padding: 0 15px !important;
  color: $primary_color !important;
  display: flex !important;
  align-items: center !important;
  font-family: "circular";
  font-weight: 500 !important;
  box-shadow: $box_shadow !important;
  &-custom {
    width: fit-content;
    padding-right: 0;
    font-size: 14px !important;
    min-width: 130px !important;
    padding: 0 9px !important;
    svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 5px;
      vertical-align: middle;
      margin-top: -1px;
    }
    .ant-select-selection-item {
      padding-right: 0 !important;
    }
    & > span {
      line-height: 15px;
    }
  }
  & > span {
    padding-right: 15px;
  }

  & svg {
    width: 10.2px;
    height: 10.2px;
    path {
      fill: #474873;
    }
  }

  &:hover {
    border: 1px solid $video-background !important;
    span {
      background: linear-gradient(
        95.32deg,
        #b809b5 -7.25%,
        #ed1c51 54.2%,
        #ffb000 113.13%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
  }
}

.btn-filter:hover {
  span {
    background: linear-gradient(
      95.32deg,
      #b809b5 -7.25%,
      #ed1c51 54.2%,
      #ffb000 113.13%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
}

.homepage-explore-filter-by,
.homepage-explore-sort-by {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
  .ant-select.ant-select-custom {
    min-width: 150px;
    .ant-select-selector {
      height: 36px !important;
    }
    .ant-select-selection-item {
      @include text-small-medium;
    }
  }
  .ant-btn {
    height: 35px !important;
    // border: 1px solid #d9d9d9;
    span {
      padding-right: 0;
    }
  }
  .select-placeholder {
    display: flex;
    align-items: center;
    gap: 8px;
    @include text-small-medium;
  }
  button {
    @media screen and (max-width: 578px) {
      width: fit-content !important;
      padding: 0 10px !important;
      span {
        padding: 0;
      }
      svg {
        margin-right: 0;
      }
    }
  }
}

#dark-theme .homepage-explore-filter-by,
#dark-theme .homepage-explore-sort-by {
  .ant-btn {
    svg {
      path {
        fill: $white-text;
      }
    }
  }
  .ant-select.ant-select-custom {
  }
}

.homepage-explore-filter-by {
  // max-width: calc(100% - 165px);
  // overflow-x: auto;
  position: relative;
  @media screen and (max-width: 1056px) {
    padding-bottom: 5px;
    margin-top: 11px;
  }
  @media screen and (max-width: 578px) {
    max-width: calc(100% - 50px);
  }
  &::-webkit-scrollbar {
    position: absolute;
    bottom: 0;
    height: 6px;
    margin-top: 10px;
    transition: all 0.25s ease;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
    height: 4px;
    width: 4px;
    margin: auto;
    opacity: 0;
  }
  &::-webkit-resizer {
    height: 4px;
    width: 4px;
  }
  &:hover ::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  .ant-select-dropdown {
    &.select__dropdown--chains {
      width: 188px !important;
    }

    &.select__dropdown--categories {
      min-width: 210px !important;
    }

    &.select__dropdown--price-range {
      width: 270px !important;

      .pricerange-header {
        @include text-normal-bold;
        color: $gray-color-3;
        width: 100%;
      }
    }

    &.select__dropdown--collections {
      width: 205px !important;

      .collection-holder {
        margin-right: -16px;
      }
    }

    &.select__dropdown--filter {
      width: 220px !important;
    }

    &.select__dropdown--properties {
      width: 205px !important;
    }
  }
}

.homepage-explore-btn-load {
  margin: auto;
  margin-top: 50px;
}

.dropdown-explore-dark-antd {
  background-color: rgb(48, 51, 57) !important;
  span {
    color: #fff !important;
  }

  .ant-dropdown-menu-item:hover {
    background-color: rgb(63, 64, 66) !important;
  }

  .verification-holder {
    color: #fff;
  }
  .collection-holder {
    .property-filter-modal_item {
      color: #fff;
      cursor: pointer;
    }
  }
  .btn-clear-dark {
    background: #000000d9 !important;
    color: $white !important;
  }
  .ant-btn:hover {
    color: $white;
    opacity: 0.9;
  }
}

.dropdown-explore-dark-range {
  background-color: rgb(48, 51, 57) !important;
  padding: 15px !important;
  ul {
    color: #fff !important;
    background-color: rgb(48, 51, 57) !important;
  }
  input {
    background-color: rgb(48, 51, 57);
    margin-top: 5px;
    color: $white-text !important;
  }

  input:focus {
    background-color: rgb(55, 58, 63);
    border: 1px solid rgba(163, 174, 208, 0.8) !important;
    color: #fff !important;
  }

  .ant-dropdown-menu-item:hover {
    background-color: rgb(63, 64, 66) !important;
  }
  .btn-clear-dark {
    background: #000000d9 !important;
    color: $white !important;
  }
  .ant-btn:hover {
    color: $white;
    opacity: 0.9;
  }
}

.dropdown-explore-light-range {
  padding: 15px !important;
}

.btn-filter-custom-dark {
  background-color: $border_color_dark !important;
  color: $secondary_background_color !important;
  border: 1px solid #525252 !important;
}

@media screen and (max-width: $breakpoint-lg-tablet) {
  .homepage-explore-filter-by {
    flex-wrap: wrap;
  }
  .homepage-explore__header {
    flex-direction: column;
    align-items: flex-start!important;
  }
}
