@import '../../../styles/mixins.scss';
@import '../../../styles/variables.scss';

.radio-group-custom {
  .ant-radio-wrapper {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    span.ant-radio + * {
      @include text-small-regular;
      color: $gray-color-3;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .ant-radio {
      width: 20px;
      height: 20px;
      
      .ant-radio-input {
        border: 1.5px solid #F5F5F5;
        height: 100%;
        width: 100%;

        &:focus + .ant-radio-inner {
          box-shadow: none;
        }
      }

      .ant-radio-inner {
        border: 1.5px solid #F5F5F5;
        height: 100%;
        width: 100%;

        &::after {
          background-color: $main-color;
          width: 10px;
          height: 10px;
          top: 4px;
          left: 4px;
        }
      }

      &-checked {
        &:after {
          content: none;
        }

        .ant-radio-inner {
          border: 1.5px solid $main-color;

          &::after {
            background-color: $main-color;
            width: 10px;
            height: 10px;
            top: 4px;
            left: 4px;
          }
        }
      }
    }
  }
}