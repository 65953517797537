@import 'styles/variables.scss';

.search-page-container {
    margin: 40px 0 100px 0;
}
.search-page-container {
    .dark-search-page-content {
        .ant-tabs-tab.ant-tabs-tab-active {
            background-color: white !important;
            .ant-tabs-tab-btn {
                color: black;
            }
        }
        .ant-tabs-tab {
            background-color: $primary_background_color_dark;
            color: $white-text;
        }
    }
}

.w-100 {
    width: 100%;
}

.btn-load-more {
    margin-top: 40px;
}

#dark-theme .layout-body {
    .container {
        .search-page-container {
            .search-page-header {
                span {
                    color: $white-text;
                }
            }
        }
    }
}

.search-page-header {
    margin-bottom: 24px;
    .search-page-title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: $gray-color-3;
    }
}

.no-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    & > img {
        width: 90px;
        height: 90px;
    }
    p:first-of-type {
        margin-top: 22px;
        font-size: 18px;
        font-family: $font-primary;
        margin-bottom: 0;
    }
    p:nth-of-type(2) {
        color: $third_color;
        font-size: 14px;
        font-family: $font-secondary;
        margin-top: 8px;
        margin-bottom: 0;
    }
    .btn-browse {
        margin: 0 auto;
        margin-top: 32px;
        width: 217px;
        height: 50px;
    }
}

.search-groups-container {
    margin-top: 24px;
    .ant-card.card-item-custom {
        width: 100%;
    }
    .ant-card.card-user-custom {
        width: 100%;
        .ant-card-body {
            width: 100%;
        }
    }
    .ant-card.card-collection-custom {
        width: 100%;
        .ant-card-body {
            width: 100%;
        }
    }
}
