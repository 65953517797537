@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.dropdown-explorer {
  .collection-holder {
    .collection-item-holder {
      .ri-check-line {
        color: black !important;
      }
    }
  }
}

.dropdown-explore-dark-antd {
  .collection-holder {
    .collection-item-holder {
      .ri-check-line {
        color: white !important;
      }
    }
  }
}

.collection-list {
  &__content {
    &__header {
      :not(:first-child, :last-child) {
        text-align: right;
      }
    }
    &__body .ant-row {
      :not(:first-child, :last-child) {
        text-align: right;
      }
    }
  }
  &__tabs .ant-tabs-tab .ant-tabs-tab-btn{
    color: $gray-color-3;
  }
  &__content-mobile {
    & > * {
      border-bottom: 1px solid $border_color;
      padding: 24px;
      background: $white;
      a {
        color: $primary_color;
      }
      & > * {
        margin-bottom: 20px;
        & > :first-child {
          font-family: $font-primary;
          font-size: 14px;
        }
      }
      & > :last-child {
        margin-bottom: 0px;
      }
    }
    .owner-avatar {
      padding-left: 16px;
      display: flex;
      align-items: center;
      gap: 4px;
      gap: 14px;

      .avatar-holder {
        position: relative;

        .verify-icon {
          position: absolute;
          top: 32px;
          right: 0;
        }
      }
    }
    .change {
      &--up {
        color: #67b629;
      }

      &--down {
        color: #952623;
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg-tablet) {
    * {
      font-size: 14px;
    }
    &__tabs {
      flex-direction: column;
      align-items: flex-start!important;
      gap: 8px;
      .ant-tabs-tab {
        padding: 2px 20px!important;
      }
    }
  }
  @media screen and (max-width: $breakpoint-mobile) {
    & {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}
