// @import 'styles/variables.scss';

// .connect-waller__container {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     background-image: url('/assets/images/effect.svg');
//     background-size: 100%;
//     background-repeat: no-repeat;
//     .nav-link {
//         width: 80%;
//         margin-top: 40px;
//         button {
//             height: 34px;
//             width: 130px;
//             &:hover {
//                 opacity: 1;
//             }
//         }
//         a {
//             color: $primary_color;
//             font-size: 15px;
//         }
//     }
//     .main {
//         width: 75%;
//         height: 780px;
//         border-radius: 16px;
//         background-color: transparent;
//         padding: 72px;
//         text-align: center;
//         margin-bottom: 40px;
//         .logo {
//             width: 200px;
//         }
//         .nav-link {
//             display: flex;
//             a {
//                 color: $third_color;
//                 font-family: $font-primary;
//                 font-size: 18px;
//                 line-height: 27px;
//             }
//         }

//         .network-tabs {
//             margin-top: 20px;
//             img {
//                 width: 20px;
//                 height: 20px;
//                 object-fit: contain;
//                 margin-right: 5px;
//             }
//             .label {
//                 display: flex;
//                 align-items: center;
//                 margin-bottom: 0;
//             }

//             .ant-tabs-tab-btn {
//                 display: flex;
//                 align-items: center;
//             }

//             .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//                 .label {
//                     color: white !important;
//                     font-weight: 500;
//                 }
//             }
//         }

//         .dark-network-tabs {
//             .ant-tabs-nav {
//                 .ant-tabs-nav-wrap {
//                     .ant-tabs-tab.ant-tabs-tab-active {
//                         background-color: white;
//                         span {
//                             color: black;
//                         }
//                     }
//                     .ant-tabs-tab {
//                         background-color: $primary_background_color_dark;
//                         span {
//                             color: white;
//                         }
//                     }
//                 }
//             }
//         }

//         .connect-wrapper {
//             display: flex;
//             justify-content: center;

//             .wallet-connect {
//                 margin-left: 20px;

//                 .wallet-connect__apps {
//                     display: flex;
//                     justify-content: center;
//                     .icon {
//                         margin: 0 18px 0;
//                     }
//                 }
//             }
//         }
//         .title {
//             font-family: $font-primary;
//             font-size: $fs-title-box;
//             line-height: 54px;
//             margin-top: 10px;
//             color: $primary_color;
//             margin-bottom: 0.8rem;
//         }

//         .description {
//             font-size: 16px;
//             line-height: 150%;
//             color: $third_color;
//             margin-bottom: 24px;
//         }
//         .walletQA {
//             color: $primary_color;
//             font-weight: bold;
//             cursor: pointer;
//             font-size: 16px;
//         }

//         .connect-option {
//             margin-top: 20px;
//             width: 33.3%;
//             background: $secondary_background_color;
//             border: solid 1px $color-gray-blue;
//             padding: 20px;
//             border-radius: 20px;
//             box-shadow: 0px 17px 13px 0px #192c4b05;
//             display: block;
//             padding-left: 23px;
//             padding-right: 23px;
//             cursor: pointer;
//             position: relative;
//             overflow: hidden;
//             transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
//             display: flex;
//             flex-direction: column;

//             &:hover {
//                 transform: translateY(-15px) !important;
//             }

//             .icon {
//                 margin: 0 auto;
//             }

//             .title-wallet {
//                 margin-top: 20px;
//                 font-family: $font-primary;
//                 font-size: 16px;
//                 color: $primary_color;
//                 line-height: 24px;
//             }
//             .description-wallet {
//                 font-size: 17px;
//                 color: $third_color;
//                 line-height: 1.6;
//                 margin-top: 1rem;
//             }
//         }

//         .connect-waller__desc__wrap {
//             margin-top: 20px;
//         }

//         .connect-waller__desc {
//             font-size: 12px;
//             line-height: 150%;
//             color: $third_color;
//             margin-top: 8px;
//         }

//         .connect-waller__popular {
//             position: absolute;
//             background: $primary_color;
//             transform: rotate(30deg);
//             height: 25px;
//             width: 150px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             right: -35px;
//             top: 14px;
//             & > span {
//                 font-weight: bold;
//                 font-size: 10px;
//                 line-height: 150%;
//                 color: $white;
//             }
//         }
//     }

//     .connect-waller_backIcon {
//         margin-right: 10px;
//     }
//     @media screen and (max-width: 1024px) {
//         .nav-link {
//             left: 20px;
//         }
//     }
//     @media screen and (max-width: 992px) {
//         .main {
//             width: 750px;
//         }
//     }
//     @media screen and (max-width: 768px) {
//         .main {
//             width: 100%;
//             padding: 40px;
//         }
//     }
//     @media screen and (max-width: 576px) {
//         .main {
//             width: 100%;
//             padding: 32px 24px 40px 24px;
//             margin: 80px 0;
//             height: auto;
//             .nav-link {
//                 a {
//                     font-size: 14px;
//                     .connect-waller_backIcon {
//                         width: 20px;
//                     }
//                 }
//             }
//             .connect-wrapper {
//                 display: unset;
//                 .connect-option {
//                     width: 100%;
//                     margin-left: 0;
//                 }
//                 .wallet-connect {
//                     .wallet-connect__apps {
//                         .icon {
//                             margin-right: 12px;
//                         }
//                     }
//                 }
//             }
//             .title {
//                 font-size: 24px;
//             }

//             .connect-waller__desc__wrap {
//                 margin-top: 40px;
//             }

//             .connect-waller__desc {
//                 font-size: 10px;
//             }

//             .connect-waller__popular {
//                 transform: rotate(41deg);
//                 height: 20px;
//                 width: 157px;
//                 right: -56px;
//                 top: 10px;
//                 & > span {
//                     font-size: 7px;
//                     line-height: 150%;
//                 }
//             }
//         }
//     }
// }

// #dark-theme .connect-waller__container {
//     .main {
//         .description {
//             color: $white-text;
//         }
//         .title,
//         .walletQA {
//             color: $white;        }

//         .connect-option {
//             background-color: rgb(48, 51, 57);
//             border: none;

//             .title-wallet {
//                 color: white;
//             }
//         }
//     }
// }

// @media screen and (max-width: $breakpoint-xxl) {
//     .connect-waller__container {
//         .nav-link {
//             width: 90%;
//         }
//         .main {
//             margin-top: 10px;
//         }
//     }
// }

// @media screen and (max-width: $breakpoint-desktop) {
//     .connect-waller__container {
//         .nav-link {
//             width: 95%;
//         }
//     }
// }

// .wallet-info {
//     max-width: 420px !important;
//     .ant-modal-body {
//         margin-top: -50px;
//         min-height: 280px;
//         h1 {
//             color: $primary_color;
//             font-weight: bold;
//         }
//         p {
//             color: $third_color;
//         }
//     }
//     @media screen and (max-width: 576px) {
//         width: 343px !important;
//         .ant-modal-content {
//             width: 343px !important;
//         }

//         .ant-modal-header {
//             max-width: 100% !important;
//         }
//         .ant-modal-body {
//             max-width: 343px !important;
//             margin-top: -50px !important;
//             h1 {
//                 font-size: 24px;
//             }
//         }
//     }
// }

@import 'styles/variables.scss';

.connect-waller__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/images/effect.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    .nav-link {
        width: 80%;
        margin-top: 40px;
        button {
            height: 34px;
            width: 130px;
            &:hover {
                opacity: 1;
            }
        }
        a {
            color: $primary_color;
            font-size: 15px;
        }
    }
    .main {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        // height: 780px;
        border-radius: 16px;
        background-color: transparent;
        // padding: 72px;
        text-align: center;
        // margin-bottom: 40px;
        .connect-content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
            align-items: center;
            justify-content: center;
        }
        .logo {
            width: 200px;
        }
        .nav-link {
            display: flex;
            a {
                color: $third_color;
                font-family: $font-primary;
                font-size: 18px;
                line-height: 27px;
            }
        }

        .network-tabs {
            margin-top: 20px;
            img {
                width: 30px;
                height: 30px;
                object-fit: contain;
                margin-right: 5px;
            }
            .label {
                display: flex;
                align-items: center;
                margin-bottom: 0;
                > span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            // .ant-tabs-tab-btn {
            //     display: flex;
            //     align-items: center;
            // }

            // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            //     .label {
            //         color: white !important;
            //         font-weight: 500;
            //     }
            // }
        }

        .dark-network-tabs {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap {
                    .ant-tabs-tab.ant-tabs-tab-active {
                        background-color: white;
                        span {
                            color: black;
                        }
                    }
                    .ant-tabs-tab {
                        background-color: $primary_background_color_dark;
                        margin: 0 8px;
                        span {
                            color: white;
                        }
                    }
                }
            }
        }

        .connect-wrapper {
            display: flex;
            justify-content: center;
            padding-inline: 30px;
            gap: 24px;
            >div {
                width: 100%;
            }

            .wallet-connect {

                .wallet-connect__apps {
                    display: flex;
                    justify-content: center;
                    .icon {
                        margin: 0 18px 0;
                    }
                }
            }
        }
        .title {
            font-family: $font-primary;
            font-size: 24px;
            line-height: 38px;
            color: $gray-color-3;
            font-weight: 700;
        }

        .description {
            flex-grow: 1;
            font-size: 16px;
            line-height: 150%;
            color: $gray-color-2;
            margin: 0;
            max-width: fit-content;
        }
        .walletQA {
            font-size: 16px;
            color: $gray-color-2;
            // line-height: 150%;
            // color: $gray-color-2;
            // border-bottom: 1px solid $gray-color-2;
            cursor: pointer;
        }
        .walletQA:hover {
            opacity: 0.8;
        }

        .connect-option {
            margin-top: 20px;
            background: $secondary_background_color;
            border: solid 1px $color-gray-blue;
            padding: 20px;
            border-radius: 20px;
            box-shadow: 0px 17px 13px 0px #192c4b05;
            display: block;
            padding-left: 23px;
            padding-right: 23px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
            display: flex;
            flex-direction: column;

            &:hover {
                transform: translateY(-15px) !important;
            }

            .icon {
                margin: 0 auto;
                max-width: 80px;
                max-height: 80px;
            }

            .title-wallet {
                margin-top: 24px;
                font-size: 20px;
                color: $gray-color-3;
                font-weight: 600;
                line-height: 28px;
            }
            .description-wallet {
                font-size: 16px;
                color: $gray-color-2;
                line-height: 24px;
                margin-top: 16px;
            }
        }

        .connect-waller__desc__wrap {
            margin-top: 20px;
        }

        .connect-waller__desc {
            font-size: 12px;
            line-height: 150%;
            color: $third_color;
            margin-top: 8px;
        }

        .connect-waller__popular {
            position: absolute;
            background: $primary_color;
            transform: rotate(30deg);
            height: 25px;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            right: -35px;
            top: 14px;
            & > span {
                font-weight: bold;
                font-size: 10px;
                line-height: 150%;
                color: $white;
            }
        }
    }

    .connect-waller_backIcon {
        margin-right: 10px;
    }
    @media screen and (max-width: 1024px) {
        .nav-link {
            left: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        .main {
            width: 100%;
            padding: 40px 0;
        }
    }
    @media screen and (max-width: $breakpoint-lg-tablet) {
        .connect-wrapper {
            flex-direction: column;
            gap: 24px;
        
        }
    }
    @media screen and (max-width: 576px) {
        .main {
            width: 100%;
            padding: 32px 0 40px;
            margin: 80px 0;
            height: auto;
            .nav-link {
                a {
                    font-size: 14px;
                    .connect-waller_backIcon {
                        width: 20px;
                    }
                }
            }
            .connect-wrapper {
                display: unset;
                .connect-option {
                    width: 100%;
                    margin-left: 0;
                }
                .wallet-connect {
                    .wallet-connect__apps {
                        .icon {
                            margin-right: 12px;
                        }
                    }
                }
            }
            .title {
                font-size: 24px;
            }

            .connect-waller__desc__wrap {
                margin-top: 40px;
            }

            .connect-waller__desc {
                font-size: 10px;
            }

            .connect-waller__popular {
                transform: rotate(41deg);
                height: 20px;
                width: 157px;
                right: -56px;
                top: 10px;
                & > span {
                    font-size: 7px;
                    line-height: 150%;
                }
            }
        }
    }
}

.ant-tabs {
    .ant-tabs-nav::before {
        position: relative;
        border: none;
    }
    .ant-tabs-tab {
        margin: 0 8px;
        width: auto;
        height: 46px;
        border-radius: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
            color: $gray-color-3;
        }
    }
    .ant-tabs-tab.ant-tabs-tab-active{
        font-weight: 500;
        background-color: rgba(238, 129, 26, 0.1);
        border: 1px solid rgba(238, 129, 26, 0.3);
    }
    .ant-tabs-tab-btn {
        display: flex;
        align-items: center;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        .label {
            color: $gray-color-3 !important;
            font-weight: 500;
        }
    }
}

#dark-theme .connect-waller__container {
    .main {
        .description {
            color: $white-text;
        }
        .title,
        .walletQA {
            color: $white;        }

        .connect-option {
            background-color: rgb(48, 51, 57);
            border: none;

            .title-wallet {
                color: white;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-xxl) {
    .connect-waller__container {
        .nav-link {
            width: 90%;
        }
        .main {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: $breakpoint-desktop) {
    .connect-waller__container {
        .nav-link {
            width: 95%;
        }
    }
}

.wallet-info {
    max-width: 420px !important;
    .ant-modal-body {
        margin-top: -50px;
        min-height: 280px;
        h1 {
            color: $gray-color-3;
            font-weight: bold;
        }
        p {
            color: $third_color;
        }
    }
    @media screen and (max-width: 576px) {
        width: 343px !important;
        .ant-modal-content {
            width: 343px !important;
        }

        .ant-modal-header {
            max-width: 100% !important;
        }
        .ant-modal-body {
            max-width: 343px !important;
            margin-top: -50px !important;
            h1 {
                font-size: 24px;
            }
        }
    }
}
