@import "styles/variables.scss";
@import "styles/mixins.scss";

// .blank-user-profile {
//     width: 100vw;
//     height: 68vh;
// }

// .profile-container {
//     width: 100%;
//     &_user-cover {
//         position: relative;
//         height: 280px;
//         width: 100%;
//         overflow: hidden;
//         .cover-picture {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//         }
//         .btn-edit {
//             position: absolute;
//             right: 40px;
//             bottom: -50px;
//             img {
//                 margin-right: 11px;
//             }
//             span {
//                 font-size: 16px;
//                 font-family: $font-primary;
//             }
//         }
//         &:hover {
//             .btn-edit {
//                 bottom: 5px;
//                 right: 0px;
//             }
//         }
//     }
//     .tabs-inner {
//         position: relative;
//     }
//     &_user-info {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background: #fff;
//         max-height: 100px;
//         position: relative;
//         z-index: 1;
//         .user-info-holder {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             width: 100%;
//             margin-top: 0 !important;
//             margin-bottom: 0 !important;
//             overflow-x: auto;
//             overflow-y: hidden;
//         }

//         .user-avatar {
//             display: flex;
//             align-items: center;

//             .color_text {
//                 color: $secondary_color;
//                 margin: 0;
//                 margin-left: 20px;
//             }

//             h5 {
//                 font-size: 15px;
//                 color: $primary_color;
//                 letter-spacing: -0.01em;
//                 margin-left: 20px;
//                 margin-top: 0;
//             }
//             .shrink-title {
//                 max-width: 150px;
//                 overflow: hidden;
//                 text-overflow: ellipsis;
//             }

//             .avatar-container {
//                 height: 7rem;
//                 width: 7rem;
//                 border-radius: 50%;
//                 position: relative;
//                 border: 4px solid white;
//                 img:first-of-type {
//                     width: 100%;
//                     height: 100%;
//                     border-radius: 50%;
//                     object-fit: cover;
//                 }
//                 .verify-icon {
//                     position: absolute;
//                     width: 28px;
//                     height: 28px;
//                     bottom: 0;
//                     right: 10px;
//                 }
//                 .avatar-default {
//                     transform: scale(1.15);
//                 }
//             }

//             .social-info-user {
//                 padding: 0 15px;
//                 width: 200px;
//                 .social-list {
//                     display: inline-block;
//                     position: relative;
//                     width: 100%;
//                     > span.ant-avatar {
//                         position: absolute;
//                         right: -15px;
//                         bottom: 2px;
//                     }
//                     .social-item {
//                         display: block;
//                     }
//                 }
//                 a {
//                     display: flex;
//                     align-items: center;
//                     font-size: 15px;
//                     font-weight: 500;

//                     i {
//                         margin-right: 5px;
//                         line-height: 24px;
//                     }
//                     svg {
//                         width: 21px;
//                         height: 21px;
//                         margin-right: 5px;
//                         path {
//                             fill: #1890ff;
//                         }
//                     }
//                 }
//                 .personal-site {
//                     line-height: 24px;
//                     margin-top: -4px;
//                     i {
//                         font-size: 21px;
//                     }
//                 }
//             }

//             .follow-me {
//                 padding-left: 15px;
//                 .btn-follow {
//                     width: 44px;
//                     height: 44px;
//                     font-weight: 600;
//                     color: $primary_color;
//                     border: 1px solid $color-gray-blue;
//                     i {
//                         margin-right: 0;
//                     }
//                 }
//             }

//             .about-description {
//                 color: $secondary_color;
//                 margin-left: 15px;
//                 display: -webkit-box;
//                 overflow: hidden;
//                 -webkit-box-orient: vertical;
//                 -webkit-line-clamp: 2;
//                 max-width: 180px;
//                 position: relative;
//                 word-break: break-all;
//                 .description-tooltip {
//                     position: absolute;
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             .user-name-address {
//                 color: $primary_color;
//                 font-family: $font-primary;
//                 padding-left: 20px;
//                 .name {
//                     margin-bottom: 24px;
//                     font-size: 24px;
//                     @media screen and (max-width: 576px) {
//                         display: block;
//                         white-space: nowrap;
//                         text-overflow: ellipsis;
//                         overflow: hidden;
//                         max-width: 550px;
//                         padding-left: 10px;
//                         padding-right: 10px;
//                     }
//                 }
//                 .customURL {
//                     max-width: 100%;
//                     margin-bottom: 8px;
//                     word-wrap: break-word;
//                 }
//                 span {
//                     margin-right: 24px;
//                 }
//                 .get-address-container {
//                     margin: 0;
//                     .btn-get-address {
//                         color: $primary_color;
//                     }
//                 }
//             }
//         }
//         .general-information {
//             text-align: center;
//             display: flex;
//             .follow-info {
//                 display: grid;
//                 font-weight: 600;
//             }
//         }
//         .reference-information {
//             span {
//                 color: black !important;
//             }
//         }
//         .get-address-container {
//             display: inline-block;
//             position: relative;
//             width: fit-content;
//             height: fit-content;
//             margin-top: 72px;
//             font-family: $font-primary;
//             .btn-get-address {
//                 display: inline-block;
//                 font-size: 14px;
//                 height: 36px;
//                 width: fit-content;
//             }
//         }

//         .user-info {
//             color: $primary_color;
//             padding-top: 16px;
//             .user-bio {
//                 max-width: 480px;
//                 color: $third_color;
//                 word-wrap: break-word;
//                 margin: 0 auto;
//                 font-family: $font-primary;
//                 button {
//                     border: none;
//                     background-color: inherit;
//                     color: $primary_color;
//                     font-family: $font-primary;
//                     text-decoration: underline;
//                     &:hover {
//                         cursor: pointer;
//                     }
//                 }
//             }
//             .user-social-link {
//                 padding-top: 18px;
//                 font-family: $font-primary;
//                 a {
//                     color: inherit;
//                     margin-left: 11px;
//                 }
//                 .twitter-link {
//                     display: inline-block;
//                     span {
//                         margin-right: 26px;
//                     }
//                 }
//                 .personal-site-link {
//                     display: inline-block;
//                 }
//             }
//         }

//         .dark-information {
//             .follow-btn {
//                 margin-left: 24px;
//                 .btn-unfollow,
//                 .btn-follow {
//                     span {
//                         color: black;
//                     }
//                 }
//             }
//         }

//         .information {
//             display: flex;
//             align-items: center;
//             .follow-btn {
//                 margin-left: 24px;
//                 .btn-unfollow,
//                 .btn-follow {
//                     margin-left: auto;
//                     margin-right: 8px;
//                     width: 100% !important;
//                     height: 100% !important;
//                     background-color: $primary_color;
//                 }
//             }
//             .profile-follow {
//                 color: $primary_color;
//                 padding-left: 15px;
//                 cursor: pointer;
//                 font-size: $fs-text;
//                 font-weight: 500;
//             }
//             .address-holder {
//                 padding: 10px 20px;
//                 border-radius: 400px;
//                 border: 1px solid $color-gray-blue;
//                 display: -webkit-box;
//                 display: -ms-flexbox;
//                 display: flex;
//                 -webkit-box-pack: justify;
//                 -ms-flex-pack: justify;
//                 justify-content: space-between;
//                 color: $secondary_color;
//                 display: flex;
//                 align-items: center;
//                 max-width: 206px;
//                 overflow: hidden;
//                 text-overflow: ellipsis;
//                 .copy-icon {
//                     padding-left: 10px;
//                     border-left: 1px solid $secondary_color;
//                 }
//                 span {
//                     max-width: 140px;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                     margin-right: 5px;
//                 }
//             }

//             div {
//                 display: inline-block;
//                 width: fit-content;
//             }
//             .button-holder {
//                 display: flex;
//                 flex-direction: row;
//                 .share-icon-container,
//                 .report-icon-container {
//                     color: #000000;
//                     margin-left: 24px;
//                     cursor: pointer;
//                     background: $secondary_background_color;
//                     height: 3rem;
//                     width: 3rem;
//                     -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
//                     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
//                     border: solid 1px $color-gray-blue;
//                     border-radius: 2000px;
//                     -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
//                     transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
//                     display: -webkit-box;
//                     display: -ms-flexbox;
//                     display: flex;
//                     -webkit-box-align: center;
//                     -ms-flex-align: center;
//                     align-items: center;
//                     -webkit-box-pack: center;
//                     -ms-flex-pack: center;
//                     justify-content: center;
//                     &:hover {
//                         box-shadow: none;
//                         background: $color-gray-blue;
//                     }
//                     img:hover {
//                         cursor: pointer;
//                     }
//                 }
//             }

//             .edit-profile-btn {
//                 margin-left: 24px;
//                 .btn-edit {
//                     width: 130px;
//                 }
//             }
//         }
//         .copied::before {
//             position: absolute;
//             content: 'Copied';
//             height: 100%;
//             width: 100%;
//             background-color: $third_color;
//             color: $primary_color;
//             border-radius: 8px;
//             z-index: 999;
//             opacity: 1;
//             left: 0;
//             top: 0;
//             padding: 7px;
//             pointer-events: none;
//         }
//     }
//     .line-filter {
//         height: 1px;
//         width: 100%;
//         background-color: rgba(163, 174, 208, 0.2);
//         position: relative;
//         &::before {
//             content: '';
//             position: absolute;
//             bottom: -50px;
//             left: 0;
//             right: 0;
//         }
//     }
//     &_tabs-container {
//         min-height: 552px;
//         .profile__sidebar {
//             margin-top: 36px;
//             .profile-card {
//                 background: $secondary_background_color;
//                 border: solid 1px $color-gray-blue;
//                 border-radius: 20px;
//                 -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
//                 box-shadow: 0px 17px 13px 0px #192c4b05;
//                 display: block;
//                 word-break: break-all;
//                 white-space: pre-wrap;
//                 li {
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                 }
//                 .social_profile {
//                     list-style-type: none;
//                     padding: 0;
//                 }
//             }
//         }

//         .tabs {
//             margin-top: 21px;
//             font-weight: 500;
//             color: $primary_color;
//             .tabs {
//                 margin-top: 20px;
//                 @include up-to-size('sm') {
//                     margin-top: 0;
//                 }

//                 .ant-tabs-nav-wrap {
//                     justify-content: flex-start !important;
//                 }

//                 .ant-tabs-nav {
//                     padding: 0 !important;
//                 }
//                 .ant-tabs-content-holder {
//                     padding: 0 !important;
//                     margin-top: 0 !important;
//                     margin-bottom: 24px;
//                 }
//             }
//             .ant-btn.btn-following,
//             .ant-btn.btn-followers {
//                 border: none;
//                 background-color: transparent !important;
//                 color: $primary_color;
//                 font-weight: 500;
//                 box-shadow: none;
//                 padding: 0;
//             }
//             .ant-tabs-tab.ant-tabs-tab-disabled {
//                 cursor: pointer;
//             }
//             .ant-tabs-tab {
//                 font-size: $fs-text;
//             }
//             .ant-tabs-nav {
//                 margin-bottom: 0;
//                 padding-left: 20px;
//                 padding-right: 20px;
//             }
//             .ant-tabs-content-holder {
//                 .list-container {
//                     margin-top: 12px;

//                     @media screen and (min-width: $breakpoint-tablet) {
//                         margin-top: 50px;
//                     }
//                 }
//                 .activity-profile {
//                     position: relative;

//                     .mobile-activity-filter {
//                         position: fixed;
//                         bottom: 0;
//                         left: 0;
//                         padding: 0 16px;
//                         background-color: $primary_background_color;
//                         border: none;
//                         height: 74px;
//                         overflow: auto;
//                         z-index: 1;
//                         width: 100%;
//                         @media screen and (max-width: 576px) {
//                             padding: 0 36px;
//                         }
//                         .button-filter-mobile {
//                             width: 100%;
//                         }
//                         .hide {
//                             display: none;
//                         }
//                     }
//                 }
//                 .no-item-container,
//                 .no-activity-container {
//                     text-align: center;
//                     p:first-of-type {
//                         margin-top: 22px;
//                         font-size: 18px;
//                         font-family: $font-primary;
//                         margin-bottom: 0;
//                     }
//                     p:nth-of-type(2) {
//                         color: $third_color;
//                         font-size: 14px;
//                         font-family: $font-secondary;
//                         margin-top: 8px;
//                         margin-bottom: 0;
//                     }
//                     .btn-browse {
//                         margin: 0 auto;
//                         margin-top: 32px;
//                         width: 217px;
//                         height: 50px;
//                         .ant-anchor-wrapper {
//                             background-color: transparent;
//                             .ant-anchor-ink {
//                                 display: none;
//                                 position: absolute;
//                             }
//                             .ant-anchor-link {
//                                 padding: 0;
//                                 width: 217px;
//                                 height: 50px;
//                                 a {
//                                     display: flex;
//                                     align-items: center;
//                                     justify-content: center;
//                                     color: white !important;
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 .no-item-container {
//                     margin-top: 0;
//                 }
//             }
//             [ant-click-animating-without-extra-node]:after {
//                 -webkit-animation: none !important;
//                 -moz-animation: none !important;
//                 -o-animation: none !important;
//                 -ms-animation: none !important;
//                 animation: none !important;
//             }
//             .ant-card.card-item-custom {
//                 .card-item-custom-content_footer__block {
//                     text-align: start;
//                 }
//             }

//             @media screen and (min-width: $breakpoint-tablet) {
//                 margin-top: 70px;
//                 .tabs {
//                     margin-top: 50px;
//                 }
//             }
//         }

//         .dark-tabs {
//             .no-item-container,
//             .no-activity-container {
//                 p:first-of-type {
//                     color: $white-text;
//                 }
//                 p:nth-of-type(2) {
//                     color: $second_white_text !important;
//                 }
//             }
//         }
//         .ant-tabs-top,
//         .ant-tabs-bottom,
//         .ant-tabs-top > div,
//         .ant-tabs-bottom > div {
//             & > .ant-tabs-nav::before {
//                 display: none;
//             }
//         }
//         .ant-tabs-ink-bar {
//             background: $primary_color !important;
//             height: 4px !important;
//         }
//         .btn-load,
//         .loading-more {
//             margin: auto;
//             margin-top: 48px;
//         }
//     }
//     .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
//         display: none;
//     }
//     .reference-information {
//         .reference-holder {
//             padding: 10px 20px;
//             border-radius: 400px;
//             border: 1px solid $color-gray-blue;
//             color: $secondary_color;
//             max-width: 400px;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             background-color: white;
//             .copy-icon {
//                 padding-left: 10px;
//                 border-left: 1px solid $secondary_color;
//             }
//             span {
//                 max-width: 300px;
//                 overflow: hidden;
//                 text-overflow: ellipsis;
//                 margin-right: 5px;
//                 font-size: 15px;
//             }
//             p {
//                 margin: 0;
//                 font-size: 15px;
//             }
//         }
//         .reference-code {
//             justify-content: space-between;
//             display: flex;
//             align-items: center;
//         }
//         .information {
//             display: flex;
//             justify-content: center;
//             margin-top: 20px;
//         }
//     }
// }

// #dark-theme .profile-container {
//     &_user-info {
//         background: $primary_background_color_dark;

//         .user-avatar {
//             h5 {
//                 color: $white;
//             }
//         }

//         .information {
//             .profile-follow {
//                 color: $white;
//             }

//             .address-holder {
//                 span {
//                     color: $white;
//                 }
//             }
//         }
//     }
//     .reference-holder {
//         background-color: black;
//         span {
//             color: $white;
//         }
//         p {
//             color: $white;
//         }
//     }
// }

// .menu-social {
//     border-radius: 7px !important;
//     & > li a {
//         display: flex;
//         align-items: center;
//         color: $secondary_color;
//         img {
//             width: 18px;
//             height: 18px;
//         }
//         & i,
//         & .tiktok-icon {
//             margin-right: 5px;
//             font-size: 20px;
//             height: 2rem;
//             width: 2rem;
//             border-radius: 50%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             &.ri-facebook-line {
//                 color: #4267b2;
//                 background: #4267b22a;
//             }
//             &.ri-twitter-line {
//                 color: #35bddb;
//                 background: #afc4e23a;
//             }
//             &.ri-discord-line {
//                 color: #738adb;
//                 background: #afc4e23a;
//             }
//             &.ri-instagram-line {
//                 color: #8a3ab9;
//                 background: #afc4e23a;
//             }
//             &.ri-medium-line {
//                 color: #66cdaa;
//                 background: #afc4e23a;
//             }
//             &.ri-youtube-line {
//                 color: #ff0000;
//                 background: #ff000027;
//             }
//             &.ri-telegram-line {
//                 color: #0088cc;
//                 background: #afc4e23a;
//             }
//         }
//         & .tiktok-icon {
//             background: #afc4e23a;
//         }
//     }
// }

// .social-item a {
//     display: flex;
//     align-items: center;
//     font-size: 15px;
//     font-weight: 500 !important;

//     i {
//         font-size: 21px;
//         margin-right: 5px;
//     }
//     .tiktok-icon svg {
//         width: 21px;
//         height: 21px;
//         margin-right: 5px;
//         path {
//             fill: #1890ff;
//         }
//     }
// }

// .personal-site-container {
//     display: flex;
// }

// @media screen and (max-width: $breakpoint-mobile) {
//     .profile-container {
//         .ant-card.card-item-custom {
//             .card-item-custom-header {
//                 .card-item-owner-creator-wrapper {
//                     flex-direction: row;
//                 }
//             }
//         }
//     }
// }

// @media screen and (min-width: $breakpoint-xxl) {
//     .profile-container {
//         &_user-info {
//             .about-description {
//                 max-width: 330px !important;
//                 padding-right: 10px;
//             }
//         }
//     }
// }

// @media screen and (min-width: $breakpoint-xxl) {
//     .profile-container {
//         &_tabs-container {
//             width: 100%;
//         }
//     }
// }

// @media screen and (max-width: $breakpoint-xl) {
//     .profile-container {
//         &_user-info {
//             overflow-x: auto;
//             overflow-y: hidden;
//             max-height: 150px;
//             .about-description {
//                 max-width: 180px !important;
//             }
//             .user-info-holder {
//                 min-width: 1240px;
//             }
//         }
//     }
// }

// @media screen and (max-width: $breakpoint-tablet) {
//     .profile-container {
//         &_user-info {
//             flex-direction: column;
//             padding: 0 10px;
//             height: 360px;
//             justify-content: flex-end;
//             max-height: none;
//             margin-bottom: 20px;
//             overflow: unset !important;
//             .user-info-holder {
//                 justify-content: center;
//                 min-width: unset;
//                 padding: 0;
//                 .user-avatar {
//                     width: 100%;
//                     &-info {
//                         display: flex;
//                     }
//                     .time-create {
//                         text-align: center;
//                     }
//                     .follow-info {
//                         width: 100%;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                         .profile-follow {
//                             color: $primary_color;
//                             cursor: pointer;
//                             &:first-child {
//                                 padding-right: 10px;
//                             }
//                         }
//                     }
//                 }
//                 .social-info-user {
//                     padding: 0;
//                     width: 100%;
//                     .social-list {
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         .social-item {
//                             padding-right: 0px;
//                             &:nth-child(2) {
//                                 padding-left: 5px;
//                             }
//                         }
//                         .ant-avatar {
//                             position: relative !important;
//                         }
//                     }
//                     & > a {
//                         justify-content: center;
//                     }
//                 }
//             }
//             .user-avatar {
//                 flex-direction: column;
//                 position: absolute;
//                 top: -50px;
//                 height: auto;
//             }
//             .general-information {
//                 padding-bottom: 15px;
//                 width: 100%;
//                 overflow: auto;
//                 justify-content: unset;
//                 .information {
//                     justify-content: unset;
//                     min-width: 400px;
//                     margin: auto;
//                     .address-holder {
//                         padding: 7px 20px;
//                     }
//                 }
//             }
//             .about-description {
//                 max-width: 300px !important;
//             }
//         }
//     }

//     .personal-site-container {
//         justify-content: center;
//     }
// }

// @media screen and (max-width: 448px) {
//     .profile-container {
//         &_user-info {
//             .general-information {
//                 padding: 0 20px 20px 20px;
//                 position: absolute;
//                 width: 100%;
//                 overflow: auto;
//                 justify-content: unset;
//             }
//         }
//     }
// }

// @media screen and (max-width: 425px) {
//     .profile-container {
//         &_tabs-container {
//             .ant-tabs-nav {
//                 .ant-tabs-tab {
//                     font-size: 14px;
//                     padding: 0 10px;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width: 350px) {
//     .social-list {
//         span.ant-avatar {
//             right: 0 !important;
//         }
//     }
// }

// .ant-tabs-dropdown-menu {
//     &-item {
//         color: $primary_color !important;
//         font-weight: 500 !important;
//     }
//     .ant-tabs-dropdown-menu-item-disabled,
//     .ant-tabs-dropdown-menu-item-disabled:hover {
//         cursor: pointer;
//     }
//     .btn-followers,
//     .btn-following {
//         border: none;
//         background-color: transparent !important;
//         color: $primary_color;
//         font-weight: 500;
//         box-shadow: none;
//         padding: 0;
//     }
//     [ant-click-animating-without-extra-node]:after {
//         -webkit-animation: none !important;
//         -moz-animation: none !important;
//         -o-animation: none !important;
//         -ms-animation: none !important;
//         animation: none !important;
//     }
// }

// .ant-popover-content {
//     .ant-popover-inner {
//         border-radius: 8px;
//         .ant-popover-inner-content {
//             color: $primary_color;
//             font-family: $font-primary;
//         }
//     }
// }

// .blue .ant-tooltip-content .ant-tooltip-inner {
//     color: $primary_color !important;
// }

// .blue {
//     .ant-tooltip-content {
//         .ant-tooltip-inner {
//             color: $primary_color !important;
//         }
//     }
//     max-width: 400px !important;
// }

// .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
//     padding-bottom: 10px;
// }

// // Modal CSS
// .ant-modal.following-container,
// .ant-modal.followers-container {
//     .ant-modal-content {
//         height: 504px;
//         max-width: 410px;
//         @media screen and (max-width: $breakpoint-mobile) {
//             width: 100%;
//         }
//         background: white !important;
//         margin: 0 auto;
//         .ant-modal-close {
//             top: 19px;
//             right: 19px;
//             .ant-modal-close-x {
//                 img {
//                     height: 18px;
//                     font-weight: 400;
//                 }
//             }
//         }
//         .ant-modal-header {
//             padding-top: 32px;
//             padding-left: 32px;
//             .ant-modal-title {
//                 position: fixed !important;
//                 color: $primary_color !important;
//                 font-weight: $font-primary !important;
//                 font-size: 36px !important;
//                 line-height: 54px !important;
//             }
//         }
//     }
//     .ant-modal-body {
//         position: relative;
//         padding: 0 24px;
//         max-height: 390px;
//         margin-top: 64px;
//         overflow-y: scroll;
//         &::-webkit-scrollbar {
//             display: none;
//         }
//         .user-list-container {
//             .current-following,
//             .current-followers {
//                 display: flex;
//                 margin-bottom: 32px;
//                 margin-left: 4px;
//                 a {
//                     display: flex;
//                 }
//                 .avatar {
//                     position: relative;
//                     max-width: fit-content;
//                     max-height: fit-content;
//                     display: inline-block;
//                     img:first-of-type {
//                         height: 50px;
//                         width: 50px;
//                         border-radius: 50%;
//                         object-fit: cover;
//                     }
//                     img:nth-of-type(2) {
//                         width: 12px;
//                         position: absolute;
//                         bottom: 1px;
//                         right: 3px;
//                     }
//                     .verify-icon {
//                         position: absolute;
//                         width: 28px;
//                         height: 28px;
//                         bottom: -10px;
//                         right: -10px;
//                     }
//                     @media screen and (max-width: 576px) {
//                         .verify-icon {
//                             width: 9px !important;
//                             bottom: -12px !important;
//                             right: 1px !important;
//                         }
//                     }
//                 }
//                 .information {
//                     display: flex;
//                     flex-direction: column;
//                     justify-content: center;
//                     .user-name {
//                         p {
//                             margin-left: 16px;
//                             margin-bottom: 0;
//                             font-size: 18px;
//                             font-family: $font-primary;
//                             color: $primary_color;
//                         }
//                     }
//                     .number-follow {
//                         margin-left: 16px;
//                         font-size: 14px;
//                         font-family: $font-secondary;
//                         color: $third_color;
//                         min-width: 140px;
//                     }
//                 }
//                 .btn-unfollow,
//                 .btn-follow {
//                     margin-left: auto;
//                     margin-right: 8px;
//                     width: 96px;
//                     height: 36px;
//                     font-size: 14px;
//                 }
//             }
//             .btn-load,
//             .loading-more {
//                 margin: auto;
//             }
//             .btn-load {
//                 height: 40px;
//                 width: 140px;
//                 font-size: 14px;
//                 margin-bottom: 15px;
//             }
//             .loading-more {
//                 margin-bottom: 25px;
//             }
//         }
//     }
// }

// .profile-updating-cover-modal {
//     width: 450px !important;
//     height: 318px;
//     .ant-modal-content {
//         width: 100%;
//         height: 100%;
//         .ant-modal-body {
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .profile-modal-wrapper {
//                 span {
//                     display: block;
//                     text-align: center;
//                 }
//                 .title {
//                     font-size: 24px;
//                     color: $primary_color;
//                     font-family: $font-primary;
//                 }
//                 .description {
//                     font-size: 16px;
//                     color: $third_color;
//                     margin-top: 8px;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: map-get($breakpoints, 'xl')) {
//     .profile-container {
//         &_user-cover {
//             .btn-edit {
//                 position: absolute;
//                 top: 24px;
//                 right: 0;
//             }
//         }
//     }
//     .ant-modal.following-container,
//     .ant-modal.followers-container {
//         .ant-modal-content {
//             .ant-modal-close {
//                 .ant-modal-close-x {
//                     display: none;
//                 }
//             }
//         }
//     }
// }

// @media (max-width: map-get($breakpoints, 'lg')) {
//     .activity-profile {
//         padding-bottom: 20px;
//     }
//     .ant-modal.following-container,
//     .ant-modal.followers-container {
//         .ant-modal-body {
//             .user-list-container {
//                 .btn-load {
//                     height: 36px;
//                     width: 120px;
//                     margin-bottom: 12px;
//                 }
//                 .loading-more {
//                     margin-bottom: 20px;
//                 }
//             }
//         }
//     }
//     .profile-updating-cover-modal {
//         height: 280px;
//     }
// }

// @media (max-width: map-get($breakpoints, 'md')) {
//     .profile-container {
//         &_user-cover {
//             .ant-btn.ant-btn-custom {
//                 max-width: 140px;
//                 height: 42px;
//                 img {
//                     margin-right: 8px;
//                 }
//             }
//             button {
//                 span {
//                     font-size: 16px;
//                 }
//             }
//         }
//         &_user-info {
//             padding: 20px 16px;
//         }
//         .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
//             display: none;
//         }
//     }
//     .profile-updating-cover-modal {
//         width: 300px !important;
//     }
// }

// @media (max-width: map-get($breakpoints, 'sm')) {
//     .profile-container {
//         &_user-cover {
//             .ant-btn.ant-btn-custom {
//                 max-width: 110px;
//                 height: 36px;
//                 img {
//                     margin-right: 5px;
//                 }
//             }
//             button {
//                 span {
//                     font-size: 14px;
//                 }
//             }
//         }

//         .user-info {
//             .user-bio {
//                 padding: 0 42px;
//             }
//             .information {
//                 flex-direction: column;
//             }
//         }

//         .ant-tabs > .ant-tabs-nav {
//             &::before {
//                 left: 21px;
//                 right: 21px;
//             }
//         }
//         .profile-container_tabs-container {
//             padding: 0 !important;
//             .tabs .ant-tabs-content-holder {
//                 margin-top: 10px;
//                 padding: 0 21px;
//             }
//         }
//     }
//     .ant-modal.following-container,
//     .ant-modal.followers-container {
//         .ant-modal-content {
//             max-width: 90vw;
//         }
//         .ant-modal-body {
//             .user-list-container {
//                 .current-following,
//                 .current-followers {
//                     margin-left: 0;
//                     align-items: center;
//                     .avatar {
//                         img {
//                             width: 36px !important;
//                             height: 36px !important;
//                         }
//                     }
//                     .information {
//                         .user-name {
//                             p {
//                                 margin-left: 8px;
//                                 font-size: 16px;
//                             }
//                         }
//                         .number-follow {
//                             margin-left: 8px;
//                             font-size: 11px;
//                             min-width: 75px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     .profile-updating-cover-modal {
//         width: 250px !important;
//         height: 200px;
//         .profile-modal-wrapper {
//             .title {
//                 font-size: 18px !important;
//             }
//             .description {
//                 font-size: 14px !important;
//             }
//         }
//     }
// }

// @media screen and (max-width: $breakpoint-m-mobile) {
//     .profile-container {
//         &_user-info {
//             .information {
//                 .button-holder {
//                     flex-direction: column;
//                 }
//                 .copy-icon {
//                     padding-left: 10px;
//                     border-left: 1px solid $secondary_color;
//                 }
//             }
//         }
//     }
// }

// .ant-scrolling-effect.hidden-scroll {
//     width: calc(100%) !important;
//     @media screen and (max-width: 576px) {
//         overflow: hidden !important;
//         position: fixed !important;
//         width: 100% !important;
//     }
// }

// .over-description-tooltip {
//     &.ant-tooltip {
//         max-width: 400px;
//     }
// }

// .text-ac-dark {
//     color: #b4adad !important;
// }

@import "styles/variables.scss";
@import "styles/mixins.scss";

.all-categories-button__dropdown--filter {
  z-index: 999 !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  text-align: center;
}

.blank-user-profile {
  width: 100vw;
  height: 68vh;
}

.container-profile {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding: 28px;
  background-color: $white;
  border-radius: 28px;
  border: 1px solid $gray-color;
  .user-cover {
    width: 100%;
  }
  .collection-tabs {
    .ant-tabs-tab-btn {
      color: $gray-color-3 !important;
    }
  }
}

.profile-container {
  width: 100%;
  // height: 260px; //test
  &_user-cover {
    position: relative;
    height: 260px;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    .cover-picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btn-edit {
      position: absolute;
      right: 40px;
      bottom: -50px;
      img {
        margin-right: 11px;
      }
      span {
        font-size: 16px;
        font-family: $font-primary;
      }
    }
    &:hover {
      .btn-edit {
        bottom: 5px;
        right: 0px;
      }
    }
  }
  .tabs-inner {
    position: relative;
  }
  &_user-info {
    display: flex;
    align-items: center;
    width: 100%;
    // justify-content: space-between;
    background: #fff;
    // max-height: 102px;
    height: auto;
    position: relative;
    z-index: 1;
    margin-top: 40px;
    margin-bottom: 32px;
    padding: 0 28px;
    height: 100%; //test
    .user-info-holder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      @include up-to-size("md") {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      // overflow-x: auto;
      // overflow-y: hidden;
      .user-info-btn {
        margin-right: 12px;
        @include up-to-size("xxl") {
          margin-bottom: 8px;
        }
        span {
          color: $gray-color-2;
          font-weight: 500;
          font-size: 14px;
          padding-left: 8px;
        }
        .text-address-copy {
          padding-right: 8px;
          padding-left: 0;
        }
      }
    }

    .user-avatar {
      display: flex;
      width: 100%;
      @include up-to-size("lg") {
        flex-direction: column;
        height: 100%;
      }
      .color_text {
        color: $secondary_color;
        margin: 0;
        margin-left: 20px;
      }

      h5 {
        font-size: 24px;
        font-weight: bold;
        color: $gray-color-3;
        line-height: 38px;
        margin-top: 0!important;
        @include up-to-size("lg") {
          text-align: center;
        }
      }
      // .shrink-title {
      //     max-width: 150px;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      // }

      .avatar-container {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        position: relative;
        // border: 4px solid white;
        img:first-of-type {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        .verify-icon {
          position: absolute;
          width: 28px;
          height: 28px;
          bottom: 0;
          right: 10px;
        }
        .avatar-default {
          //   transform: scale(1.15);
        }
      }
      .container-user-info {
        width: 100%;
        height: auto;
        @include min-size("lg") {
          margin-left: 24px;
        }
      }
      .container-user-tabs {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @include up-to-size("xl") {
          flex-direction: column;
        }
        .short-url {
          font-size: 16px;
          font-weight: 600;
          color: $gray-color-2;
        }
        .user-avatar-info h5 {
          color: $gray-color-3;
          font-weight: 600;
          width: fit-content;
        }
      }
      .user-info-tabs {
        display: flex;
        @include up-to-size("lg") {
          flex-direction: column;
          align-items: center;
        }
        // .tab-holder, .address-holder {
        //     padding: 10px 20px;
        //     border-radius: 400px;
        //     border: 1px solid $color-gray-blue;
        //     display: -webkit-box;
        //     display: -ms-flexbox;
        //     display: flex;
        //     -webkit-box-pack: justify;
        //     -ms-flex-pack: justify;
        //     justify-content: space-between;
        //     color: $secondary_color;
        //     display: flex;
        //     align-items: center;
        //     max-width: 206px;
        //     max-height: 48px;
        //     margin-right: 12px;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     @include up-to-size('lg') {
        //         margin-bottom: 8px;
        //     }
        //     .copy-icon {
        //         font-size: 20px;
        //     }
        //     span {
        //         max-width: 140px;
        //         overflow: hidden;
        //         text-overflow: ellipsis;
        //         // margin-right: 5px;
        //     }
        // }

        // .address-holder > span {
        //     margin-right: 5px;
        // }

        // .tab-holder > span {
        //     margin-left: 5px;
        // }

        // .tab-holder {
        //     cursor: pointer;
        // }
      }

      .social-info-user {
        padding: 0 15px;
        // width: 200px;
        .social-list {
          // display: inline-block;
          display: flex;
          // flex-wrap: wrap;
          // justify-content: flex-start;
          align-items: center;
          position: relative;
          padding: 10px 0;
          // width: 100%;
          @include up-to-size("sm") {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          > span.ant-avatar {
            // position: absolute;
            // right: -15px;
            // bottom: 2px;
            display: block;
          }
          .social-item {
            display: block;
            margin-right: 20px;
          }
        }
        a {
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 500;

          i {
            margin-right: 5px;
            line-height: 24px;
          }
          svg {
            width: 21px;
            height: 21px;
            margin-right: 5px;
            path {
              fill: #1890ff;
            }
          }
        }
        .personal-site {
          line-height: 24px;
          margin-top: -4px;
          i {
            font-size: 21px;
          }
        }
      }

      .follow-me {
        padding-left: 15px;
        .btn-follow {
          width: 44px;
          height: 44px;
          font-weight: 600;
          color: $primary_color;
          border: 1px solid $color-gray-blue;
          i {
            margin-right: 0;
          }
        }
      }

      .about-description {
        color: $gray-color-2;
        font-size: 16px;
        font-weight: 500;
        margin-left: 15px;
        display: flex;
        width: 100%;
        // display: -webkit-box;
        // overflow: hidden;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;
        position: relative;
        word-break: break-word;
        // .description-tooltip {
        //   position: absolute;
        //   width: 100%;
        //   height: 100%;
        // }
        p {
          margin-bottom: 0;
        }
        .expandBtn {
          color: $primary_color;
          font-weight: bold;
          margin-left: 4px;
          cursor: pointer;
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .user-name-address {
        color: $primary_color;
        font-family: $font-primary;
        padding-left: 20px;
        .name {
          margin-bottom: 24px;
          font-size: 24px;
          @media screen and (max-width: 576px) {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 550px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .customURL {
          max-width: 100%;
          margin-bottom: 8px;
          word-wrap: break-word;
        }
        span {
          margin-right: 24px;
        }
        .get-address-container {
          margin: 0;
          .btn-get-address {
            color: $primary_color;
          }
        }
      }
    }
    .general-information {
      text-align: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include up-to-size("xxl") {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
      }
      .follow-info {
        display: grid;
        font-weight: 600;
      }
    }
    .reference-information {
      span {
        color: black !important;
      }
    }
    .get-address-container {
      display: inline-block;
      position: relative;
      width: fit-content;
      height: fit-content;
      margin-top: 72px;
      font-family: $font-primary;
      .btn-get-address {
        display: inline-block;
        font-size: 14px;
        height: 36px;
        width: fit-content;
      }
    }

    .user-info {
      color: $primary_color;
      padding-top: 16px;
      .user-bio {
        max-width: 480px;
        color: $third_color;
        word-wrap: break-word;
        margin: 0 auto;
        font-family: $font-primary;
        button {
          border: none;
          background-color: inherit;
          color: $primary_color;
          font-family: $font-primary;
          text-decoration: underline;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .user-social-link {
        padding-top: 18px;
        font-family: $font-primary;
        a {
          color: inherit;
          margin-left: 11px;
        }
        .twitter-link {
          display: inline-block;
          span {
            margin-right: 26px;
          }
        }
        .personal-site-link {
          display: inline-block;
        }
      }
    }

    .dark-information {
      .follow-btn {
        margin-left: 24px;
        .btn-unfollow,
        .btn-follow {
          span {
            color: black;
          }
        }
      }
    }

    .information {
      display: flex;
      align-items: center;
      .follow-btn {
        margin-left: 24px;
        .btn-unfollow,
        .btn-follow {
          margin-left: auto;
          margin-right: 8px;
          width: 100% !important;
          height: 100% !important;
          background-color: $primary_color;
        }
        span {
          padding-left: 0;
        }
      }
      .profile-follow {
        color: $primary_color;
        padding-left: 15px;
        cursor: pointer;
        font-size: $fs-text;
        font-weight: 500;
      }
      // .address-holder {
      //     padding: 10px 20px;
      //     border-radius: 400px;
      //     border: 1px solid $color-gray-blue;
      //     display: -webkit-box;
      //     display: -ms-flexbox;
      //     display: flex;
      //     -webkit-box-pack: justify;
      //     -ms-flex-pack: justify;
      //     justify-content: space-between;
      //     color: $secondary_color;
      //     display: flex;
      //     align-items: center;
      //     max-width: 206px;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     .copy-icon {
      //         padding-left: 10px;
      //         border-left: 1px solid $secondary_color;
      //     }
      //     span {
      //         max-width: 140px;
      //         overflow: hidden;
      //         text-overflow: ellipsis;
      //         margin-right: 5px;
      //     }
      // }

      div {
        display: inline-block;
        width: fit-content;
      }
      .button-holder {
        display: flex;
        flex-direction: row;
        min-width: 110px;
        .share-icon-container,
        .report-icon-container {
          color: #000000;
          // margin-left: 24px;
          cursor: pointer;
          background: $secondary_background_color;
          height: 1rem;
          width: 1rem;
          -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border: solid 1px $color-gray-blue;
          border-radius: 2000px;
          -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          &:hover {
            box-shadow: none;
            background: $color-gray-blue;
          }
          img:hover {
            cursor: pointer;
          }
        }
      }

      .edit-profile-btn {
        margin-left: 24px;
        .btn-edit {
          min-width: 130px;
          width: fit-content;
        }
      }
    }
    .copied::before {
      position: absolute;
      content: "Copied";
      height: 100%;
      width: 100%;
      background-color: $third_color;
      color: $primary_color;
      border-radius: 8px;
      z-index: 999;
      opacity: 1;
      left: 0;
      top: 0;
      padding: 7px;
      pointer-events: none;
    }
  }
  .line-filter {
    height: 1px;
    width: 100%;
    background-color: rgba(163, 174, 208, 0.2);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: -50px;
      left: 0;
      right: 0;
    }
  }
  &_tabs-container {
    min-height: 552px;
    .profile__sidebar {
      margin-top: 36px;
      .profile-card {
        background: $secondary_background_color;
        border: solid 1px $color-gray-blue;
        border-radius: 20px;
        -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
        box-shadow: 0px 17px 13px 0px #192c4b05;
        display: block;
        word-break: break-all;
        white-space: pre-wrap;
        li {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .social_profile {
          list-style-type: none;
          padding: 0;
        }
      }
    }

    .tabs {
      margin-top: 21px;
      font-weight: 500;
      color: $primary_color;
      .tabs {
        margin-top: 20px;
        @include up-to-size("sm") {
          margin-top: 0;
        }

        .ant-tabs-nav-wrap {
          justify-content: flex-start !important;
        }

        .ant-tabs-nav {
          padding: 0 !important;
        }
        .ant-tabs-content-holder {
          padding: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 24px;
        }
      }
      .ant-btn.btn-following,
      .ant-btn.btn-followers {
        border: none;
        background-color: transparent !important;
        color: $primary_color;
        font-weight: 500;
        box-shadow: none;
        padding: 0;
      }
      .ant-tabs-tab.ant-tabs-tab-disabled {
        cursor: pointer;
      }
      .ant-tabs-tab {
        font-size: $fs-text;
      }
      .ant-tabs-nav {
        margin-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
      }
      .ant-tabs-content-holder {
        .list-container {
          margin-top: 12px;

          @media screen and (min-width: $breakpoint-tablet) {
            margin-top: 50px;
          }
        }
        .activity-profile {
          position: relative;

          .mobile-activity-filter {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 0 16px;
            background-color: $primary_background_color;
            border: none;
            height: 74px;
            overflow: auto;
            z-index: 1;
            width: 100%;
            @media screen and (max-width: 576px) {
              padding: 0 36px;
            }
            .button-filter-mobile {
              width: 100%;
            }
            .hide {
              display: none;
            }
          }
        }
        .no-item-container,
        .no-activity-container {
          text-align: center;
          p:first-of-type {
            margin-top: 22px;
            font-size: 18px;
            font-family: $font-primary;
            margin-bottom: 0;
          }
          p:nth-of-type(2) {
            color: $third_color;
            font-size: 14px;
            font-family: $font-secondary;
            margin-top: 8px;
            margin-bottom: 0;
          }
          .btn-browse {
            margin: 0 auto;
            margin-top: 32px;
            width: fit-content;
            height: 50px;
            .ant-anchor-wrapper {
              background-color: transparent;
              .ant-anchor-ink {
                display: none;
                position: absolute;
              }
              .ant-anchor-link {
                padding: 0;
                width: 217px;
                height: 50px;
                a {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: white !important;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        .no-item-container {
          margin-top: 0;
        }
      }
      [ant-click-animating-without-extra-node]:after {
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
      }
      .ant-card.card-item-custom {
        .card-item-custom-content_footer__block {
          text-align: start;
        }
      }

      @media screen and (min-width: $breakpoint-tablet) {
        margin-top: 70px;
        .tabs {
          margin-top: 50px;
        }
      }
    }

    .dark-tabs {
      .no-item-container,
      .no-activity-container {
        p:first-of-type {
          color: $white-text;
        }
        p:nth-of-type(2) {
          color: $second_white_text !important;
        }
      }
    }
    .ant-tabs-top,
    .ant-tabs-bottom,
    .ant-tabs-top > div,
    .ant-tabs-bottom > div {
      & > .ant-tabs-nav::before {
        display: none;
      }
    }
    .ant-tabs-ink-bar {
      background: $primary_color !important;
      height: 4px !important;
    }
    .btn-load,
    .loading-more {
      margin: auto;
      margin-top: 48px;
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
    display: none;
  }
  .reference-information {
    .reference-holder {
      padding: 10px 20px;
      border-radius: 400px;
      border: 1px solid $color-gray-blue;
      color: $secondary_color;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: white;
      .copy-icon {
        padding-left: 10px;
        border-left: 1px solid $secondary_color;
      }
      span {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
        font-size: 15px;
      }
      p {
        margin: 0;
        font-size: 15px;
      }
    }
    .reference-code {
      justify-content: space-between;
      display: flex;
      align-items: center;
    }
    .information {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

.container-tab-data {
  margin-top: 40px;
}

#dark-theme .profile-container {
  &_user-info {
    background: $primary_background_color_dark;
    width: 100%;

    .user-avatar {
      h5 {
        color: $white;
      }
    }

    .information {
      .profile-follow {
        color: $white;
      }

      .address-holder {
        span {
          color: $white;
        }
      }
    }
  }
  .reference-holder {
    background-color: black;
    span {
      color: $white;
    }
    p {
      color: $white;
    }
  }
}

.menu-social {
  border-radius: 7px !important;
  & > li a {
    display: flex;
    align-items: center;
    color: $secondary_color;
    img {
      width: 18px;
      height: 18px;
    }
    & i,
    & .tiktok-icon {
      margin-right: 5px;
      font-size: 20px;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.ri-facebook-line {
        color: #4267b2;
        background: #4267b22a;
      }
      &.ri-twitter-line {
        color: #35bddb;
        background: #afc4e23a;
      }
      &.ri-discord-line {
        color: #738adb;
        background: #afc4e23a;
      }
      &.ri-instagram-line {
        color: #8a3ab9;
        background: #afc4e23a;
      }
      &.ri-medium-line {
        color: #66cdaa;
        background: #afc4e23a;
      }
      &.ri-youtube-line {
        color: #ff0000;
        background: #ff000027;
      }
      &.ri-telegram-line {
        color: #0088cc;
        background: #afc4e23a;
      }
    }
    & .tiktok-icon {
      background: #afc4e23a;
    }
  }
}

.social-item a {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500 !important;

  i {
    font-size: 21px;
    margin-right: 5px;
  }
  .tiktok-icon svg {
    width: 21px;
    height: 21px;
    margin-right: 5px;
    path {
      fill: #1890ff;
    }
  }
}

.personal-site-container {
  display: flex;
}

@media screen and (max-width: $breakpoint-mobile) {
  .profile-container {
    .ant-card.card-item-custom {
      .card-item-custom-header {
        .card-item-owner-creator-wrapper {
          flex-direction: row;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .profile-container {
    &_user-info {
      .about-description {
        // max-width: 330px !important;
        width: 100%;
        padding-right: 10px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .profile-container {
    &_tabs-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .profile-container {
    &_user-info {
      // overflow-x: auto;
      // overflow-y: hidden;
      // max-height: 150px;
      height: 100%; //test
      .about-description {
        // max-width: 180px !important;
        width: 100%;
      }
      // .user-info-holder {
      //     min-width: 1240px;
      // }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .profile-container {
    &_user-info {
      flex-direction: column;
      padding: 0 10px;
      // height: 360px;
      height: 100%; //test
      justify-content: flex-end;
      max-height: none;
      margin-bottom: 20px;
      overflow: unset !important;
      .user-info-holder {
        justify-content: center;
        min-width: unset;
        padding: 0;
        .user-avatar {
          width: 100%;
          // &-info {
          //     display: flex;
          // }
          .time-create {
            text-align: center;
          }
          .follow-info {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .profile-follow {
              color: $primary_color;
              cursor: pointer;
              &:first-child {
                padding-right: 10px;
              }
            }
          }
        }
        .social-info-user {
          padding: 0;
          width: 100%;
          .social-list {
            display: flex;
            justify-content: center;
            align-items: center;
            .social-item {
              padding-right: 0px;
              &:nth-child(2) {
                padding-left: 5px;
              }
            }
            .ant-avatar {
              position: relative !important;
            }
          }
          & > a {
            justify-content: center;
          }
        }
      }
      // .user-avatar {
      //     flex-direction: column;
      //     // position: absolute;
      //     // top: -50px;
      //     // height: auto;
      //     height: 100%; //test
      // }
      .general-information {
        padding-bottom: 15px;
        width: 100%;
        // overflow: auto;
        justify-content: unset;
        .information {
          display: flex;
          // justify-content: unset;
          width: auto;
          // min-width: 400px;
          margin: auto;
          .address-holder {
            padding: 7px 20px;
          }
        }
      }
      .about-description {
        // max-width: 300px !important;
        width: 100%;
      }
    }
  }

  .personal-site-container {
    justify-content: center;
  }
}

@media screen and (max-width: 448px) {
  .profile-container {
    &_user-info {
      .general-information {
        // padding: 0 20px 20px 20px;
        // position: absolute;
        width: 100%;
        // overflow: auto;
        justify-content: unset;
        height: 100%; //test
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .profile-container {
    &_tabs-container {
      .ant-tabs-nav {
        .ant-tabs-tab {
          font-size: 14px;
          padding: 0 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .social-list {
    span.ant-avatar {
      right: 0 !important;
    }
  }
}

.ant-tabs-dropdown-menu {
  &-item {
    color: $primary_color !important;
    font-weight: 500 !important;
  }
  .ant-tabs-dropdown-menu-item-disabled,
  .ant-tabs-dropdown-menu-item-disabled:hover {
    cursor: pointer;
  }
  .btn-followers,
  .btn-following {
    border: none;
    background-color: transparent !important;
    color: $primary_color;
    font-weight: 500;
    box-shadow: none;
    padding: 0;
  }
  [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

.ant-popover-content {
  .ant-popover-inner {
    border-radius: 8px;
    .ant-popover-inner-content {
      color: $primary_color;
      font-family: $font-primary;
    }
  }
}

.blue .ant-tooltip-content .ant-tooltip-inner {
  color: $primary_color !important;
}

.blue {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      color: $primary_color !important;
    }
  }
  max-width: 400px !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  padding-bottom: 10px;
}

// Modal CSS
.ant-modal.following-container,
.ant-modal.followers-container {
  .ant-modal-content {
    height: 504px;
    max-width: 410px;
    @media screen and (max-width: $breakpoint-mobile) {
      width: 100%;
    }
    background: white !important;
    margin: 0 auto;
    .ant-modal-close {
      top: 19px;
      right: 19px;
      .ant-modal-close-x {
        img {
          height: 18px;
          font-weight: 400;
        }
      }
    }
    .ant-modal-header {
      padding-top: 32px;
      padding-left: 32px;
      .ant-modal-title {
        position: fixed !important;
        color: $gray-color-3 !important;
        font-weight: $font-primary !important;
        font-size: 24px !important;
        line-height: 54px !important;
      }
    }
  }
  .ant-modal-body {
    position: relative;
    padding: 0 24px;
    max-height: 390px;
    margin-top: 64px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .user-list-container {
      .current-following,
      .current-followers {
        display: flex;
        margin-bottom: 32px;
        margin-left: 4px;
        a {
          display: flex;
        }
        .avatar {
          position: relative;
          max-width: fit-content;
          max-height: fit-content;
          display: inline-block;
          img:first-of-type {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            object-fit: cover;
          }
          img:nth-of-type(2) {
            width: 12px;
            position: absolute;
            bottom: 1px;
            right: 3px;
          }
          .verify-icon {
            position: absolute;
            width: 28px;
            height: 28px;
            bottom: -10px;
            right: -10px;
          }
          @media screen and (max-width: 576px) {
            .verify-icon {
              width: 9px !important;
              bottom: -12px !important;
              right: 1px !important;
            }
          }
        }
        .information {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .user-name {
            p {
              margin-left: 16px;
              margin-bottom: 0;
              font-size: 18px;
              font-family: $font-primary;
              color: $gray-color-3;
            }
          }
          .number-follow {
            margin-left: 16px;
            font-size: 14px;
            font-family: $font-secondary;
            color: $third_color;
            min-width: 140px;
          }
        }
        .btn-unfollow,
        .btn-follow {
          margin-left: auto;
          margin-right: 8px;
          width: 96px;
          height: 36px;
          font-size: 14px;
        }
      }
      .btn-load,
      .loading-more {
        margin: auto;
      }
      .btn-load {
        height: 40px;
        width: 140px;
        font-size: 14px;
        margin-bottom: 15px;
      }
      .loading-more {
        margin-bottom: 25px;
      }
    }
  }
}

.profile-updating-cover-modal {
  width: 450px !important;
  height: 318px;
  .ant-modal-content {
    width: 100%;
    height: 100%;
    .ant-modal-body {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .profile-modal-wrapper {
        span {
          display: block;
          text-align: center;
        }
        .title {
          font-size: 24px;
          color: $gray-color-3;
          font-family: $font-primary;
        }
        .description {
          font-size: 16px;
          color: $third_color;
          margin-top: 8px;
        }
      }
    }
  }
}

@media (max-width: map-get($breakpoints, "xl")) {
  .profile-container {
    &_user-cover {
      .btn-edit {
        position: absolute;
        top: 24px;
        right: 0;
      }
    }
  }
  .ant-modal.following-container,
  .ant-modal.followers-container {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x {
          display: none;
        }
      }
    }
  }
}

@media (max-width: map-get($breakpoints, "lg")) {
  .activity-profile {
    padding-bottom: 20px;
  }
  .ant-modal.following-container,
  .ant-modal.followers-container {
    .ant-modal-body {
      .user-list-container {
        .btn-load {
          height: 36px;
          width: 120px;
          margin-bottom: 12px;
        }
        .loading-more {
          margin-bottom: 20px;
        }
      }
    }
  }
  .profile-updating-cover-modal {
    height: 280px;
  }
}

@media (max-width: map-get($breakpoints, "md")) {
  .profile-container {
    &_user-cover {
      .ant-btn.ant-btn-custom {
        max-width: 140px;
        height: 42px;
        img {
          margin-right: 8px;
        }
      }
      button {
        span {
          font-size: 16px;
        }
      }
    }
    &_user-info {
      padding: 20px 16px;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
      display: none;
    }
  }
  .profile-updating-cover-modal {
    width: 300px !important;
  }
}

@media (max-width: map-get($breakpoints, "sm")) {
  .profile-container {
    &_user-cover {
      .ant-btn.ant-btn-custom {
        max-width: 110px;
        height: 36px;
        img {
          margin-right: 5px;
        }
      }
      button {
        span {
          font-size: 14px;
        }
      }
    }

    .user-info {
      .user-bio {
        padding: 0 42px;
      }
      .information {
        flex-direction: column;
      }
    }

    .ant-tabs > .ant-tabs-nav {
      &::before {
        left: 21px;
        right: 21px;
      }
    }
    .profile-container_tabs-container {
      padding: 0 !important;
      .tabs .ant-tabs-content-holder {
        margin-top: 10px;
        padding: 0 21px;
      }
    }
  }
  .ant-modal.following-container,
  .ant-modal.followers-container {
    .ant-modal-content {
      max-width: 90vw;
    }
    .ant-modal-body {
      .user-list-container {
        .current-following,
        .current-followers {
          margin-left: 0;
          align-items: center;
          .avatar {
            img {
              width: 36px !important;
              height: 36px !important;
            }
          }
          .information {
            .user-name {
              p {
                margin-left: 8px;
                font-size: 16px;
              }
            }
            .number-follow {
              margin-left: 8px;
              font-size: 11px;
              min-width: 75px;
            }
          }
        }
      }
    }
  }
  .profile-updating-cover-modal {
    width: 250px !important;
    height: 200px;
    .profile-modal-wrapper {
      .title {
        font-size: 18px !important;
      }
      .description {
        font-size: 14px !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-m-mobile) {
  .profile-container {
    &_user-info {
      .information {
        .button-holder {
          flex-direction: column;
        }
        .copy-icon {
          padding-left: 10px;
          border-left: 1px solid $secondary_color;
        }
      }
    }
  }
}

.ant-scrolling-effect.hidden-scroll {
  width: calc(100%) !important;
  @media screen and (max-width: 576px) {
    overflow: hidden !important;
    position: fixed !important;
    width: 100% !important;
  }
}

.over-description-tooltip {
  &.ant-tooltip {
    max-width: 400px;
  }
}

.text-ac-dark {
  color: #b4adad !important;
}

@media screen and (max-width: $breakpoint-lg-tablet) {
  .profile-container_user-info .user-avatar {
    align-items: center;
    .container-user-info .user-avatar-info{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .collection-tabs {
    padding: 0!important;
    .ant-tabs.tabs-custom .ant-tabs-nav-list .ant-tabs-tab {
      padding: 2px 6px;
    }
  }
}
