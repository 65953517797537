@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.sale-modal-container {
    .sale-modal-title {
        color: $gray-color-3;
        font-size: $fs-title-modal;
        font-family: $font-primary;
        margin-bottom: 32px;
    }
    .create-nft-form {
        flex: 1;
        box-sizing: border-box;
        overflow: hidden;

        .create-nft_text__highlight {
            color: $gray-color-3!important;
            font-weight: bold;
        }

        .create-nft-block {
            margin-bottom: 32px;
            .label-custom_title {
                color: $gray-color-3;
            }
            .ant-select.ant-select-custom .ant-select-selector {
                height: 45px!important;
            }
        }

        .create-nft-block_desc {
            margin-bottom: 32px;
        }

        .create-nft-marketPlace {
            margin-bottom: 32px;
            .create-nft-marketPlace_header {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 24px;
                .create-nft-marketPlace_label {
                    display: flex;
                    flex-direction: column;

                    .create-nft-marketPlace_label__title {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        color: $gray-color-3;
                        margin-bottom: 4px;
                    }

                    .create-nft-marketPlace_label__desc {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        color: $third_color;
                    }
                }
            }
        }

        .create-nft-price {
            display: flex;
            justify-content: space-between;

            .create-nft-price_input {
                flex: 1;
                margin-right: -20px;
                margin-bottom: 0;
            }

            .create-nft-price_currency {
                width: 120px;
                margin-bottom: 0;
            }
            .ant-select-selector {
                width: auto !important;
            }
        }

        .create-nft-minimumBid_label {
            width: 100%;
        }

        .create-nft-minimumBid {
            display: flex;
            justify-content: space-between;

            .create-nft-minimumBid_price_input {
                flex: 1;
                margin-right: 20px;
                margin-bottom: 0;
            }

            .create-nft-minimumBid_price_currency {
                margin-bottom: 0;
                width: 120px;
                margin-bottom: 0;
            }

            .create-nft-startDate {
                flex: 1;
                margin-right: 10px;
                margin-bottom: 0;
            }

            .create-nft-expireDate {
                flex: 1;
                margin-left: 10px;
                margin-bottom: 0;
            }
        }

        .create-nft-minimumBid_date__desc {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            margin-top: 7px;
            color: $third_color;
            display: block;

            .create-nft-minimumBid_date__highlight {
                color: $primary_color;
                text-decoration: underline;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .create-nft-minimumBid_label {
            width: 100%;
        }

        .create-nft-minimumBid {
            display: flex;
            justify-content: space-between;

            .create-nft-minimumBid_price_input {
                flex: 1;
                margin-right: 20px;
                margin-bottom: 0;
            }

            .create-nft-minimumBid_price_currency {
                display: flex;
                height: 45px;
                width: 120px;
                justify-content: center;
                align-items: center;
                border-radius: 18px;
                box-sizing: border-box;
                border: 1px solid $input-prefix-background;
                color: $primary_color;
                font-size: 18px;
            }

            .create-nft-startDate {
                margin-bottom: 0;
                max-width: 214px;
                margin-left: 1px;
                @include up-to-size('sm') {
                    max-width: 100%;
                    width: 100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }

            .create-nft-expireDate {
                margin-left: 8px;
                margin-bottom: 0;
                max-width: 214px;
                @include up-to-size('sm') {
                    max-width: 100%;
                    width: 100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }
            .expireDate_label > * {
                margin-left: 6px;
                @include up-to-size('sm') {
                    margin-left: 0px;
                    margin-top: 8px;
                }
            }
            @include up-to-size('sm') {
                flex-flow: row wrap;
            }
        }

        .create-nft-minimumBid_date__desc {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            margin-top: 7px;
            color: $third_color;
            display: block;

            .create-nft-minimumBid_date__highlight {
                color: $primary_color;
                text-decoration: underline;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
}
