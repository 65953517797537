@import 'styles/variables.scss';
@import 'styles/mixins.scss';
.process-modal-container {
    color: $third_color;
    width: 100%;
    p {
        margin: 0px;
    }
    & > :first-child {
        color: $gray-color-3;
        font-size: 24px;
        font-family: $font-primary;
        margin-bottom: 24px;
        @include up-to-size('md') {
            font-size: 24px;
        }
    }
    & > :nth-child(2) {
        margin-bottom: 32px;
    }
    .process-detail {
        border: 1px solid $border_color;
        & > * {
            & > * {
                width: 50%;
            }
        }
        & > :first-child {
            padding: 15px 24px;
            border-bottom: 1px solid $border_color;
        }
        & > :nth-child(2) {
            padding: 15px 24px;
            & > :first-child {
                display: flex;
                & > :first-child {
                    margin-right: 10px;
                }
            }
            & > :nth-child(2) {
                color: $primary-color;
                font-family: $font-primary;
            }
        }
    }
}
