@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.dark-trading-history {
  &__no-item {
    p {
      color: $white-text;
    }
  }
  &__table {
    & > * {
      span,
      a,
      .ant-col {
        color: $white-text !important;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  .ant-select.ant-select-custom {
    .ant-select-arrow {
      .anticon {
        color: white !important;
      }
    }
  }
}

.trading-history {
  &__loading {
    margin: auto;
  }
  &__container {
    color: $primary_color;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 36px;

    .paginate-filter-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .select-custom-wrapper  {
        margin-left: auto;
      }
    }
    .select {
      width: 200px;
    }

    .user-info {
      align-items: center;
      gap: 8px;
      .avatar {
        position: relative;
        .icon {
          position: absolute;
          top: 50%;
          right: -2px;
          z-index: 1;
        }
      }
    }
  }
  &__filter-event {
    width: 395px;
    height: 36px;
    @include up-to-size('md') {
      width: 100%;
    }
  }

  &__table {
    border-radius: 28px;
    background-color: white;
    border: 1px solid $gray-color;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 28px;
    & > * {
      background: $white;
      & > * {
        width: calc(15%);
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: visible;
      }
      a {
        color: $gray-color-3;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ant-row:first-child {
      padding-bottom: 16px;
      font-weight: 500;
      color: $gray-color-2;
    }
    .ant-row:not(:first-child) {
      border-top: 1px solid $border_color;
      > .ant-col:first-child {
        padding-left: 16px;
      }
      padding-block: 16px;
      color: $gray-color-3;
      font-weight: 500;
      .action-col {
        display: flex;
        gap: 10px;
      }
    }
  }
  &__table-mobile {
    margin-bottom: 48px;
    & > :last-child {
      border-radius: 0px 0px 8px 8px;
    }
    .ant-col {
      & > :nth-child(3),
      & > :nth-child(4) {
        column-gap: 15px;
        & > :nth-child(2) {
          max-width: 260px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    & > :first-child {
      border-radius: 8px 8px 0px 0px;
    }
    & > * {
      border-bottom: 1px solid $border_color;
      padding: 24px;
      background: $white;
      a {
        color: $primary_color;
      }
      & > * {
        margin-bottom: 20px;
        & > :first-child {
          font-family: $font-primary;
          font-size: 14px;
        }
      }
      & > :last-child {
        margin-bottom: 0px;
      }
    }
    .ant-row {
      flex-flow: nowrap;
    }
  }

  &__event-name {
    & > :first-child {
      margin-right: 10px;
    }
  }
  &__date-link {
    font-weight: 600;
    & > :first-child {
      margin-right: 8px;
    }
  }
  &__no-item {
    text-align: center;
    margin-top: 60px;
    p {
      font-size: 18px;
      font-family: $font-primary;
      margin-top: 22px !important;
    }
    .no-item-img {
      height: 100px;
      margin-left: 15px;
    }
  }
}
.filter-event-dropdown {
  z-index: 999 !important;
}
