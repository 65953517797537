@import '../../../../../styles/variables.scss';

.video-player_wrap {
    position: relative;
    display: flex;
    justify-content: center;

    .video-player_video {
        border-radius: 8px;
        width: 100%;
        height: auto;
        object-fit: contain;
        display: block;
    }

    .video-player_controls {
        height: 50px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 22px;
        background: rgba(243, 246, 253, 0.9);
        border-radius: 8px;
        bottom: 16px;
        width: 90%;
    }

    .video-player_controls_mp3 {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .video-player_controls_item {
        cursor: pointer;
    }

    .video-player_controls_control {
        display: flex;
    }

    .video-player_controls_sliders {
        width: 140px;
    }

    .video-player_controls_times {
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: $third_color;
    }

    .video-player_controls_expand {
        display: flex;
    }

    .video-player_controls_sound {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        &:before {
            content: '';
            position: absolute;
            height: 15px;
            width: 100%;
            top: -15px;
        }

        .video-player_controls_sound_picker {
            height: 70px;
            position: absolute;
            top: -85px;
        }
    }
}

.ant-slider.slider-custom {
    &:hover {
        .ant-slider-track {
            background-color: $primary_color;
            z-index: 10;
        }
    }
    .ant-slider-track {
        background-color: $primary_color;
        z-index: 10;
    }

    .ant-slider-step {
        background: $third_color;
        opacity: 0.5;
        border-radius: 3px;
    }

    .ant-slider-handle {
        width: 16px;
        height: 16px;
        background: $primary_color;
        border: none;
        z-index: 20;
        margin-top: -6px;
    }
}
