@import 'styles/variables.scss';

.header-page {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  .container {
    padding: 0 28px;
  }
  &__container {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    text-align: center;
    gap: 24px;

    .logo-input {
      flex-grow: 1;
      gap: 36px;
      .logo-pace-art {
        display: flex;
      }

      .search-input {
        gap: 24px;
        width: 100%;
      }

      .back-button {
        width: 40px;
        height: 40px;
        padding: 9px;
      }

      .search-box {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        position: relative;

        .ant-select {
          width: 100%;
          .ant-select-selector {
            border: 1px solid $gray-color !important;
            border-radius: 16px;

            height: 45px;
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
              padding-left: 35px;
            }
            &:hover,
            &:active,
            &:focus {
              border: solid 1px $color-gray-blue;
              background-color: white;
            }
          }
        }

        .search-box-icon {
          position: absolute;
          left: 16px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 14px;
          }
          color: $gray-color-2;
        }
      }
    }

    .user-login-notify {
      display: flex;
      align-items: center;
      gap: 24px;

      .header-page__nav {
        padding: 0;
        .links {
          flex-direction: row;
          justify-content: center;
          gap: 24px;

          .active-link {
            font-weight: 500;
          }

          .link-item {
            font-size: $fs-text;
            padding: 5px 0px;
            margin: 0;
            cursor: pointer;
            &:hover {
              color: $main-color !important;
            }
          }
          .ant-anchor-wrapper {
            background-color: initial;
            .ant-anchor-ink {
              display: none;
            }
            .ant-anchor-link {
              padding: inherit;
              line-height: inherit;
            }
            .ant-anchor-link-title {
              color: $color-text;
              font-size: 16px;
              padding: 10px 30px;
            }
            .ant-anchor-link-active {
              background-color: $bg_color_active;
              font-weight: 700;
            }
          }
        }
      }

      .header-page__act {
        display: flex;
        align-items: center;
        gap: 24px;
        .button-wrapper {
          display: flex;
          gap: 16px;
        }
        div:not(:has(button)) i {
          color: $gray-color-3;
        }
      }

      .connect-button {
        button {
          height: 40px !important;
          border-radius: 40px !important;
          width: fit-content;
          &:hover {
            border-color: $main-color !important;
          }

          .text-center {
            display: flex;
            justify-content: space-between;
            align-items: center !important;
            span {
              color: $gray-color-3;
              font-weight: 500;
              font-size: 14px;
              strong {
                padding-left: 3px;
              }
            }
          }
          img {
            width: 35px;
            height: 35px;
          }

          a {
            vertical-align: middle;
          }
        }
        .btn-account-wallet {
          padding: 0 4px 0 15px !important;
        }
      }

      .btn-create {
        width: fit-content;
        border-radius: 40px !important;
        span {
          font-size: 14px;
        }
      }

      .btn-account {
        width: fit-content;
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }

    .btn-submit {
      a {
        color: white;
      }
    }
  }

  &__mobile {
    width: 100%;
    .logo-container {
      display: flex;
      align-items: center;
      padding: 16px 0px;
      img {
        height: 40px;
      }
    }
    .bg-active {
      background-color: $bg_menu_active;
    }
    .mobile-nav {
      background-color: transparent !important;
      .menu-icon {
        width: 66px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 480px) {
          width: 56px;
          height: 60px;
        }
        @media screen and (max-width: $breakpoint-sm-mobile) {
          width: 33px;
          height: 60px;
        }
      }
    }
    .notify-icon .btn-search {
      i {
        color: $gray-color-2;
      }
    }
    .user-option {
      gap: 10px;
      padding: 12px 0;
      position: relative;
      button {
        width: fit-content;
        border-radius: 40px !important;
        i {
          margin-right: 0;
        }
      }
      .search-icon {
        margin: 0 14px;
        div svg {
          vertical-align: middle;
          path {
            fill: $primary_color;
          }
        }
        @media screen and (max-width: $breakpoint-sm-mobile) {
          margin: 0 5px;
        }
      }
      .notify-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .notification-alert {
          width: fit-content;
          height: fit-content;
          svg.icon-gray {
            path {
              fill: $primary_color;
            }
          }
        }
        @media screen and (max-width: $breakpoint-sm-mobile) {
          margin: 0 5px;
        }
        button {
          border-color: $gray-color-2 !important;
        }
      }
      .connect-wallet {
        .btn-submit {
          width: fit-content !important;
          font-size: 14px;
        }
        .btn-create {
          width: fit-content !important;
        }
      }
      .btn-account {
        width: fit-content;
        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 0px;
          margin-left: 10px;
        }
      }
    }
    .btn-account-mobile {
      width: 34px !important;
      height: 34px !important;
      margin-left: 10px;
      img {
        height: 100%;
        width: 100%;
        cursor: pointer;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .search-header-mobile {
    .search-mobile {
      width: 100% !important;
      padding: 0 5px;
      &__container {
        padding-block: 40px;
        gap: 16px;
      }
      &__header {
        position: relative;
        padding-right: 0;
        input {
          padding-right: 30px !important;
        }
        .icon-search {
          position: absolute;
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
          color: $gray-color-2;
          font-size: larger;
        }
        .icon-closed {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }
}

#light-theme .header-page {
  background-color: $secondary_background_color;
  color: $primary_color;

  &__container {
    .logo-input {
      .search-box {
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          ) {
          .ant-select-selector {
            border: solid 1px $border_color;
            background-color: white;
            box-shadow: 0px 17px 13px 0px #192c4b05;
          }
        }

        .ant-select {
          .ant-select-selector {
            border: 1px solid $border_color;
            &:hover,
            &:focus {
              border: 1px solid $main-color !important;
            }
          }
        }

        .search-box-icon {
          svg {
            path {
              fill: rgba(152, 162, 179, 0.1);
            }
          }
        }
      }
    }

    .user-login-notify {
      .links {
        .active-link {
          color: $btn_blue !important;
          border-bottom: 3px solid $btn_blue;
        }
        .link-item {
          color: $gray-color-3;
          font-weight: 500;
        }
      }
      .header-page__act {
        i:hover {
          color: $main-color !important;
        }
      }
    }
  }
}

#dark-theme .header-page {
  background-color: $primary_background_color_dark;
  color: $color-text;

  &__container {
    .logo-input {
      .search-box {
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          ) {
          .ant-select-selector {
            border: solid 1px $border_color;
            background-color: rgba(255, 255, 255, 0.07);
            box-shadow: 0px 17px 13px 0px #192c4b05;
          }
        }

        .ant-select {
          .ant-select-selector {
            border: 1px solid rgb(21, 27, 34);
            background-color: #333030;
            color: $white;
          }
        }

        .ant-select-clear {
          background: currentcolor !important;

          .anticon {
            color: $white;
          }
        }

        .search-box-icon {
          svg {
            path {
              fill: #333030;
            }
          }
        }
      }
    }

    .user-login-notify {
      .links {
        .active-link {
          border-bottom: 3px solid $btn_blue;
        }

        .link-item {
          color: $color-text;
        }
      }

      .connect-button {
        button {
          .text-center {
            span {
              color: white;
            }
          }
        }

        .btn-account-wallet {
          background: rgb(48, 51, 57) !important;
        }
      }
    }
  }
  .notify-icon {
    svg {
      path {
        fill: $white-text;
      }
    }
  }
}

.dropdown-explore {
  padding: 10px !important;
  border-radius: 10px !important;

  & > li a {
    font-size: 1rem;
    & img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.dropdown-explore-dark {
  background-color: rgb(48, 51, 57) !important;

  & > li a {
    color: #fff !important;
    img {
      filter: brightness(0) invert(1);
    }
  }

  .ant-dropdown-menu-item:hover {
    background-color: rgb(63, 64, 66) !important;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .header-page {
    &__container {
      gap: 16px;
      .search-box {
        margin: 0;
      }
      .logo-input,
      .user-login-notify,
      .header-page__act,
      .links {
        gap: 16px;
      }
    }
  }
  .header-page__nav {
    .links {
      .link-item {
        margin: 0 8px;
      }
    }
  }
}

.link-item {
  z-index: 1001 !important;
  // .dropdown-explore {
  //     position: fixed;
  //     top: 74px;
  // }
}

.dropdown-account {
  width: 250px;
  top: 80.1px !important;
  @media screen and (max-width: 768px) {
    top: 0 !important;
  }
  .ant-dropdown-menu {
    height: unset !important;
    border-radius: 16px;
    border: 1px solid $input-prefix-background;
  }
  .balance-list {
    padding: 16px;
    .item-redirect {
      margin: 0 0 11px 0;
    }
  }
  .ant-dropdown-menu-item {
    margin-bottom: 11px;
  }
  .ant-dropdown-menu-item-divider {
    margin-bottom: 20px;
  }
  .ant-menu-light {
    padding: 15px;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    box-shadow: 0px 3px 30px 0px rgba(145, 145, 145, 0.6) !important;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(145, 145, 145, 0.6) !important;
    -moz-box-shadow: 0px 3px 30px 0px rgba(145, 145, 145, 0.6) !important;
  }
  .ant-menu-item-divider {
    background-color: $input-prefix-background;
    margin-bottom: 16px !important;
  }
  .ant-dropdown-arrow {
    right: 28px;
    border-width: 16px;
    top: 0;
    border-radius: 8px 0;
  }
  .ant-menu-item {
    height: initial !important;
    margin-bottom: 0 !important;

    &-active:hover {
      background-color: transparent !important;
    }

    &:hover {
      color: $btn_blue !important;
    }
    &:active {
      background: none !important;
    }
  }
  .user-address {
    &:hover {
      cursor: initial;
    }
    .address {
      font-size: 17px;
      font-weight: 700;
      line-height: 36px;
      color: $primary_color;
      margin-bottom: 5px;
      font-size: 17px;
      font-weight: 500;
      i {
        cursor: pointer;
      }
    }
  }
  .action-item {
    .set-display-name {
      color: $third_color;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
    .cursor-initial {
      cursor: initial;
    }
  }

  .banlance-item {
    &:hover {
      cursor: initial;
    }
    .balance {
      line-height: 20px !important;
      img {
        width: 36px;
        height: 36px;
        margin-right: 16px;
      }
      .balance-price {
        .title {
          font-size: 12px;
          line-height: 18px;
          color: $secondary_color;
          font-weight: 400;
          margin-bottom: 0;
          span {
            margin-left: 10px;
          }
        }
        .price {
          font-size: 16px;
          color: $secondary_color;
          line-height: 24px;
          font-weight: 500;
          span {
            margin-left: 10px;
            color: $secondary_color;
          }
        }
      }
    }
    .claim-btn {
      width: 75px;
      height: 36px;
      font-size: 14px;
    }
  }
  .add-visa-item {
    border: 1px solid $primary_color;
    border-radius: 8px;
    margin-bottom: 24px !important;
    .add-visa {
      padding: 10px;
      .title {
        font-size: 16px;
        color: $primary_color;
        font-weight: 700;
      }
    }
  }
  .item-redirect {
    border-radius: 10px;
    div {
      color: $primary_color;
      font-size: 15px;
      &:hover {
        color: $btn_blue;
      }
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }
}

.dropdown-account-dark {
  .ant-menu-light {
    background: rgb(48, 51, 57);
  }

  .user-address {
    .address {
      color: $white;
    }
  }

  .banlance-item {
    .balance {
      .balance-price {
        .title {
          color: rgba(255, 255, 255, 0.789);
        }
        .price {
          color: rgba(255, 255, 255, 0.789);

          span {
            color: rgba(255, 255, 255, 0.789);
          }
        }
      }
    }
  }

  .item-redirect {
    div {
      color: $white;

      &:hover {
        color: $btn_blue;
      }
    }
  }

  .btn-account-wallet {
    background-color: rgb(48, 51, 57) !important;
  }
}

.dropdown-notification {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &-menu {
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 13;
  }
  .ant-menu-light {
    border-radius: 16px;
    width: 100%;
    @media screen and (max-width: 767px) {
      height: 580px;
    }
    @media screen and (max-width: 480px) {
      height: 610px;
    }
  }
  .ant-dropdown-arrow {
    right: 28px;
    border-width: 16px;
    top: 0;
    border-radius: 8px 0;
  }
  .not-read-noti {
    background: rgba(0, 174, 208, 0.05);
  }
  .ant-menu-submenu-title {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .ant-menu-item {
    a {
      color: $third_color;
      &:hover {
        color: $third_color;
      }
      &::before {
        position: relative;
      }
    }

    height: initial !important;

    padding: 20px 12px !important;
    color: $third_color;
    font-size: 16px;
    font-weight: 400;
    white-space: inherit;
    line-height: 24px !important;

    margin-top: 0px !important;
    margin-bottom: 0px !important;
    &:last-child:not(:first-child) {
      border-bottom: none !important;
    }
    .item-image {
      margin-right: 1rem;
      object-fit: cover;
      position: relative;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
      .icon-checked {
        position: absolute;
        width: 16px;
        height: 16px;
        bottom: 0;
        right: 0;
      }
    }

    .text-highlight {
      color: $gray-color-3;
      font-weight: 700;
      margin-right: 3px;
    }

    .time-noti {
      font-size: 14px;
    }
    &:hover {
      color: $third_color;
    }
    &:active {
      background: none !important;
    }
  }
}

.notification-alert {
  width: 24px;
  height: 24px;
  svg.icon-gray {
    path {
      fill: $gray-color-3;
    }
  }
}

.dropdown-notify {
  min-width: 400px;
  background: $secondary_background_color;
  border-radius: 16px;
  box-shadow: 0px 4px 32px rgba(52, 87, 210, 0.05);
  max-height: 480px;
  overflow: auto;
  padding: 20px;
  border: solid 1px $color-gray-blue;
  .ant-menu-vertical {
    border: none !important;
  }
  .box-noti {
    border-radius: 5px;
  }
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .notifi-custom-btn {
      height: 28px !important;
    }
    h3 {
      margin: 0;
      color: $gray-color-3;
    }
  }
  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .noNoti {
    border-bottom: none;
    border-radius: 16px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
      height: 80px;
    }
    p {
      margin-top: 22px;
      font-size: 16px;
      font-weight: 700;
      color: $gray-color-3;
    }
  }
  .loading-more {
    margin: 20px auto;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(220, 227, 242, 1);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(163, 174, 208, 1);
    border: 3px solid transparent;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(134, 144, 175);
    border: 3px solid transparent;
    background-clip: content-box;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #fff;
  }
}

.dark-dropdown-notify {
  background-color: $primary_background_color_dark;
  border: solid 1px #e2eef1;
  .notification-header {
    .noti-title {
      color: $white-text;
    }
  }
  .not-read-noti {
    background-color: rgb(66, 66, 66);
    border-bottom: 1px solid rgba(255, 255, 255, 0.471) !important;
  }
  .noNoti {
    p {
      color: $white-text;
    }
  }
  .ant-menu-vertical {
    background-color: $primary_background_color_dark;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primary_background_color_dark;
  }
}

.menu-mobile {
  .ant-menu-item {
    display: flex;
    span {
      flex-grow: 1;
    }
    margin-top: 20px;
    margin-bottom: 20px !important;
  }
  .link-item {
    color: $gray-color-3;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      margin-top: -2px;
    }
    .link-title {
      display: flex;
      justify-content: space-between;
    }
  }
}
.drawer-menu-mobile {
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 480px) {
    margin-top: 70px;
  }
  .ant-drawer-mask {
    @media screen and (min-width: 480px) {
      top: 4px;
    }
  }
  .ant-drawer-content-wrapper {
    @media screen and (min-width: 480px) {
      top: 4px;
    }

    @media screen and (max-width: 450px) {
      width: 90% !important;
    }
  }
  .ant-drawer-content {
    overflow-x: hidden;
    background-color: $white !important;
  }
  .ant-drawer-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px !important;
    @media screen and (min-width: 480px) {
      padding-bottom: 110px !important;
    }

    .logo-item {
      margin: 24px 24px 20px 24px;
    }
    .drawer-body-item {
      .active-link {
        color: $bg_color_active;
      }
      .link-item {
        color: $primary_color;
        font-size: $fs-text;
        width: 100%;
        display: block;
        &:hover {
          color: $btn_blue;
        }
        div {
          margin-left: 24px;
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
    .mobile-menu-community {
      background-color: $bg_color;
      padding-left: 24px;

      .ant-collapse-item {
        border: none !important;
        background-color: transparent;
        height: 49px;

        .ant-collapse-header {
          color: white;
          height: 100%;
          padding: 12px 0 !important;
          font-size: 16px;
          .anticon {
            top: 20px !important;
          }
        }
        .ant-menu-root.ant-menu-vertical {
          background-color: $bg_color !important;
          border: none;
        }
        .ant-collapse-content-box {
          padding: 0 !important;
        }
        .ant-menu-item {
          padding: 0 8px !important;
        }
        .join-text {
          color: white;
          margin-left: 8px;
        }
        .ant-menu-item-selected {
          background-color: none !important;
        }
      }
    }
    .btn-create-mobile {
      bottom: 100px;
      padding-left: 24px;
      padding-right: 24px;
      width: 100%;
      .ant-btn-color-blue {
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
      button {
        width: 100%;
      }
    }
  }
}

.dark-drawer-menu-mobile {
  .ant-drawer-content {
    background-color: $primary_background_color_dark !important;
  }
  .ant-menu {
    background-color: $primary_background_color_dark !important;
  }
  .link-title {
    color: $white-text;
  }
  span {
    color: $white-text;
  }
  i {
    color: $white-text;
  }
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-menu-inline {
        span {
          .link-item {
            color: white;
            img {
              filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
            }
          }
        }
      }
      .ant-menu-sub.ant-menu-inline {
        .ant-menu-item {
          a {
            color: $white-text;
          }
        }
      }
    }
  }
}

.notification-alert {
  &.active {
    color: $red;
  }
}

.dropdown-search-header {
  position: fixed !important;
  border-radius: 16px !important;
  margin-top: 17px !important;
  top: 70px !important;
  padding: 10px 0 !important;
  box-shadow: 0px 4px 32px rgba(52, 87, 210, 0.05) !important;
  border: 1px solid $input-prefix-background !important;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 99px;
  }

  .btn-view-all {
    width: 100%;
    font-weight: 700;
    border: 1px solid $third_color;
  }

  .result-search-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mobile-view-all {
      cursor: pointer;
      position: absolute;
      right: 16px;
      font-size: 16px;
      font-weight: 700;
      color: $primary_color;
    }
  }

  .btn-load svg {
    width: 42px;
    height: 42px;
  }
  .dark-title {
    p {
      color: $white-text !important;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 400 !important;
    margin-bottom: 0;
    padding: 5px 17px;
    margin: 0;
    &:first-child {
      padding: 10px 17px 5px;
    }
    img {
      width: 80px;
      height: 80px;
    }
    p {
      margin-top: 22px;
    }
    &:hover {
      background: $secondary_background_color;
    }
  }
  .rc-virtual-list-holder {
    max-height: 550px !important;
  }
  .ant-select-item {
    background-color: $white !important;
    & :hover {
      background-color: $white !important;
    }
  }
  .list-items-search {
    .user-avatar .custom-avatar .verified-icon {
      width: 25px;
      height: 25px;
      top: 50%;
    }
    .ant-card-body {
      display: flex;
      padding: 5px 18px;
      align-items: center;
    }
    &.ant-select-item {
      padding: 0;
    }

    .gray-text {
      font-size: 14px;
      font-weight: 500;
      color: $third_color;
    }
    .card-search-avatar,
    .user-avatar {
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
        border-radius: 6px;
        padding: 0;
      }
      .search-music-icon,
      .search-video-icon {
        position: absolute;
        bottom: 15px;
        left: 29px;
      }
      .custom-loading-icon {
        animation: spin 0.75s linear infinite;
      }
    }
    .user-avatar {
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .ant-card-bordered {
      border: none;
    }
    .ant-card:hover {
      background: #f1f3f8;
    }
    .card-search-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item-name {
        font-size: 16px;
        font-weight: 700;
        max-width: 250px;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 0px !important;
        text-overflow: ellipsis;
        color: $primary_color;
      }

      p:first-child {
        margin-bottom: 6px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.dropdown-search-header-light {
  .btn-view-all {
    color: $primary_color;
  }

  .title {
    color: $primary_color !important;
    font-weight: $bold;
  }

  .ant-select-item {
    background-color: $white !important;
    & :hover {
      background-color: $white !important;
    }
  }

  .list-items-search {
    background-color: $white !important;
    &.ant-select-item {
      background-color: $white !important;
      // & :hover {
      //     background-color: rgb(31, 31, 31) !important;
      // }
    }

    .user-avatar {
      background: $white;
    }
  }
}

.dropdown-search-header-dark {
  background-color: rgb(31, 31, 31) !important;
  .btn-view-all {
    color: $color-text;
    background: transparent;
  }

  .title {
    color: $color-gray-blue !important;
  }

  .ant-select-item {
    background-color: rgb(31, 31, 31) !important;
    & :hover {
      background-color: rgb(31, 31, 31) !important;
    }
  }

  .list-items-search {
    background-color: rgb(31, 31, 31) !important;
    &.ant-select-item {
      background-color: rgb(31, 31, 31) !important;
      // & :hover {
      //     background-color: rgb(31, 31, 31) !important;
      // }
    }
    .ant-card {
      background-color: rgb(31, 31, 31) !important;
    }

    .user-avatar {
      background: rgb(31, 31, 31);
    }

    .card-search-body {
      .item-name {
        color: $white;
      }
    }
  }
}

// @media screen and (min-width: 1440px) {
//     .search-box {
//         width: 330px !important;
//     }
// }

// @media screen and (min-width: 1600px) {
//   .search-box {
//     width: 350px !important;
//   }
// }

// @media screen and (min-width: 1700px) {
//   .search-box {
//     width: 380px !important;
//   }
// }

@media screen and (max-width: 1360px) {
  .header-page {
    .logo-name {
      font-size: 1rem;
    }
  }
  .header-page__nav {
    padding: 0 10px;
    .links {
      padding: 0 10px;
      .link-item {
        padding: 5px 10px;
        margin: 0 0;
        font-size: $fs-text;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .header-page__nav {
    padding: 0;
    .links {
      padding: 0;

      .link-item {
        margin: 0 4px;
        font-size: $fs-text;
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .header-page_nav {
    padding: 0;
    .links {
      padding: 0;
      .link-item {
        font-size: 15px;
        margin: 0 2px;
      }
    }
  }
}

@media screen and (max-width: 1072px) {
  .header-page_nav {
    .link-item {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 1055px) {
  .header-page__nav {
    .link-item {
      margin: 0;
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 1026px) {
  .header-page {
    .dropdown-link {
      font-size: 14px;
      padding: 0 10px;
    }
    .connect-button-mobile {
      button {
        gap: 8px;
        span div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-weight: 500;
            display: flex;

            strong {
              padding-left: 3px;
            }
          }
        }
        a {
          vertical-align: middle;
          color: $white;
          &:hover {
            color: $white;
          }
        }
      }
      .btn-account-wallet {
        padding: 0 2px 0 15px !important;
      }
    }
  }
  .header-page__nav {
    .links {
      .link-item {
        font-size: 14px;
        padding: 0 8px;
        margin: 0;
      }
    }
  }
}

.liked-nft-name {
  font-weight: bold;
  color: $primary_color;
}

.dark-my-auto {
  .liked-nft-name {
    font-weight: bold;
    color: $white-text;
  }
}

@media screen and (max-width: 960px) {
  .header-page {
    &__container {
      .user-login-notify {
        .connect-button {
          display: none;
        }
        .notification-icon {
          margin-right: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 840px) {
  .header-page {
    &__container {
      .logo-input {
        .logo-pace-art {
          margin-right: 15px;
        }
        .search-box {
          width: 300px;
        }
      }

      .user-login-notify {
        .notification-icon {
          margin-right: 20px;
        }
      }
    }
  }
  .header-page__nav {
    .links {
      padding: 0;
    }
  }
}

@media screen and (max-width: 840px) {
  .header-page {
    &__container {
      padding: 20px 25px;
    }
  }
}

.ant-modal.modal_weth_container {
  .ant-modal-content {
    height: 584px;
    width: 410px;
    margin: 0 auto;
    @media screen and (max-width: $breakpoint-mobile) {
      width: 100% !important;
    }
    .ant-modal-header {
      padding-top: 32px;
      padding-left: 32px;
      .ant-modal-title {
        color: $gray-color-3 !important;
        font-family: $font-primary;
        font-size: 24px !important;
        line-height: 54px !important;
      }
    }
  }
  .ant-modal-body {
    position: relative;
    padding: 0 32px;
    margin-top: 55px;
    .modal_weth_content {
      font-size: 16px;
      height: 360px;
      width: 100%;
      color: $secondary_color;
      span {
        color: $primary_color;
        font-family: $font-primary;
      }
    }
    .btn-continute {
      width: 100% !important;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 992px) {
  .ant-drawer-body {
    padding: 0 !important;
  }
  .dropdown-account {
    width: 100%;
    div *:not(.ant-menu-item-divider) {
      height: 100%;
    }
    .ant-drawer-content-wrapper {
      border-radius: 16px 16px 0px 0px;
      max-height: calc(100vh - 100px) !important;
      overflow: auto;
      background-color: $white;
      min-height: calc(100% - 80px);
    }
    .ant-drawer-content {
      border: 1px solid $input-prefix-background;
      border-radius: 16px 16px 0px 0px;
      background-color: transparent !important;
    }
    .ant-menu-light {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: unset !important;
    }
    .ant-drawer-body {
      padding: 0;
    }
  }
  .dropdown-notification-menu {
    top: 0;
    z-index: 3;
    .ant-drawer-content-wrapper {
      border-radius: 16px 16px 0px 0px;
    }
    .ant-drawer-content {
      border: 1px solid $input-prefix-background;
      box-shadow: 0px 4px 32px rgba(52, 87, 210, 0.05);
      border-radius: 16px 16px 0px 0px;
    }
    .ant-drawer-body {
      padding: 0;
      overflow: hidden;
    }
    .ant-menu-light {
      border-radius: 16px;
      width: 100%;
    }
    .ant-dropdown-arrow {
      right: 28px;
      border-width: 16px;
      top: 0;
      border-radius: 8px 0;
    }
    .not-read-noti {
      background: rgba(0, 174, 208, 0.05);
    }
    .ant-menu-submenu-title {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .ant-menu-item {
      a {
        color: $third_color;
        &:hover {
          color: $third_color;
        }
        &::before {
          position: relative;
        }
      }

      height: initial !important;

      padding: 15px 20px !important;
      color: $third_color;
      font-size: 14px;
      font-weight: 400;
      white-space: inherit;
      line-height: 24px !important;

      margin-top: 0px !important;
      margin-bottom: 0px !important;
      .item-image {
        margin-right: 1rem;
        object-fit: cover;
        position: relative;
        img:first-child {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          object-fit: cover;
        }
        .icon-checked {
          position: absolute;
          width: 16px;
          height: 16px;
          bottom: 0;
          right: 0;
        }
      }
      .text-highlight {
        color: $primary_color;
        font-weight: 700;
      }
      .time-noti {
        font-size: 12px;
      }
      &:hover {
        color: $third_color;
      }
      &:active {
        background: none !important;
      }
    }
  }

  .dropdown-notify {
    min-width: 373px;
    height: 100%;
    max-height: 100%;
    border-radius: 0;

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
    }
    .noNoti {
      height: 474px;
    }
    .loading-more {
      margin: 20px auto;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(220, 227, 242, 1);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(163, 174, 208, 1);
      border: 3px solid transparent;
      background-clip: content-box;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(134, 144, 175);
      border: 3px solid transparent;
      background-clip: content-box;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #fff;
    }
  }

  .wrap-model-weth {
    overflow: unset !important;
  }
}

@media screen and (max-width: 1024px) {
  .header-page {
    width: 100%;

    .search {
      display: flex;
      align-items: center;

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 25px;
          color: $gray-color-2;
          margin-top: 2px;
        }
      }
    }
  }
  .header-page__mobile {
    justify-content: space-between !important;
    position: relative;
  }
  .search-mobile {
    width: 100%;

    &__header {
      margin: auto;
      width: 100%;
      input {
        font-size: 16px;
      }
      &__input {
        border: 1px solid $gray-color-2 !important;
        color: $primary_color !important;
        border-radius: 16px !important;
        height: 39px;
        padding-left: 35px !important;
      }
    }
  }
  .dropdown-search-header {
    border-radius: unset !important;
    margin-top: unset !important;
    top: 108px !important;
    padding: 0 !important;
    box-shadow: unset !important;
    border: unset !important;
    position: absolute !important;
    background: $white;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 101;
    .title {
      background: $white;
    }
    .ant-card-body {
      background: $white;
    }
    &.dropdown-search-header__unset-bottom {
      bottom: unset !important;
    }
  }
  .dropdown-menu li:nth-child(10) div,
  .dropdown-menu li:nth-child(8) div,
  .dropdown-menu li:nth-child(9) div {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .menu-footer {
    z-index: 100;
    padding: 15px 0 0px;
    border-top: 1px solid $color-gray-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    > button {
      margin-top: auto;
    }
    .links-container {
      margin-top: 4px;
      display: flex;
      flex-wrap: wrap;
      max-width: 248px;
      margin: auto;
      justify-content: center;
      column-gap: 10px;
      a {
        margin-top: 16px;
        i {
          color: $primary_color;
          font-size: 1.2rem;
          height: 2.5rem;
          width: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          background: $color-gray-blue;
          transition: all 0.25s ease-in-out;
          border: solid 1px transparent;
        }
        &:hover,
        &:active {
          i {
            opacity: 0.8;
            // background-color: transparent;
            // border: 1px solid $color-gray-blue;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-action {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      gap: 10px;
      .btn-create-mobile {
        width: auto !important;
        padding: 0 !important;
      }
      .connect-button-mobile {
        .avatar,
        .btn-account-mobile img {
          width: 34px !important;
        }
      }
    }
  }
}

.dark-dropdown-menu {
  .ant-dropdown-menu {
    background-color: $primary_background_color_dark;
  }
  li:hover {
    background: none;
  }
}

@media screen and (max-width: $breakpoint-m-mobile) {
  .search-mobile {
    min-width: 250px;
    margin: auto;

    &__header {
      min-width: 250px;
    }
  }
}

.community-container {
  width: 272px;

  .ant-dropdown-menu {
    height: 82px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $input-prefix-background !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 32px rgba(52, 87, 210, 0.05);
    border-radius: 16px !important;
    .ant-dropdown-menu-item:hover {
      background-color: white !important;
    }
  }
}

.red-dot {
  &::after {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
  }
}

@media screen and (max-width: 450px) {
  .menu-footer {
    .links-container {
      max-width: 248px;
      margin: auto;
    }
  }
}

@media (max-width: map-get($breakpoints,'lg' )) {
  .header-page {
    &__container {
      padding: 20px;
    }
  }
}

// reponsive whatISWETHModal
@media (max-width: map-get($breakpoints,'sm' )) {
  .wrap-model-weth {
    .ant-modal.modal_weth_container {
      .ant-modal-content {
        height: 560px;
        max-width: 90vw;
        .ant-modal-header {
          padding-top: 20px;
          padding-left: 25px;
          .ant-modal-title {
            font-size: 30px !important;
          }
        }
      }
    }
    .ant-modal-body {
      margin-top: 36px !important;
      padding: 16px 25px !important;
      .modal_weth_content {
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header-page {
    .container {
      padding: 0 10px;
    }
    .search-header-mobile {
      .search-mobile {
        &__header {
          min-width: 220px;
        }
      }
    }
  }
}

.btn-toggle-theme {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: black;
  margin-left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-switcher {
  height: 24px;
  width: 24px;
}

.header-page__act .multi-lang-dropdown-menu {
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 2px 12px 0px rgba(16, 24, 40, 0.08);
  .ant-radio-checked .ant-radio-inner {
    border-color: $main-color !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: $main-color !important;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $main-color !important;
  }
}

#light-theme .btn-toggle-theme {
  background-color: white;
  border: 1px solid rgb(161, 160, 160);
}

.dark-my-auto {
  .text-highlight {
    color: white !important;
  }
  .action {
    color: $white-text;
  }
}

.dark-search-mobile {
  .circle {
    .close-search-btn {
      color: $white-text !important;
    }
  }
}

#dark-theme .text-highlight {
  color: $white;
}

.search-mobile-no-items {
  margin-top: 40px !important;
}

.ant-drawer-body {
  .search-mobile {
    &__container {
      padding: 60px 16px 40px;
    }
    &__header {
      position: relative;
      .icon-search {
        color: $gray-color-2;
        font-size: large;
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      .icon-closed {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
}
