@import '../../../styles/variables.scss';

.hot-collections {
    color: $primary_color;
    min-height: 400px;
    // padding: 100px 0 50px 0;
    .home-page-category__header {
        line-height: 1;
        padding: 0;
    }
    .ant-carousel {
        margin: 0 -12px;
        .slick-track {
            display: flex;
        }

        .card-container {
            padding: 0 14px;
        }

        .card-collection-custom {
            border: solid 1px $color-gray-blue;
            transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            &:hover {
                transform: translateY(-5px);
            }
        }
    }
    .container {
        .home-page-carousel {
            margin: 0;
        }
    }

    .collections-load-more {
        margin: 24px auto 0;
        width: 240px;
        border-radius: 16px;
    }
}

#dark-theme .hot-collections {
    .home-page-category__header {
        h2 {
            color: $white-text;
        }
    }
}

@media screen and (max-width: 470px) {
    .hot-collections {
        .home-page-category__header {
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .hot-collections {
        .home-page-category__header {
            padding: 0;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .hot-collections {
        .card-container {
            margin: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .hot-collections {
        .container .ant-carousel {
            margin: 0;
        }
    }
}
