@import 'styles/variables.scss';

.logo-image {
    width: 160px;
    height: 44px;
}

.logo-name {
    line-height: 40px;
    padding-left: 5px;
    color: $primary_color;
    font-size: 1.2rem;
    font-weight: 600;
    vertical-align: middle;
}

// @media screen and (max-width: $breakpoint-mobile) {
//     .logo-image {
//         width: 50px;
//     }
// }

// @media screen and (max-width: $breakpoint-m-mobile) {
//     .logo-image {
//         width: 100px;
//         object-fit: unset;
//     }
// }
