@import '../../../styles/variables';

.ant-input.ant-input-textarea-custom {
    padding: 16px 24px;
    font-size: 16px;
    border-radius: 18px;
    line-height: 16px;
    border: 1px solid $input-prefix-background;
    color: $primary_color;

    &:hover {
        border: 1px solid $primary_color;
    }

    &:focus {
        border: 1px solid $primary_color;
        color: $primary_color;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    &::placeholder {
        color: $third_color;
    }
}

#dark-theme .ant-input.ant-input-textarea-custom {
    background: #4c505c;
    color: white;
}
