@import 'styles/common.scss';
@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.list-collections {
    padding-bottom: 40px;
    .number {
        text-align: end;
        display: flex;
        justify-content: flex-end;
    }
    .collections-container {
        flex-direction: column;
        background-color: #fff;
        .header-text {
            margin-top: 0;
            margin-bottom: 0;
            @extend %title-page;
        }
        .filter-container {
            width: 100%;
            padding: 20px 0;
            border-bottom: 1px solid $color-gray-blue;
            border-top: 1px solid $color-gray-blue;
        }
        .select-custom {
            margin-left: 12px;
        }
        .ant-btn-color-white {
            background-color: #fff !important;
        }
        .mobile-activity-filter {
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 0 16px;
            background-color: $primary_background_color;
            height: 74px;
            overflow: auto;
            z-index: 1;
            width: 100%;
            display: flex;
            button {
                width: 100%;
            }
        }
        .filter {
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;
            width: 100%;
            padding: 20px 0;
            border-top: 1px solid rgba(163, 174, 208, 0.2);
            border-bottom: 1px solid rgba(163, 174, 208, 0.2);
            .left {
                min-width: 203px;
                &:global(.ant-select) {
                    :global(.ant-select-selector) {
                        :global(.ant-select-selection-item) {
                            margin-left: 80px;
                            text-transform: lowercase;
                        }
                    }
                }
            }

            .select {
                &:global(.ant-select) {
                    :global(.ant-select-selector) {
                        background-color: white;
                        height: 34px !important;
                        box-shadow: 0px 2px 4px $shadow_color;
                        border: solid 1px rgba(10, 33, 65, 0.05);

                        :global(.ant-select-selection-item) {
                            color: $primary_color;
                            font-weight: 400;
                        }

                        :global(.ant-select-selection-placeholder) {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;

                            color: $primary_color;
                        }
                    }

                    :global(.ant-select-arrow) {
                        color: $primary_color;
                    }
                }
            }

            .btn-filter {
                height: 34px;
                padding-right: 9px !important;
                font-size: 16px;
                svg {
                    width: 10.5px;
                    height: 20px;
                }
            }

            .select-custom-wrapper .ant-select-selector {
                height: 34px !important;
            }
        }
    }
    &__table {
        margin: 50px 0 40px 0;
        color: $primary_color;
        background-color: #fff;
        border: solid 1px $color-gray-blue;
        padding: 20px;
        border-radius: 20px;
        -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
        box-shadow: 0px 17px 13px 0px #192c4b05;
        display: block;
        overflow: auto;
        @media screen and (max-width: $breakpoint-tablet) {
            margin: 50px 0;
        }
        .list-collections__table__td-header {
            background-color: transparent;
            & > * {
                background-color: transparent;
                padding: 0 20px !important;
            }
        }
        &__td {
            padding: 5px 0;
            background-color: #fff;
            & > * {
                // border-bottom: 1px solid $border_color;
                padding: 15px 20px;
                border-radius: 10px;
                background: #edf3f5;
                & > * {
                    &:first-child {
                        width: calc(5%);
                    }
                    width: calc(15%);
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                a {
                    color: $primary_color;
                }
            }
        }
        & > :last-child {
            border-radius: 0px 0px 8px 8px;
        }
        & > :first-child {
            background-color: $white;
            border-radius: 8px 8px 0px 0px;
            font-family: $font-primary;
        }
        .name-holder {
            font-weight: 400;
            font-size: 19px;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: auto;
            margin-bottom: auto;
        }
        .verify-icon {
            position: absolute;
            bottom: 0;
            left: 50px;
            width: 26px;
            height: 26px;
        }
        .increase {
            color: $price_buy !important;
        }
        .decrease {
            color: #eb5757 !important;
        }
        .button {
            display: flex;
            justify-content: center;
        }
        .collection-name {
            margin-left: 12px;
        }
        .ant-btn-custom {
            max-width: 100px;
            max-height: 36px;
        }
    }
}

#dark-theme .list-collections {
    .collections-container {
        background-color: $primary_background_color_dark;
        .header-text {
            color: $btn-blue;
        }
        .filter {
            .btn-filter {
                svg {
                    path {
                        fill: $white-text;
                    }
                }
            }
            .ant-select-arrow {
                svg {
                    path {
                        fill: $white-text;
                    }
                }
            }
        }
    }

    &__table {
        background-color: rgb(48, 51, 57);
        border: none;

        &__td {
            background-color: rgb(48, 51, 57);

            & > * {
                background-color: rgba(18, 18, 18, 0.7);
            }
        }

        & > :first-child {
            background-color: rgb(48, 51, 57) !important;
            color: $white;
            & > div {
                background-color: rgb(48, 51, 57) !important;
            }
        }

        .name-holder {
            color: white;
        }
    }
}

.chain-logo {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

@media screen and (max-width: $breakpoint-xl) {
    .list-collections {
        &__table {
            &__td {
                min-width: 1086px;
            }
        }
    }
}

@media screen and (max-width: 530px) {
    .list-collections .collections-container .filter {
        justify-content: unset;
        overflow: auto;
    }
}

@media screen and (max-width: $breakpoint-mobile) {
    .list-collections .collections-container .filter {
        padding: 20px 22px;
    }
}

.header-text-dark {
    color: $white;
}
