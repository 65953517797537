@import "../../../styles/variables";

.select-custom-wrapper {
  position: relative;
  .ant-select {
    color: $primary_color;
  }

  .ant-white {
    .ant-select-selector {
      border: solid 1px $border_color !important;
      background: white;
      border: solid 1px $border_color;
      color: $primary_color;
      box-shadow: 0px 2px 4px $shadow_color !important;
    }
  }

  .select-custom_prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100.4%;
    color: $primary_color;
    z-index: 2;
    font-family: $font-secondary;

    .select-custom_prefix__icon {
      margin-right: 12px;
    }
    &-svg {
      left: 15px !important;
      svg path {
        fill: #474873;
      }
    }
  }
  .select-custom_prefix__dark {
    svg {
      path {
        fill: $white-text;
      }
    }
  }
}

.ant-select.ant-select-custom.dark {
  .ant-select-selector {
    background: $primary_color !important;
    border: solid 1px $border_color;
  }
  .ant-select-selection-item {
    color: $white !important;
  }
  .ant-select-arrow {
    color: $white !important;
  }
}
.ant-select.ant-custom-select-dark {
  .ant-select-selector {
    border: 1px solid #525252 !important;
    background-color: $border_color_dark !important;
    color: $secondary_background_color !important;
    &:hover {
      border: 1px solid $border_color !important;
    }
  }
  .ant-select-selection-placeholder {
    color: $white !important;
  }
  .ant-select-arrow {
    color: white !important;
  }
  .ant-select-selection-item {
    color: $white !important;
  }
  .btn-filter svg path {
    fill: $white !important;
  }
}
.ant-select.ant-select-custom {
  display: block;

  .ant-select-selector {
    height: 48px !important;
    padding: 8px 16px !important;
    box-sizing: border-box;
    border-radius: 12px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-color !important;

    &:hover {
      border: 1px solid $main-color !important;
    }
  }

  .ant-select-selection-placeholder {
    display: flex;
    color: $gray-color-2;
    font-weight: normal;
    font-size: 16px;
  }

  .ant-select-selection-search {
    z-index: 3;
  }

  .ant-select-selection-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $gray-color-2;
    font-family: $font-secondary;

    @media screen and (max-width: $breakpoint-mobile) {
      font-size: 14px;
    }
  }

  .ant-select-arrow {
    color: $gray-color-3;
  }

  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid $main-color !important;
      box-shadow: none !important;
    }
  }
}

.select-custom-wrapper-prefix__icon {
  .ant-select.ant-select-custom {
    .ant-select-selector {
      padding: 0 0 0 38px !important;
    }
  }
}

.ant-select-dropdown.custom-dropdown {
  border-radius: 16px;
  padding: 0;
  z-index: 1051; //to apear in front of ant modals
  // border: 1px solid $color-gray-blue !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.ant-select-dropdown.custom-dropdown-dark {
  background-color: rgb(48, 51, 57);

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: rgb(63, 64, 66) !important;
  }
}

.select-custom_option.ant-select-item-option {
  height: 42px;
  padding: 0 20px;

  &.ant-select-item-option-selected {
    background-color: #f5f5f5;
  }
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: $primary_color;
    overflow: visible;
  }
  &:hover {
    color: $primary_color;
  }
}

.select-custom_option-dark.ant-select-item-option {
  .ant-select-item-option-content {
    color: white;
  }

  &:hover {
    color: $primary_color;
    background-color: rgb(63, 64, 66);
  }
}

.select-custom_option__icon__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  img {
    margin-right: 7px;
    width: 16px;
    height: 16px;
  }
  .small-icon {
    margin-right: 10px;
  }
}

.select-custom_option__icon__wrapper-dark {
  img {
    filter: brightness(0) invert(1);
  }
}

.select-custom_option__icon {
  margin-right: 16px;
}
.select-home:hover {
  .ant-select-selection-item {
    background: linear-gradient(
      95.32deg,
      #b809b5 -7.25%,
      #ed1c51 54.2%,
      #ffb000 113.13%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold !important;
    span {
      background: linear-gradient(
        95.32deg,
        #b809b5 -7.25%,
        #ed1c51 54.2%,
        #ffb000 113.13%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
  }
}
