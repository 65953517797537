@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/common.scss';

.overview {
  overflow: visible;
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-start;
  padding: 28px;
  border-radius: 28px;
  background-color: white;
  gap: 32px;
  .nft-image {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgba(141, 153, 155, 0.2);
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 1;
    width: 380px;
    flex-grow: 1;
    img {
      width: 100%;
      height: 100%;
      flex-grow: 1;
    }
  }
  .asset-img {
    max-width: 100%;
    cursor: zoom-in;
    height: 0;
  }
  .vertical-scale {
    height: auto;
    width: auto;
    @include up-to-size('sm') {
      width: 100%;
      height: auto;
    }
  }
  .horizontal-scale {
    width: 100%;
    height: auto;
  }
  .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding-left: 0 !important;
    padding-right: 0 !important;
    flex: none;
  }
  .right-container {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @include up-to-size('lg') {
      padding: 0px;
      padding-top: 20px;
      justify-content: center;
    }
  }
  .loading-img-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dark-asset-detail {
    .detail-name {
      color: $white-text;
    }
    .description {
      background-color: $primary_background_color_dark;
      color: $white-text;

      .expand-text {
        color: $white-text;
      }
    }
    .nft-description {
      color: $white-text;
      .expand-text {
        color: white;
      }
    }
    .bid {
      .bid-left {
        & > :nth-child(2) {
          display: flex;
          .price-info {
            color: $white-text;
            & > :first-child {
              color: $gray-color-3 !important;
            }
          }
        }
      }
      .bid-right {
        color: $secondary_color;
        .countdown-container {
          .flip-countdown {
            .colon-custom {
              color: white;
            }
          }
        }
      }
    }
  }

  .asset-detail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    // max-width: 572px;
    // min-width: 480px;
    font-size: 16px;
    color: $secondary_color;
    word-wrap: break-word;
    @include up-to-size('lg') {
      min-width: 100%;
    }
    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .detail-label {
      color: $primary_color;
    }
    .detail-name {
      color: $gray-color-3;
      word-break: break-word;
      font-size: 24px;
      font-weight: 700;
      font-family: $font-primary;
    }
    .action {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .tag{
        height: 40px;
        width: fit-content;
        max-width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: 1px solid $gray-color;
        border-radius: 12px;
        padding: 8px 16px;
        i {
          color: $gray-color-3;
        }
        &:not(.ant-btn-custom) {
          font-weight: 500;
        }
        span {
          font-size: 14px;
          color: $gray-color-2;
          font-weight: 400!important;
        }
        &:hover {
          background-color: #fff2e6!important;
        }
      }
      img {
        margin-right: 8px;
        width: 16px;
      }
      @include up-to-size('md') {
        & > * {
          margin-bottom: 8px;
        }
        & > :last-child {
          margin-right: 0px;
        }
        .ant-btn-small {
          min-width: 50px !important;
        }
      }
      @media screen and (max-width: 441px) {
        justify-content: center;
      }
      @media screen and (max-width: $breakpoint-m-mobile) {
        & > * {
          margin-right: 8px;
        }
      }
    }
    .price-info-container {
      display: flex;
      gap: 24px;
      color: $gray-color-2;
      @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column;
        gap: 16px;
      }
      &__box {
        display: flex;
        flex-direction: column;
        .price-title {
          font-size: 14px;
        }

        .price {
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          strong {
            color: $gray-color-3;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
    // .share-icon-container {
    //   width: 40px;
    //   height: 40px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background-color: white;
    //   border-radius: 20px;
    //   color: $primary_color;
    //   &:hover,
    //   &:active,
    //   &:focus {
    //     background-color: $color-gray-blue;
    //   }
    //   .ant-dropdown-trigger {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     gap: 8px;
    //   }
    // }
    .btn-view-proof {
      width: auto;
      padding: 0px 20px;
    }
    .nft-description {
      width: 100%;
      word-break: break-word;
      padding: 16px;
      border-radius: 10px;
      min-height: 100px;
      border: none;
      resize: none;
      position: relative;
      line-height: 46px;
      border: 1px solid $color-gray-blue !important;
      .show {
        color: $primary_color;
        font-family: $font-primary;
        cursor: pointer;
        margin-left: 4px;
      }
      .btn-edit-description {
        position: absolute;
        right: 10px;
        top: 0px;
        i {
          color: $primary_color;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .btn-edit-description-dark {
        i {
          color: $border_color;
        }
      }
    }

    .action-edit-description {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      button {
        @media screen and (max-width: 346px) {
          width: auto !important;
          padding: 0 25px !important;
        }
      }
    }

    .network-type {
      .network-type-title {
        margin-bottom: 5px;
      }
      .network-container {
        color: $primary_color;
        font-family: $font-primary;
        cursor: pointer;

        img {
          width: 34px;
          height: 34px;
          margin-right: 10px;
        }
      }
    }
    .expand-text {
      color: $dark_primary_color;
      font-family: $font-primary;
      cursor: pointer;
      margin-left: 4px;
    }
    .btn-view-proof {
      height: 34px;
    }
    .share-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-right: 0;
      column-gap: 4px;
      @media screen and (max-width: 441px) {
        justify-content: center;
        flex: 0 0;
      }
    }

    .bid {
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-radius: 8px;
      color: $primary_color;
      background-color: transparent;
      max-width: 100%;

      .ant-btn-custom {
        @extend %button-bid;
      }

      .action-btn {
        max-height: 50px;
        min-height: 46.4px;
        font-size: 19px;
        @include up-to-size('md') {
          width: 100% !important;
          max-width: 100%;
        }
      }

      .bid-left {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: $fs-text;
        color: $secondary_color;
        min-width: 50%;

        & > p > span {
          font-weight: 600;
        }

        & > :first-child {
          display: -webkit-box;
          max-width: 400px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: $breakpoint-m-mobile) {
            font-size: 15px;
          }
        }

        & > :nth-child(2) {
          display: flex;
          .price-info {
            @media screen and (max-width: $breakpoint-m-mobile) {
              font-size: 14px;
            }
            font-size: 16px;
            display: flex;
            align-items: center;
            @include up-to-size('md') {
              margin-left: 0px;
            }
            color: $secondary_color;
            & > :first-child {
              font-size: 20px;
              font-weight: 600;
              font-family: 'Circular Std', Helvetica, Arial, sans-serif;
              color: $gray-color-3;
              @media screen and (max-width: $breakpoint-m-mobile) {
                font-size: 16px;
              }
            }
          }
        }
      }
      .bid-info {
        align-items: center;
        .custom-avatar {
          margin-right: 15px;
        }
        .token-icon {
          border-radius: 50%;
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
        .ant-avatar {
          border: 3px solid white;
        }
      }
      .bid-right {
        padding-left: 24px;
        font-size: $fs-text;
        color: $secondary_color;
        & > p {
          padding-left: 3px;
          margin-bottom: 5px;
        }

        @include up-to-size('md') {
          padding-left: 16px;
        }
        .countdown-container {
          justify-content: flex-start;
        }
        @media screen and (max-width: $breakpoint-m-mobile) {
          font-size: 14px;
        }
      }
      .sale-text {
        font-size: 16px;
        color: $third_color;
      }
    }
    .bid-auction {
      display: flex;
    }
  }
  .action-buy {
    display: flex;
    gap: 24px;
    @include up-to-size('md') {
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      margin: 0;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      .action-btn {
        margin: 0 !important;
        width: 100% !important;
        max-width: 100%;
      }
    }
    .buy-with-sp {
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 8px;
      > span {
        color: $main-color;
        font-weight: 600;
      }
    }
    button {
      max-width: fit-content;
      padding: 12px 24px;
    }
  }
  .not-sale-container {
    @include up-to-size('md') {
      width: 100%;

      bottom: 0;
      left: 0;
      background-color: $primary_background_color;
      z-index: 1000;
    }
    .not-sale-btn {
      color: $white !important;

      @extend %button-bid;
      @include up-to-size('md') {
        margin-top: 0px;
      }
    }
  }

  .audio-display {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    @include up-to-size('lg') {
      width: 100%;
      max-width: 652px;
    }
    & > :first-child {
      border-radius: 16px;
      width: 100%;
      object-fit: cover;
    }
    & > :nth-child(2) {
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
    }
    &:hover {
      & > :nth-child(2) {
        display: flex;
      }
    }
  }
  .video-display {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: $video-background;
    border-radius: 16px;
    & > :first-child {
      max-height: 493px;
      max-width: 652px;
      width: 100%;
    }
    .video-player_controls {
      opacity: 0;
      transition: all 0.25s ease-in;
    }
    &:hover .video-player_controls {
      opacity: 1;
    }
  }
}

.menu-view-proof {
  border-radius: 7px !important;
  & > li {
    padding: 5px 15px;
    a {
      font-size: 1rem;
    }
  }
}

.color-red {
  color: $red;
}

.menu-share {
  .ant-dropdown-menu-item:hover {
    transform: scale(1.3);
    background-color: transparent;
  }
}

.menu-report {
  .report-holder {
    display: flex;
    column-gap: 10px;
  }
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }
}

.menu-icon {
  top: 10px;
  border-radius: 20px !important;
  .ant-dropdown-menu-item:hover {
    background-color: transparent;
  }
  .icon {
    font-size: 20px;
  }
  .text {
    font-size: 17px;
    &:hover {
      color: $primary_color;
    }
  }
}

.unlockable-content {
  margin-top: 29px;
  display: flex;
  justify-content: center;
  .ant-btn.ant-btn-custom {
    width: 100%;
    border-color: $input-prefix-background !important;
    img {
      margin-right: 16px;
    }
  }
}

.custom-zoom-modal {
  margin: 0px !important;
  padding: 0px !important;
  top: 0px !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100vw !important;
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75) !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    .ant-modal-body {
      padding: 20px !important;
      width: 100%;
      height: 100%;
      .zoom-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.custom-sale-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  .ant-modal-content {
    width: 512px !important;
    .ant-modal-body {
      padding: 32px !important;
    }
  }
}

.custom-buy-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px !important;
  .ant-modal-content {
    width: 100% !important;
  }
}

.custom-process-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal-content {
    width: 410px !important;
    margin: auto;
    @media screen and (max-width: $breakpoint-mobile) {
      width: 100% !important;
    }
    .ant-modal-body {
      padding: 32px !important;
    }
  }
}

.custom-bid-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500x !important;
  .ant-modal-content {
    width: 100% !important;
    .ant-modal-body {
      padding: 32px 32px 40px 32px !important;
    }
  }
}

.custom-uniswap-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 628px !important;
  .ant-modal-content {
    width: 100% !important;
    .ant-modal-body {
      padding: 32px !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .overview {
    padding: 20px;
    .nft-image {
      max-width: 300px;
    }
  }
}

@media screen and (max-width: $breakpoint-lg-tablet) {
  .overview {
    flex-direction: column;
    .left-container {
        width: 100%;
    }
    .nft-image {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        img {
          aspect-ratio: auto;
          max-width: 100%;
        }
      }
  }
  .custom-uniswap-modal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 601px) {
  .asset-detail {
    .action > * {
      margin-right: 10px !important;
    }
    .btn-view-proof {
      width: auto;
      padding: 0 15px !important;
    }
  }
}

.custom-tooltip {
  .ant-tooltip-inner {
    width: max-content;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.custom-loading-icon {
  animation: spin 0.75s linear infinite;
}

.ant-modal.unlockable-content-modal {
  max-width: 410px;
  padding: 8px;
  .ant-modal-content {
    max-width: 100%;
    .ant-modal-body {
      .unlockable-container {
        padding: 8px;
        .unlockable-header {
          color: $gray-color-3;
          font-family: $font-primary;
          font-size: 24px;
        }
        .hide-content,
        .copyright,
        .content-unlockable {
          font-size: 18px;
          color: $third_color;
        }
        .hide-content {
          margin-top: 17px;
          margin-bottom: 24px;
        }
        .close-unlockable-btn {
          width: 100%;
          margin-top: 24px;
        }
        .content-unlockable {
          margin-top: 24px;
        }
      }
    }
  }
  @include up-to-size('sm') {
    max-width: 100%;
  }
}
